/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Camera {
  /** Name */
  name: string;

  /** Device Id */
  device_id?: string;

  /** Id */
  id: string;

  /** Camera Index */
  camera_index: number;

  /** Last Capture Image */
  last_capture_image?: string;
  device?: Device;
  config?: CameraConfig;
}

export interface CameraCapture {
  /** Rtsp Url */
  rtsp_url: string;
}

export interface CameraConfig {
  /** Service Id */
  service_id?: string;

  /** Organization Id */
  organization_id?: string;

  /** Rtsp Url */
  rtsp_url?: string;

  /**
   * Ai Threshold First
   * @min 0
   * @max 1
   */
  ai_threshold_first?: number;

  /**
   * Ai Threshold Second
   * @min 0
   * @max 1
   */
  ai_threshold_second?: number;

  /** Patrol Url */
  patrol_url?: string;

  /** Notification Email */
  notification_emails?: string[]
  notification_schedules?: NotificationScheduleBE[]
  /**
   * Notification Interval
   * @min 15
   * @max 600
   */
  notification_interval?: number;

  /** Is Clone Config */
  is_clone_config?: boolean;

  /** Alert */
  alert: boolean;

  /** Movie */
  movie: boolean;

  /** Area */
  area?: any[];

  line_crosses?: (LineCross | null)[]

  crop_area?: CropArea

  /** Resolution */
  resolution: string;

  /** List Threshold 1 */
  list_threshold_1?: any[];

  /** List Threshold 2 */
  list_threshold_2?: any[];

  /** Schedule Enabled */
  schedule_enabled: boolean;
  schedule?: Schedule;

  /** Id */
  id: string;
}

export interface CameraConfigCreate {
  /** Service Id */
  service_id?: string;

  /** Organization Id */
  organization_id?: string;

  /** Rtsp Url */
  rtsp_url?: string;

  /**
   * Ai Threshold First
   * @min 0
   * @max 1
   */
  ai_threshold_first?: number;

  /**
   * Ai Threshold Second
   * @min 0
   * @max 1
   */
  ai_threshold_second?: number;

  /** Patrol Url */
  patrol_url?: string;

  /** Notification Email */
  notification_emails?: string[]
  notification_schedules?: NotificationScheduleBE[]


  /**
   * Notification Interval
   * @min 15
   * @max 600
   */
  notification_interval?: number;

  /** Is Clone Config */
  is_clone_config?: boolean;

  /** Alert */
  alert: boolean;

  /** Movie */
  movie: boolean;

  /** Area */
  area?: any[];

  line_crosses?: (LineCross | null)[]

  crop_area?: CropArea

  /** Resolution */
  resolution: string;

  /** List Threshold 1 */
  list_threshold_1?: any[];

  /** List Threshold 2 */
  list_threshold_2?: any[];

  /** Schedule Enabled */
  schedule_enabled: boolean;
  schedule?: Schedule;
}

export interface CameraConfigUpdate {
  /** Service Id */
  service_id?: string;

  /** Organization Id */
  organization_id?: string;

  /** Rtsp Url */
  rtsp_url?: string;

  /**
   * Ai Threshold First
   * @min 0
   * @max 1
   */
  ai_threshold_first?: number;

  /**
   * Ai Threshold Second
   * @min 0
   * @max 1
   */
  ai_threshold_second?: number;

  /** Patrol Url */
  patrol_url?: string;

  /** Notification Email */
  notification_emails?: string[]
  notification_schedules?: NotificationScheduleBE[]


  /**
   * Notification Interval
   * @min 15
   * @max 600
   */
  notification_interval?: number;

  /** Is Clone Config */
  is_clone_config?: boolean;

  /** Alert */
  alert?: boolean;

  /** Movie */
  movie?: boolean;

  /** Area */
  area?: any[];

  line_crosses?: (LineCross | null)[]

  crop_area?: CropArea

  /** Resolution */
  resolution?: string;

  /** List Threshold 1 */
  list_threshold_1?: any[];

  /** List Threshold 2 */
  list_threshold_2?: any[];

  /** Schedule Enabled */
  schedule_enabled?: boolean;
  schedule?: Schedule;
}

export interface CameraCreate {
  /** Name */
  name: string;

  /** Device Id */
  device_id: string;
  config?: CameraConfigCreate;
}

export interface CameraUpdate {
  /** Name */
  name: string;

  /** Device Id */
  device_id?: string;
  config?: CameraConfigUpdate;
}

export interface CollectionCamera {
  /** Data */
  data: Camera[];
  pagination: Pagination;
}

export interface CollectionDevice {
  /** Data */
  data: Device[];
  pagination: Pagination;
}

export interface CollectionIncident {
  /** Data */
  data: Incident[];
  pagination: Pagination;
}

export interface CollectionTenant {
  /** Data */
  data: Tenant[];
  pagination: Pagination;
}

export interface CollectionUser {
  /** Data */
  data: User[];
  pagination: Pagination;
}

export interface DailySchedule {
  /** Start */
  start?: string;

  /** End */
  end?: string;
}

export interface Device {
  /** Name */
  name?: string;

  /** Id */
  id: string;

  /** Tenant Id */
  tenant_id?: string;
}

export interface DeviceCreate {
  /** Name */
  name?: string;
}

export interface DeviceUpdate {
  /** Name */
  name?: string;
}

export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

export interface Incident {
  /** Camera Id */
  camera_id?: string;

  /** Detection Type */
  detection_type?: string;

  /**
   * Capture Time
   * @format date-time
   */
  capture_time?: string;

  /** Detection Video Url */
  detection_video_url?: string;

  /** Detection Image Url */
  detection_image_url?: string;

  /** Is Issue */
  is_issue?: boolean;

  /** Is Confirmed */
  is_confirmed?: boolean;

  /** Comment */
  comment?: string;

  /** Updated By */
  updated_by?: string;

  /** Id */
  id: string;
  camera: Camera;
}

export interface IncidentCreate {
  /** Camera Id */
  camera_id: string;

  /** Detection Type */
  detection_type?: string;

  /**
   * Capture Time
   * @format date-time
   */
  capture_time: string;

  /** Detection Video Url */
  detection_video_url: string;

  /** Detection Image Url */
  detection_image_url: string;

  /** Is Issue */
  is_issue?: boolean;

  /** Is Confirmed */
  is_confirmed?: boolean;

  /** Comment */
  comment?: string;

  /** Updated By */
  updated_by?: string;
}

export interface IncidentUpdate {
  /** Camera Id */
  camera_id?: string;

  /** Detection Type */
  detection_type?: string;

  /**
   * Capture Time
   * @format date-time
   */
  capture_time?: string;

  /** Detection Video Url */
  detection_video_url?: string;

  /** Detection Image Url */
  detection_image_url?: string;

  /** Is Issue */
  is_issue?: boolean;

  /** Is Confirmed */
  is_confirmed?: boolean;

  /** Comment */
  comment?: string;

  /** Updated By */
  updated_by?: string;
}

export interface Pagination {
  /** Skip */
  skip: number;

  /** Limit */
  limit: number;

  /** Total */
  total: number;
}

export interface RefreshPayload {
  /** Refresh Token */
  refresh_token: string;
}

/**フロントではレンダリング制御のため時間帯ルールにidが必要なたFEとBEで型を分けている */
export interface NotificationScheduleFE {
  enabled: boolean
  rules: NotificationRule[]
}

export interface NotificationScheduleBE {
  enabled: boolean
  rules: Omit<NotificationRule, "reactId">[]
}

export type DayName = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat"

export interface NotificationRule {
  reactId: string
  dayName: DayName | null
  rule_enable: boolean
  startTime: string | null
  endTime: string | null
}


export interface Schedule {
  monday?: DailySchedule;
  tuesday?: DailySchedule;
  wednesday?: DailySchedule;
  thursday?: DailySchedule;
  friday?: DailySchedule;
  saturday?: DailySchedule;
  sunday?: DailySchedule;
}

export interface LineCrossPoint {
  x: number
  y: number
}

export interface LineCross {
  p1: LineCrossPoint;
  p2: LineCrossPoint;
}

export interface CropAreaPoint {
  x: number
  y: number
}

export interface CropArea {
  p1: CropAreaPoint;
  p2: CropAreaPoint;
}


export interface Tenant {
  /** Name */
  name: string;

  /** Origanization Id */
  origanization_id?: string;

  /** Service Id */
  service_id?: string;

  /** Id */
  id: string;
}

export interface TenantCreate {
  /** Name */
  name: string;

  /** Origanization Id */
  origanization_id: string;

  /** Service Id */
  service_id: string;
}

export interface TenantUpdate {
  /** Name */
  name: string;

  /** Origanization Id */
  origanization_id?: string;

  /** Service Id */
  service_id?: string;
}

export interface User {
  /**
   * Username
   * @format email
   */
  username: string;

  /** An enumeration. */
  role: UserRole;

  /** Tenant Id */
  tenant_id?: string;

  /** Id */
  id: string;
}

export interface UserCreate {
  /**
   * Username
   * @format email
   */
  username: string;

  /** An enumeration. */
  role?: UserRole;

  /** Tenant Id */
  tenant_id?: string;
}

/**
 * An enumeration.
 */
export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  OPERATOR = "OPERATOR",
}

export interface ValidationError {
  /** Location */
  loc: string[];

  /** Message */
  msg: string;

  /** Error Type */
  type: string;
}

export interface Log {
  /** ERROR - CRITICAL */
  level: string;
  message: string;
}