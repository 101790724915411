import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  Bar, BarChart, CartesianGrid, Legend,
  ResponsiveContainer, Tooltip, XAxis,
  YAxis
} from "recharts";
import { IN_COLOR, OUT_COLOR } from "../config-camera/linecross-layer/KonvaLineCross";
import { CsvDataType, CsvExportLogic, ImageExportLogic } from "../Crowddetection";
import { DeviceType, LineType } from "./DeviceLineSelect";
import { csvDeviceCameraString } from "./PeopleCountChart";
import { AttributeChartData, AttributeChartDataPair, RangeInfo } from "./types";

type AttributeChartProps = {
  chartDataPair: AttributeChartDataPair;
  rangeInfo: RangeInfo
  activeTabKey: string
  csvExportLogic: CsvExportLogic
  imageExportLogic: ImageExportLogic
  isLoading: boolean
  deviceIDs: DeviceType[]
  lineInfos: LineType[]
};

type BarLegendHidePropsType = {
  age0to9: boolean,
  age10to19: boolean,
  age20to39: boolean,
  age40to64: boolean,
  age65older: boolean,
  male: boolean,
  female: boolean
}

type DirectionLegendHideType = {
  in: boolean
  out: boolean
}

/**in/outを合算したデータを計算する */
const sumChartDataPair = (chartDataPair: AttributeChartDataPair): AttributeChartData => {

  const result: AttributeChartData = {
    male: {
      age0to9: chartDataPair.in.male.age0to9.map((x, i) => x + chartDataPair.out.male.age0to9[i]),
      age10to19: chartDataPair.in.male.age10to19.map((x, i) => x + chartDataPair.out.male.age10to19[i]),
      age20to39: chartDataPair.in.male.age20to39.map((x, i) => x + chartDataPair.out.male.age20to39[i]),
      age40to64: chartDataPair.in.male.age40to64.map((x, i) => x + chartDataPair.out.male.age40to64[i]),
      age65older: chartDataPair.in.male.age65older.map((x, i) => x + chartDataPair.out.male.age65older[i])
    },
    female: {
      age0to9: chartDataPair.in.female.age0to9.map((x, i) => x + chartDataPair.out.female.age0to9[i]),
      age10to19: chartDataPair.in.female.age10to19.map((x, i) => x + chartDataPair.out.female.age10to19[i]),
      age20to39: chartDataPair.in.female.age20to39.map((x, i) => x + chartDataPair.out.female.age20to39[i]),
      age40to64: chartDataPair.in.female.age40to64.map((x, i) => x + chartDataPair.out.female.age40to64[i]),
      age65older: chartDataPair.in.female.age65older.map((x, i) => x + chartDataPair.out.female.age65older[i])
    },
    labels: chartDataPair.labels,
    xAxisName: chartDataPair.xAxisName
  }
  return result
}

/**年齢・性別・方向レジェンドの状態とAPI取得データから、表示すべきグラフデータを計算する */
const calcFilteredChartData = (inOutLegendHide: DirectionLegendHideType, barLegendHide: BarLegendHidePropsType, chartDataPair: AttributeChartDataPair): AttributeChartData => {
  if (!inOutLegendHide.in && !inOutLegendHide.out) {
    return calcBarLegendFilteredChartData(barLegendHide, calcDirectionFilteredChartData("total", chartDataPair))
  } else if (inOutLegendHide.in && !inOutLegendHide.out) {
    return calcBarLegendFilteredChartData(barLegendHide, calcDirectionFilteredChartData("out", chartDataPair))
  } else if (!inOutLegendHide.in && inOutLegendHide.out) {
    return calcBarLegendFilteredChartData(barLegendHide, calcDirectionFilteredChartData("in", chartDataPair))
  } else {
    return calcDirectionFilteredChartData("none", chartDataPair)
  }
}

/**getFilteredChartData関数の補関数 */
const calcDirectionFilteredChartData = (type: "in" | "out" | "total" | "none", chartDataPair: AttributeChartDataPair): AttributeChartData => {
  if (type === "in") {
    return {
      male: chartDataPair["in"].male,
      female: chartDataPair["in"].female,
      labels: chartDataPair["labels"],
      xAxisName: chartDataPair["xAxisName"]
    }
  } else if (type === "out") {
    return {
      male: chartDataPair["out"].male,
      female: chartDataPair["out"].female,
      labels: chartDataPair["labels"],
      xAxisName: chartDataPair["xAxisName"]
    }
  } else if (type === "total") {
    return sumChartDataPair(chartDataPair)
  } else {
    const xs = { ...chartDataPair }
    return {
      male: {
        age0to9: [...xs.in.male.age0to9].fill(0),
        age10to19: [...xs.in.male.age10to19].fill(0),
        age20to39: [...xs.in.male.age20to39].fill(0),
        age40to64: [...xs.in.male.age40to64].fill(0),
        age65older: [...xs.in.male.age65older].fill(0)
      },
      female: {
        age0to9: [...xs.in.female.age0to9].fill(0),
        age10to19: [...xs.in.female.age10to19].fill(0),
        age20to39: [...xs.in.female.age20to39].fill(0),
        age40to64: [...xs.in.female.age40to64].fill(0),
        age65older: [...xs.in.female.age65older].fill(0)
      },
      labels: chartDataPair["labels"],
      xAxisName: chartDataPair["xAxisName"]
    }
  }
}

/**年齢・性別のレジェンド状態を考慮したデータを計算する */
const calcBarLegendFilteredChartData = (barLegendHide: BarLegendHidePropsType, initChartData: AttributeChartData): AttributeChartData => {
  const copyVariableChartData = {
    male: {} as { [k: string]: number[] },
    female: {} as { [k: string]: number[] },
    labels: initChartData.labels,
    xAxisName: initChartData.xAxisName
  };

  if (!barLegendHide.age0to9) {
    if (!barLegendHide.male) {
      copyVariableChartData.male.age0to9 = initChartData.male.age0to9.concat();
    }
    if (!barLegendHide.female) {
      copyVariableChartData.female.age0to9 = initChartData.female.age0to9.concat();
    }
  } else {
    copyVariableChartData.male.age0to9 = [...initChartData.male.age0to9].fill(0);
    copyVariableChartData.female.age0to9 = [...initChartData.female.age0to9].fill(0);
  }

  if (!barLegendHide.age10to19) {
    if (!barLegendHide.male) {
      copyVariableChartData.male.age10to19 = initChartData.male.age10to19.concat();
    }
    if (!barLegendHide.female) {
      copyVariableChartData.female.age10to19 = initChartData.female.age10to19.concat();
    }
  } else {
    copyVariableChartData.male.age10to19 = [...initChartData.male.age10to19].fill(0);
    copyVariableChartData.female.age10to19 = [...initChartData.female.age10to19].fill(0);
  }

  if (!barLegendHide.age20to39) {
    if (!barLegendHide.male) {
      copyVariableChartData.male.age20to39 = initChartData.male.age20to39.concat();
    }
    if (!barLegendHide.female) {
      copyVariableChartData.female.age20to39 = initChartData.female.age20to39.concat();
    }
  } else {
    copyVariableChartData.male.age20to39 = [...initChartData.male.age20to39].fill(0);
    copyVariableChartData.female.age20to39 = [...initChartData.female.age20to39].fill(0);
  }

  if (!barLegendHide.age40to64) {
    if (!barLegendHide.male) {
      copyVariableChartData.male.age40to64 = initChartData.male.age40to64.concat();
    }
    if (!barLegendHide.female) {
      copyVariableChartData.female.age40to64 = initChartData.female.age40to64.concat();
    }
  } else {
    copyVariableChartData.male.age40to64 = [...initChartData.male.age40to64].fill(0);
    copyVariableChartData.female.age40to64 = [...initChartData.female.age40to64].fill(0);
  }

  if (!barLegendHide.age65older) {
    if (!barLegendHide.male) {
      copyVariableChartData.male.age65older = initChartData.male.age65older.concat();
    }
    if (!barLegendHide.female) {
      copyVariableChartData.female.age65older = initChartData.female.age65older.concat();
    }
  } else {
    copyVariableChartData.male.age65older = [...initChartData.male.age65older].fill(0);
    copyVariableChartData.female.age65older = [...initChartData.female.age65older].fill(0);
  }

  if (!barLegendHide.male) {
    if (!barLegendHide.age0to9) {
      copyVariableChartData.male.age0to9 = initChartData.male.age0to9.concat();
    }
    if (!barLegendHide.age10to19) {
      copyVariableChartData.male.age10to19 = initChartData.male.age10to19.concat();
    }
    if (!barLegendHide.age20to39) {
      copyVariableChartData.male.age20to39 = initChartData.male.age20to39.concat();
    }
    if (!barLegendHide.age40to64) {
      copyVariableChartData.male.age40to64 = initChartData.male.age40to64.concat();
    }
    if (!barLegendHide.age65older) {
      copyVariableChartData.male.age65older = initChartData.male.age65older.concat();
    }
  } else {
    copyVariableChartData.male.age0to9 = [...initChartData.male.age0to9].fill(0);
    copyVariableChartData.male.age10to19 = [...initChartData.male.age10to19].fill(0);
    copyVariableChartData.male.age20to39 = [...initChartData.male.age20to39].fill(0);
    copyVariableChartData.male.age40to64 = [...initChartData.male.age40to64].fill(0);
    copyVariableChartData.male.age65older = [...initChartData.male.age65older].fill(0);
  }

  if (!barLegendHide.female) {
    if (!barLegendHide.age0to9) {
      copyVariableChartData.female.age0to9 = initChartData.female.age0to9.concat();
    }
    if (!barLegendHide.age10to19) {
      copyVariableChartData.female.age10to19 = initChartData.female.age10to19.concat();
    }
    if (!barLegendHide.age20to39) {
      copyVariableChartData.female.age20to39 = initChartData.female.age20to39.concat();
    }
    if (!barLegendHide.age40to64) {
      copyVariableChartData.female.age40to64 = initChartData.female.age40to64.concat();
    }
    if (!barLegendHide.age65older) {
      copyVariableChartData.female.age65older = initChartData.female.age65older.concat();
    }
  } else {
    copyVariableChartData.female.age0to9 = [...initChartData.female.age0to9].fill(0);
    copyVariableChartData.female.age10to19 = [...initChartData.female.age10to19].fill(0);
    copyVariableChartData.female.age20to39 = [...initChartData.female.age20to39].fill(0);
    copyVariableChartData.female.age40to64 = [...initChartData.female.age40to64].fill(0);
    copyVariableChartData.female.age65older = [...initChartData.female.age65older].fill(0);
  }

  return copyVariableChartData as AttributeChartData
}


export const AttributeAnalysisChart: React.FC<AttributeChartProps> = ({ isLoading, activeTabKey, chartDataPair, rangeInfo, csvExportLogic, imageExportLogic, lineInfos, deviceIDs }) => {

  const shapeChartData = () => {
    return variableChartData.labels.map((x, i) => {
      const male_total: number =
        variableChartData.male.age0to9[i] +
        variableChartData.male.age10to19[i] +
        variableChartData.male.age20to39[i] +
        variableChartData.male.age40to64[i] +
        variableChartData.male.age65older[i];
      const female_total: number =
        variableChartData.female.age0to9[i] +
        variableChartData.female.age10to19[i] +
        variableChartData.female.age20to39[i] +
        variableChartData.female.age40to64[i] +
        variableChartData.female.age65older[i];
      const age0to9_total: number = variableChartData.male.age0to9[i] + variableChartData.female.age0to9[i];
      const age10to19_total: number = variableChartData.male.age10to19[i] + variableChartData.female.age10to19[i]
      const age20to39_total: number = variableChartData.male.age20to39[i] + variableChartData.female.age20to39[i]
      const age40to64_total: number = variableChartData.male.age40to64[i] + variableChartData.female.age40to64[i]
      const age65older_total: number = variableChartData.male.age65older[i] + variableChartData.female.age65older[i]
      return {
        name: String(variableChartData.labels[i]),
        age0to9: Number.isNaN(age0to9_total) ? undefined : age0to9_total,
        age10to19: Number.isNaN(age10to19_total) ? undefined : age10to19_total,
        age20to39: Number.isNaN(age20to39_total) ? undefined : age20to39_total,
        age40to64: Number.isNaN(age40to64_total) ? undefined : age40to64_total,
        age65older: Number.isNaN(age65older_total) ? undefined : age65older_total,
        male: Number.isNaN(male_total) ? undefined : male_total,
        female: Number.isNaN(female_total) ? undefined : female_total,
      };
    });
  };

  const initialChartData = sumChartDataPair(chartDataPair)

  const [variableChartData, setVariableChartData] = useState(initialChartData)

  const [directionLegendHide, setDirectionLegendHide] = useState<DirectionLegendHideType>({ in: false, out: false })

  const [barLegendHide, setBarLegendHide] = useState<BarLegendHidePropsType>({
    age0to9: false,
    age10to19: false,
    age20to39: false,
    age40to64: false,
    age65older: false,
    male: false,
    female: false
  });

  const data = shapeChartData();

  useEffect(() => {
    setVariableChartData(initialChartData)
    setDirectionLegendHide({ in: false, out: false })
    setBarLegendHide({
      age0to9: false,
      age10to19: false,
      age20to39: false,
      age40to64: false,
      age65older: false,
      male: false,
      female: false
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDataPair])


  const directionSelectBar = (type: "in" | "out") => {
    if (type === "in") {
      const newHide = !directionLegendHide.in
      const newState = {
        ...directionLegendHide,
        in: newHide
      }
      setDirectionLegendHide(newState)
      setVariableChartData(calcFilteredChartData(newState, barLegendHide, chartDataPair))
    } else {
      const newHide = !directionLegendHide.out
      const newState = {
        ...directionLegendHide,
        out: newHide
      }
      setDirectionLegendHide(newState)
      setVariableChartData(calcFilteredChartData(newState, barLegendHide, chartDataPair))
    }
  }
  const selectBar = (e: any) => {
    const dataKey: keyof BarLegendHidePropsType = e.dataKey
    const newState = {
      ...barLegendHide,
      [dataKey]: !barLegendHide[dataKey]
    }

    setVariableChartData(calcFilteredChartData(directionLegendHide, newState, chartDataPair))
    setBarLegendHide(newState);
  }

  // CSVデータの更新・画像ファイル名更新
  const updateExportData = () => {
    if (activeTabKey !== "analysis" || data.length === 0) return
    if (rangeInfo.rangeType === null) return

    const rangeType = rangeInfo.rangeType
    let csvFileName = "属性分析"
    if (rangeType === "年") {
      const date = dayjs(new Date(rangeInfo.year!, 0))
      csvFileName += "_" + date.format("YYYY")
    } else if (rangeType === "月") {
      const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1))
      csvFileName += "_" + date.format("YYYYMM")
    } else if (rangeType === "日") {
      const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1, rangeInfo.day!))
      csvFileName += "_" + date.format("YYYYMMDD")
    } else if (rangeType === "時") {
      const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1, rangeInfo.day!, rangeInfo.hour!))
      csvFileName += "_" + date.format("YYYYMMDD_HH00")
    }

    const csvData: CsvDataType = []

    //デバイス・カメラ選択情報
    if (deviceIDs.length !== 0 && lineInfos.length !== 0) {
      const str = csvDeviceCameraString(deviceIDs, lineInfos)
      const firstHeader = ["デバイス・カメラ選択状態", str]
      csvData.push(firstHeader)
    } else if (deviceIDs.length !== 0) {
      const firstHeader = ["デバイス・カメラ選択状態", deviceIDs.map(d => d.deviceName).join(",")]//MORE:こっちもcsvDeviceCameraStringへ
      csvData.push(firstHeader)
    } else {
      const firstHeader = ["デバイス・カメラ選択状態", ""]
      csvData.push(firstHeader)
    }


    // header処理
    const headers: CsvDataType[0] = ["日付"]
    const row = data[0];
    const keys = (Object.keys(row) as (keyof typeof row)[])
    const visibleKeys = keys.filter(key => key === "name" || !barLegendHide[key])
    visibleKeys.forEach((key) => {
      let convertedKey: string = key
      if (key === "name") {
        convertedKey = chartDataPair.xAxisName
      }
      else if (key === "male") {
        convertedKey = "男性"
      } else if (key === "female") {
        convertedKey = "女性"
      } else if (key === "age0to9") {
        convertedKey = "0~9歳"
      } else if (key === "age10to19") {
        convertedKey = "10~19歳"
      } else if (key === "age20to39") {
        convertedKey = "20~39歳"
      } else if (key === "age40to64") {
        convertedKey = "40~64歳"
      } else if (key === "age65older") {
        convertedKey = "65歳以上"
      }
      headers.push(convertedKey)
    })
    csvData.push(headers)

    //行データの生成
    for (let i = 0; i < data.length; i++) {
      const csvLine: CsvDataType[0] = []
      const row = data[i];
      const visibleKeys = keys.filter(key => key === "name" || !barLegendHide[key])
      visibleKeys.forEach((key) => {
        const value = row[key]
        if (value !== undefined) {
          csvLine.push(value)
        }
      })


      //日付の追加
      const rangeType = rangeInfo.rangeType!
      const xAxisNameIndex = 0
      if (rangeType === "年") {
        const monthIndex = (csvLine[xAxisNameIndex] as number) - 1
        const date = dayjs(new Date(rangeInfo.year!, monthIndex)).format("YYYY-MM-DD HH:mm")
        csvLine.unshift(date)
      } else if (rangeType === "月") {
        const day = (csvLine[xAxisNameIndex] as number)
        const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1, day)).format("YYYY-MM-DD HH:mm")
        csvLine.unshift(date)
      } else if (rangeType === "日") {
        const hour = (csvLine[xAxisNameIndex] as number)
        const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1, rangeInfo.day!, hour)).format("YYYY-MM-DD HH:mm")
        csvLine.unshift(date)
      } else if (rangeType === "時") {
        const minute = (csvLine[xAxisNameIndex] as number)
        const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1, rangeInfo.day!, rangeInfo.hour!, minute)).format("YYYY-MM-DD HH:mm")
        csvLine.unshift(date)
      }
      csvData.push(csvLine)
    }

    csvExportLogic.setCsvState({
      fileName: csvFileName,
      data: csvData
    })
    imageExportLogic.imageStateRef.current.fileName = csvFileName

  }

  useEffect(() => {
    // MORE:useEffectでvariableChartDataを更新するのではなく、
    // variableChartDataを更新するタイミングで、updateExportData()すればアニメーションは動作すると思われる。
    // 現状の書き方だと自然な方法で実装できないので、アニメーションが機能しない実装となっている。
    if (activeTabKey === "analysis") {
      updateExportData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variableChartData, activeTabKey])

  // 初期表示中、APIデータ取得中にダウンロードしないようにする処理
  useEffect(() => {
    if (activeTabKey === "analysis" && !isLoading) {
      updateExportData()
      csvExportLogic.setIsCsvAvailable(true)
      imageExportLogic.setIsImageAvailable(true)
    } else if (activeTabKey === "analysis" && isLoading) {
      csvExportLogic.setIsCsvAvailable(false)
      imageExportLogic.setIsImageAvailable(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, activeTabKey])

  const formatLegendText = (value: string, entry: any) => {
    if (value === "0~9歳") {
      return barLegendHide.age0to9 ? <span style={{ textDecorationLine: "line-through", textDecorationColor: "black" }}>{value}</span> : value
    } else if (value === "10~19歳") {
      return barLegendHide.age10to19 ? <span style={{ textDecorationLine: "line-through", textDecorationColor: "black" }}>{value}</span> : value
    } else if (value === "20~39歳") {
      return barLegendHide.age20to39 ? <span style={{ textDecorationLine: "line-through", textDecorationColor: "black" }}>{value}</span> : value
    } else if (value === "40~64歳") {
      return barLegendHide.age40to64 ? <span style={{ textDecorationLine: "line-through", textDecorationColor: "black" }}>{value}</span> : value
    } else if (value === "65歳以上") {
      return barLegendHide.age65older ? <span style={{ textDecorationLine: "line-through", textDecorationColor: "black" }}>{value}</span> : value
    } else if (value === "男性") {
      return barLegendHide.male ? <span style={{ textDecorationLine: "line-through", textDecorationColor: "black" }}>{value}</span> : value
    } else if (value === "女性") {
      return barLegendHide.female ? <span style={{ textDecorationLine: "line-through", textDecorationColor: "black" }}>{value}</span> : value
    }
  };

  return (
    <div id="AttributeAnalysisChartPicture" style={{ width: "100%", display: "flex", placeItems: "center", backgroundColor: "white" }}>
      <div style={{ backgroundColor: "white", width: "90%" }}>
        {activeTabKey === "analysis" ? <ResponsiveContainer
          width="100%"
          aspect={3}>
          <BarChart
            id='AttributeAnalysisChart'
            width={1000}
            height={500}
            data={data}
            margin={{
              top: 40,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barCategoryGap={'20%'}
            barGap={0}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" label={{ value: chartDataPair.xAxisName, position: "right", offset: 15 }} />
            <YAxis allowDecimals={false} label={{ value: "人", position: "top", offset: 15 }} />
            <Tooltip />
            <Legend align="right" verticalAlign="middle" layout="vertical" onClick={selectBar} formatter={formatLegendText} />
            <Bar
              dataKey="age0to9" name="0~9歳" stackId="a" fill="#72e272" hide={barLegendHide.age0to9} />
            <Bar
              dataKey="age10to19" name="10~19歳" stackId="a" fill="#2bd52b" hide={barLegendHide.age10to19} />
            <Bar
              dataKey="age20to39" name="20~39歳" stackId="a" fill="#23b123" hide={barLegendHide.age20to39} />
            <Bar
              dataKey="age40to64" name="40~64歳" stackId="a" fill="#1d8d1d" hide={barLegendHide.age40to64} />
            <Bar
              dataKey="age65older" name="65歳以上" stackId="a" fill="#156a15" hide={barLegendHide.age65older} />
            <Bar
              dataKey="male" name="男性" stackId="b" fill="#74a1f1" hide={barLegendHide.male} />
            <Bar
              dataKey="female" name="女性" stackId="b" fill="#feb9db" hide={barLegendHide.female} />
          </BarChart>

        </ResponsiveContainer>
          : <></>}
      </div>

      <div style={{ backgroundColor: "white", width: "10%" }}> <div>
        {inoutLegend("in", IN_LEGEND_COLOR, directionLegendHide.in, () => {
          directionSelectBar("in")
        })}
        {inoutLegend("out", OUT_LEGEND_COLOR, directionLegendHide.out, () => {
          directionSelectBar("out")
        })}
      </div></div>
    </div >
  );
};

const IN_LEGEND_COLOR = IN_COLOR
const OUT_LEGEND_COLOR = OUT_COLOR

const inoutLegend = (type: "in" | "out", color: string, hide: boolean, onClick: () => void) => {

  const GRAY_OUT_COLOR = "rgb(204, 204, 204)"
  const clr = hide ? GRAY_OUT_COLOR : color
  const strikethrough = hide ? "line-through" : "none"

  return <div onClick={onClick}>
    <svg width="14" height="14" viewBox="0 0 32 32" version="1.1" >
      <path strokeWidth="4" fill="none" stroke={clr} d="M0,16h10.666666666666666
    A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
    H32M21.333333333333332,16
    A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16">
      </path>
    </svg>
    <span style={{ color: clr, cursor: "default" }}>{` : `}<span style={{ textDecorationLine: strikethrough, textDecorationColor: "black" }}>{type}</span></span>
  </div>
}