import { createContext, useState, useRef } from "react"
import { CropArea } from "../../../../store/types/models"
import DataBasicForm from "../type"

export type CropAreaControllerContextProps = {
    selectCropArea: (select: boolean) => void
    rmeoveCropArea: () => void
    setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>
    cropAreaData: CropArea | null
    setCropAreaData: React.Dispatch<React.SetStateAction<CropArea | null>>
    isCreatingRef: React.MutableRefObject<boolean>;
    isCropAreaSelected: boolean
}

export const CropAreaContext = createContext<CropAreaControllerContextProps | null>(null);

type CropAreaProviderType = {
    dataForm: DataBasicForm;
    children: React.ReactNode;
    setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
};

export const CropAreaProvider = ({ children, dataForm, setDataForm }: CropAreaProviderType) => {
    const [isCropAreaSelected, setIsCropAreaSelected] = useState<boolean>(false);

    const [cropAreaData, setCropAreaData] = useState<CropArea | null>(dataForm.crop_area);

    const rmeoveCropArea = () => {
        if (cropAreaData !== null) {
            setIsCropAreaSelected(false)
            setCropAreaData(null)
            setDataForm({
                ...dataForm,
                crop_area: null
            })
        }
    };

    const selectCropArea = (select: boolean) => {
        if (select !== isCropAreaSelected) {
            setIsCropAreaSelected(select)
        }
    };

    const isCreatingRef = useRef(false);

    const value: CropAreaControllerContextProps = {
        setDataForm,
        isCreatingRef,
        cropAreaData,
        rmeoveCropArea,
        isCropAreaSelected,
        selectCropArea,
        setCropAreaData,
    };
    return <CropAreaContext.Provider value={value}>{children}</CropAreaContext.Provider>;
};