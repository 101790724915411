import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import styled from "styled-components";
import API from "../../api";
import useAPIService from "../../api/useAPIService";
import { LoadingComponent } from "../../components/LoadingLayout";
import Logo from "../../components/Logo";
import { PermissionLayout } from "../../components/PermissionLayout";
import { ResponseStatus } from "../../constant";
import { AuthContext } from "../../contexts/AuthContext";
import { Device, UserRole } from "../../store/types/models";
import {
  handleErrorNotification,
  handleSuccessNotification
} from "../../utils";


const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
const Heading = styled.div`
  padding-top: 1rem;
  margin: 0 2rem;
  display: flex;
  align-items: center;
`;

const PageHeadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const PageHeading = styled.h3`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  padding: 2rem;
  flex: 1;
  max-width: 1700px;
  min-width: 700px;
`;

export const DeviceSettings: React.FC = () => {
  const { user } = React.useContext(AuthContext);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<Device | undefined>();
  const [tempItemName, setTempItemName] = React.useState("");

  const handleCloseModal = () => {
    setTempItemName("");
    setSelectedItem(undefined);
    setShowModal(false);
  };

  const handleChangeModal = async () => {
    await updateDevice({ id: selectedItem?.id, name: tempItemName });
    handleCloseModal();
    getAllDevices();
  };

  const {
    invoke: getAllDevices,
    data: dataListDevices,
    isLoading,
    status: getAllDeviceStatus,
    error: getAllDeviceError,
  } = useAPIService<{
    data: Device[];
    pagination: { skip: number; limit: number; total: number };
  }>(API.Devices.getAllDevices);

  const {
    invoke: updateDevice,
    status: updateDeviceStatus,
    error: updateDeviceError,
  } = useAPIService(API.Devices.updateDevice);

  React.useEffect(() => {
    getAllDevices();
  }, [getAllDevices]);

  React.useEffect(() => {
    if (getAllDeviceStatus === ResponseStatus.FAIL) {
      handleErrorNotification(getAllDeviceError?.response?.data?.detail);
    }
  }, [getAllDeviceStatus, getAllDeviceError]);

  React.useEffect(() => {
    if (updateDeviceStatus) {
      updateDeviceStatus === ResponseStatus.SUCCESS
        ? handleSuccessNotification("デバイス名が正常に更新されました")
        : handleErrorNotification(updateDeviceError?.response?.data?.detail);
    }
  }, [updateDeviceStatus, updateDeviceError]);

  if (user && user.role === UserRole.OPERATOR) {
    return <PermissionLayout />;
  }



  return (
    <Wrapper>
      <Heading id={"devices-heading"}>
      <Logo />
      </Heading>
      <ContentWrapper>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div
              className="list-setting"
              style={{
                padding: "1rem",
                backgroundColor: "#fff",
                borderRadius: 10,
              }}
            >
              <PageHeadingWrap>
                <PageHeading>デバイス設定</PageHeading>
              </PageHeadingWrap>
              <Table style={{ width: "100%" }}>
                <thead className="table-primary">
                  <tr>
                    <th>No</th>
                    <th data-cy={"_device_header_id"}>デバイスID</th>
                    <th data-cy={"_device_header_name"}>デバイス名</th>
                    <th
                      data-cy={"_device_header_action"}
                      style={{ textAlign: "center" }}
                    >
                      編集
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataListDevices &&
                    dataListDevices.data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td style={{ textAlign: "center" }}>
                            <Button
                              data-testid={`button`}
                              id="button"
                              data-cy={"_editButton" + (index + 1)}
                              variant="primary"
                              onClick={() => {
                                setSelectedItem(item);
                                setTempItemName(item.name || "");
                                setShowModal(true);
                              }}
                            >
                              編集
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </ContentWrapper>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>デバイス名変更</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              backgroundColor: "#F0F0F0",
              padding: "0.5rem",
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            ID: {selectedItem?.id}
          </div>
          <div
            style={{
              display: "flex",
              padding: "1rem 2rem",
              justifyContent: "space-around",
            }}
          >
            <div>デバイス名変更</div>
            <div>
              <input
                id="inputDevices"
                type="text"
                data-cy="_editDeviceName"
                maxLength={20}
                value={tempItemName}
                onChange={(e) => setTempItemName(e.target.value.trim())}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            id="changeModal"
            disabled={tempItemName === ""}
            data-cy="_saveButton"
            variant="primary"
            onClick={handleChangeModal}
          >
            保存
          </Button>
          <Button
            id="closeModal"
            data-cy="_cancelButton"
            variant="secondary"
            onClick={handleCloseModal}
          >
            戻る
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};
