import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
const Wrapper = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const PermissionLayout: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Wrapper id="permission-layout">
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p>Sorry! You don't have permission.</p>
        <Button
          id="goBackButton"
          variant="primary"
          onClick={() => navigate("/dashboard/detection-history")}
        >
          Go Back
        </Button>
      </div>
    </Wrapper>
  );
};
