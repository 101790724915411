import React from "react";
import {
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { MultipleAIThreadholdInput } from "./MultipleAIThreadholdInput";
import DataBasicForm from "./type";
import NumberFormat from "react-number-format";

const basicInfoSettings = [
  {
    label: "デバイスID",
    name: "deviceID",
    disable: true,
  },
  {
    label: "AI1閾値",
    name: "ai_threshold_first",
    showTooltip: true,
    message: "0~1の範囲で0.1刻みで入力してください",
  },
  {
    label: "デバイス名",
    name: "deviceName",
    disable: true,
  },
  {
    label: "AI2閾値",
    name: "ai_threshold_second",
    showTooltip: true,
    message: "0~1の範囲で0.1刻みで入力してください",
  },
  {
    label: "カメラID",
    name: "cameraID",
    disable: true,
  },
  {
    label: "AI設定1",
    name: "list_threshold_1",
    maxLength: 100,
    showTooltip: true,
    message: "セミコロン(;)で区切って値を入力してください",
  },
  {
    label: "カメラ名",
    name: "cameraName",
    maxLength: 20,
    minLength: 1,
    require: true,
  },
  {
    label: "AI設定2",
    name: "list_threshold_2",
    maxLength: 100,
    showTooltip: true,
    message: "セミコロン(;)で区切って値を入力してください",
  },
  {
    label: "RTSP URL",
    name: "rtspUrl",
  },
];

type Props = {
  setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
  dataForm: DataBasicForm;
};

export const BasicInfor: React.FC<Props> = ({ setDataForm, dataForm }) => {
  const renderTooltip = (props: any, item: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {item.message}
    </Tooltip>
  );
  const cameraName = dataForm?.cameraName;
  return (
    <div style={{ marginTop: "0.5rem" }}>
      <div className="row row-cols-2">
        {basicInfoSettings.map((item) => {
          if (["list_threshold_1", "list_threshold_2"].includes(item.name))
            return (
              <MultipleAIThreadholdInput
                setDataForm={setDataForm}
                dataForm={dataForm}
                item={item}
                key={item.name}
              />
            );
          const isCameraField = item.name === "cameraName";
          let isFieldValid: boolean | undefined = undefined;
          if (isCameraField) {
            isFieldValid = !cameraName;
          }

          return (
            <div
              key={item.name}
              className="col"
              style={{
                display: "flex",
                padding: "0.5rem 2rem",
                justifyContent: "space-around",
              }}
            >
              {item.showTooltip ? (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => renderTooltip(props, item)}
                >
                  <InputGroup className="mb-3" lang="en-US">
                    <InputGroup.Text id="basic-addon1" style={{ width: "30%" }}>
                      {item.label}
                    </InputGroup.Text>
                    <NumberFormat
                        id={item.name}
                        value={
                          dataForm[
                              `${item.name as keyof DataBasicForm}`
                              ]?.toString() || ""
                        }
                        prefix=""
                        decimalSeparator="."
                        displayType="input"
                        type="text"
                        isAllowed={values => {
                          const { formattedValue, floatValue } = values;

                          if (floatValue == null) {
                            return formattedValue === '';
                          } else if (floatValue > 1 || floatValue < 0 || floatValue.toString().length > 3) {
                            return false;
                          } else {
                            return floatValue.toString().length <= 3;
                          }
                        }}
                        style={{ width: "70%" }}
                        placeholder={"自由入力"}
                        aria-label={item.label}
                        aria-describedby="basic-addon1"
                        className="form-control"
                        onValueChange={(e) => {
                          const value = parseFloat(e.value);

                          if (
                              value > 1 ||
                              value < 0 ||
                              value.toString().length > 3
                          ) {
                            return;
                          }
                          setDataForm((prev) => {
                            return {
                              ...prev,
                              [item.name]: value.toString(),
                            };
                          });
                        }}
                    />
                  </InputGroup>
                </OverlayTrigger>
              ) : (
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1" style={{ width: "30%" }}>
                    {item.label}
                  </InputGroup.Text>
                  <FormControl
                    id={item.name}
                    value={
                      dataForm[
                        `${item.name as keyof DataBasicForm}`
                      ]?.toString() || ""
                    }
                    onChange={(e) =>
                      setDataForm((prev) => {
                        return { ...prev, [item.name]: e.target.value.trim() };
                      })
                    }
                    required={item.require}
                    disabled={item.disable}
                    isInvalid={isFieldValid}
                    style={{ width: "70%" }}
                    placeholder={"自由入力"}
                    aria-label={item.label}
                    aria-describedby="basic-addon1"
                    maxLength={item.maxLength}
                    minLength={item.minLength}
                  />
                </InputGroup>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
