import React, { useState, useEffect } from "react";
import { AttributeChartData, AttributeChartDataPair, RangeInfo } from "./types";
import { GetRangeArray, GetXAxisLabels, GetXAxisName } from "./common";
import { AttributeAnalysisChart } from "./AttributeAnalysisChart";
import { DropdownOfDay } from "./DropdownOfDay";
import { DropdownOfHour } from "./DropdownOfHour";
import { DropdownOfMonth } from "./DropdownOfMonth";
import { DropdownOfRange } from "./DropdownOfRange";
import { DropdownOfYear } from "./DropdownOfYear";
import useAPIService from "../../../api/useAPIService";
import API from "../../../api";
import { handleErrorNotification } from "../../../utils";
import { ResponseStatus } from "../../../constant";
import { CsvExportLogic, ImageExportLogic } from "../Crowddetection";
import { DeviceLineSelectContainer, DeviceType, LineType } from "./DeviceLineSelect";
import objectHash from "object-hash";

type AttributeGenderBackEndType = {
  "0-9歳": number,
  "10-19歳以下": number,
  "20-39歳以下": number,
  "40-64歳以下": number,
  "65歳以上": number
}

export type XRange = "month" | "day" | "hour" | "minute"

/**
 * レンジ変更時に期間が整合的になるように自動変更する
 * 更新があるならtrueを返す
 */
export function setValidRangeInfo(now: Date, setRangeInfo: (value: React.SetStateAction<RangeInfo>) => void, rangeInfo: RangeInfo): boolean {
  if (rangeInfo.month && rangeInfo.year === now.getFullYear() && now.getMonth() + 1 < rangeInfo.month) {
    setRangeInfo({ ...rangeInfo, month: now.getMonth() + 1 });
    return true
  } else if (rangeInfo.year === now.getFullYear() && rangeInfo.month === now.getMonth() + 1 && rangeInfo.day && now.getDate() < rangeInfo.day) {
    setRangeInfo({ ...rangeInfo, month: now.getMonth() + 1, day: now.getDate() });
    return true
  } else if (rangeInfo.year === now.getFullYear() && rangeInfo.month === now.getMonth() + 1 && rangeInfo.day === now.getDate() && rangeInfo.hour && now.getHours() < rangeInfo.hour) {
    setRangeInfo({ ...rangeInfo, month: now.getMonth() + 1, day: now.getDate(), hour: now.getHours() });
    return true
  }

  if (rangeInfo.year && rangeInfo.month && rangeInfo.day) {
    const lastDay = new Date(rangeInfo.year, rangeInfo.month, 0);
    if (lastDay.getDate() < rangeInfo.day) {
      setRangeInfo({ ...rangeInfo, day: lastDay.getDate() });
      return true
    }
  }

  return false
}

type GendarPairBE<X extends XRange> = Record<X, number> & {
  "male": AttributeGenderBackEndType,
  "female": AttributeGenderBackEndType
}

type AttributeBackendType<X extends XRange> = Record<"in" | "out", GendarPairBE<X>[]>

/**APIデータを整形処理途中の型 */
type AttributeIntermediateMaps = {
  in: Map<number, AttributeIntermediateType>
  out: Map<number, AttributeIntermediateType>
}

/**APIデータを整形処理途中の型 */
type AttributeIntermediateType = {
  male: {
    age0to9: number,
    age10to19: number,
    age20to39: number,
    age40to64: number,
    age65older: number,
  },
  female: {
    age0to9: number,
    age10to19: number,
    age20to39: number,
    age40to64: number,
    age65older: number,
  },
}

const ALL_AGES: (keyof AttributeIntermediateType["male"])[] = ["age0to9", "age10to19", "age20to39", "age40to64", "age65older"]
const ALL_GENDERS = ["male", "female"] as const

type UseAttributeChartDataType = {
  chartDataPair: AttributeChartDataPair;
  isLoading: boolean;
  setRangeInfo: React.Dispatch<React.SetStateAction<RangeInfo>>;
  rangeInfo: RangeInfo;
  callback: (params: { devices: DeviceType[], lines: LineType[] }) => void
  deviceIDs: DeviceType[]
  lineInfos: LineType[]
};

const arrayToMap = <K extends XRange,>(array: GendarPairBE<K>[], unit_of_time: K): Map<number, AttributeIntermediateType> => {
  const dict: Map<number, AttributeIntermediateType> = new Map();
  array.forEach((x) => {
    dict.set(x[unit_of_time], {
      male: {
        age0to9: x["male"]["0-9歳"] ? x["male"]["0-9歳"] : 0,
        age10to19: x["male"]["10-19歳以下"] ? x["male"]["10-19歳以下"] : 0,
        age20to39: x["male"]["20-39歳以下"] ? x["male"]["20-39歳以下"] : 0,
        age40to64: x["male"]["40-64歳以下"] ? x["male"]["40-64歳以下"] : 0,
        age65older: x["male"]["65歳以上"] ? x["male"]["65歳以上"] : 0,
      },
      female: {
        age0to9: x["female"]["0-9歳"] ? x["female"]["0-9歳"] : 0,
        age10to19: x["female"]["10-19歳以下"] ? x["female"]["10-19歳以下"] : 0,
        age20to39: x["female"]["20-39歳以下"] ? x["female"]["20-39歳以下"] : 0,
        age40to64: x["female"]["40-64歳以下"] ? x["female"]["40-64歳以下"] : 0,
        age65older: x["female"]["65歳以上"] ? x["female"]["65歳以上"] : 0,
      },
    })
  }
  )
  return dict
};

/**APIデータを整形 */
const setDictAttributeDataPair = <T extends XRange,>(arraysOfTimePair: AttributeBackendType<T>, unit_of_time: T): AttributeIntermediateMaps => {
  const arrayIn = arraysOfTimePair["in"]
  const arrayOut = arraysOfTimePair["out"]

  return {
    in: arrayToMap(arrayIn, unit_of_time),
    out: arrayToMap(arrayOut, unit_of_time)
  }
}

/** レンジ・デバイス・カメラ（ライン）情報から、チャートデータを提供する */
export function useAttributeChartData(): UseAttributeChartDataType {
  const now = new Date();

  const defaultRangeInfo: RangeInfo = {
    rangeType: "時",
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
    hour: now.getHours(),
  };

  const initChartData: Pick<AttributeChartData, "male" | "female"> = {
    male: {
      age0to9: [],
      age10to19: [],
      age20to39: [],
      age40to64: [],
      age65older: [],
    },
    female: {
      age0to9: [],
      age10to19: [],
      age20to39: [],
      age40to64: [],
      age65older: [],
    },
  };
  const initChartDataPair: AttributeChartDataPair = {
    in: initChartData,
    out: initChartData,
    labels: [],
    xAxisName: ""
  }

  const [chartDataPair, setChartDataPair] = useState<AttributeChartDataPair>(initChartDataPair);
  const [rangeInfo, setRangeInfo] = useState<RangeInfo>(defaultRangeInfo);
  const [devices, setDevices] = useState<DeviceType[]>([])
  const [lines, setLines] = useState<LineType[]>([])

  const callback: (params: { devices: DeviceType[], lines: LineType[] }) => void = ({ devices: devicesA, lines: linesA }) => {
    if (objectHash(devices) === objectHash(devicesA) && objectHash(linesA) === objectHash(lines)) {
      return
    } else {
      setDevices(devicesA)
      setLines(linesA)
    }
  }


  const { invoke: getChartData, data: apiData, status, isLoading } = useAPIService<AttributeBackendType<any>>(API.AttributeAnalysis.getChartData)

  useEffect(() => {
    // データが取得できなかった場合の処理
    if (status === ResponseStatus.FAIL) {
      handleErrorNotification("データ取得に失敗しました");

      const emptyData: Pick<AttributeChartData, "male" | "female"> = {
        male: {
          age0to9: [],
          age10to19: [],
          age20to39: [],
          age40to64: [],
          age65older: [],
        },
        female: {
          age0to9: [],
          age10to19: [],
          age20to39: [],
          age40to64: [],
          age65older: [],
        },
      }

      const emptyPair: AttributeChartDataPair = {
        in: emptyData,
        out: emptyData,
        labels: GetXAxisLabels(rangeInfo),
        xAxisName: GetXAxisName(rangeInfo.rangeType)
      };
      setChartDataPair(emptyPair)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])


  const apiDataToChartDataPair = (apiData: AttributeBackendType<XRange>, rangeInfo: RangeInfo): AttributeChartDataPair => {
    const labels = GetXAxisLabels(rangeInfo);
    const modifiedPair: Partial<AttributeChartDataPair> = {
      labels: labels,
      xAxisName: GetXAxisName(rangeInfo.rangeType)
    }


    if (rangeInfo.rangeType === "年") {
      const dictPair = setDictAttributeDataPair(apiData, "month");

      (["in", "out"] as const).forEach(direction => {
        const dict = dictPair[direction]

        const ARRAYS: Pick<AttributeChartData, "male" | "female"> = {
          male: {
            age0to9: [],
            age10to19: [],
            age20to39: [],
            age40to64: [],
            age65older: []
          },
          female: {
            age0to9: [],
            age10to19: [],
            age20to39: [],
            age40to64: [],
            age65older: []
          }
        }

        if (now.getFullYear() === rangeInfo.year) {
          ALL_GENDERS.forEach(gender => {
            ALL_AGES.forEach(age => {
              ARRAYS[gender][age] = GetRangeArray(now.getMonth() + 1).map((_, i) => dict.get(i + 1) ? dict.get(i + 1)![gender][age] : 0);
            })
          })
        } else {
          ALL_GENDERS.forEach(gender => {
            ALL_AGES.forEach(age => {
              ARRAYS[gender][age] = GetRangeArray(12).map((_, i) => dict.get(i + 1) ? dict.get(i + 1)![gender][age] : 0);
            })
          })
        }

        const modified: Pick<AttributeChartData, "male" | "female"> = ARRAYS;
        modifiedPair[direction] = modified
      })
    } else if (rangeInfo.rangeType === "月") {
      const dictPair = setDictAttributeDataPair(apiData, "day");

      const visibleLastDay = now.getFullYear() === rangeInfo.year && now.getMonth() + 1 === rangeInfo.month ? now.getDate() : new Date(rangeInfo.year!, rangeInfo.month!, 0).getDate();

      (["in", "out"] as const).forEach(direction => {
        const dict = dictPair[direction]

        const ARRAYS: Pick<AttributeChartData, "male" | "female"> = {
          male: {
            age0to9: [],
            age10to19: [],
            age20to39: [],
            age40to64: [],
            age65older: []
          },
          female: {
            age0to9: [],
            age10to19: [],
            age20to39: [],
            age40to64: [],
            age65older: []
          }
        }

        ALL_GENDERS.forEach(gender => {
          ALL_AGES.forEach(age => {
            ARRAYS[gender][age] = GetRangeArray(visibleLastDay).map((_, i) => dict.get(i + 1) ? dict.get(i + 1)![gender][age] : 0);
          })
        })

        const modified: Pick<AttributeChartData, "male" | "female"> = ARRAYS;

        modifiedPair[direction] = modified
      })

    } else if (rangeInfo.rangeType === "日") {

      const visibleLastHour = now.getFullYear() === rangeInfo.year && now.getMonth() + 1 === rangeInfo.month && now.getDate() === rangeInfo.day ? now.getHours() : 23

      const dictPair = setDictAttributeDataPair(apiData, "hour");

      (["in", "out"] as const).forEach(direction => {
        const dict = dictPair[direction]

        const ARRAYS: Pick<AttributeChartData, "male" | "female"> = {
          male: {
            age0to9: [],
            age10to19: [],
            age20to39: [],
            age40to64: [],
            age65older: []
          },
          female: {
            age0to9: [],
            age10to19: [],
            age20to39: [],
            age40to64: [],
            age65older: []
          }
        }

        ALL_GENDERS.forEach(gender => {
          ALL_AGES.forEach(age => {
            ARRAYS[gender][age] = GetRangeArray(visibleLastHour + 1).map((_, i) => dict.get(i) ? dict.get(i)![gender][age] : 0);
          })
        })

        const modified: Pick<AttributeChartData, "male" | "female"> = ARRAYS;
        modifiedPair[direction] = modified

      })
    } else if (rangeInfo.rangeType === "時") {

      const visibleLastMinute = now.getFullYear() === rangeInfo.year && now.getMonth() + 1 === rangeInfo.month && now.getDate() === rangeInfo.day && now.getHours() === rangeInfo.hour ? Math.floor(now.getMinutes() / 5) + 1 : 12
      const dictPair = setDictAttributeDataPair(apiData, "minute");

      (["in", "out"] as const).forEach(direction => {
        const dict = dictPair[direction]

        const ARRAYS: Pick<AttributeChartData, "male" | "female"> = {
          male: {
            age0to9: [],
            age10to19: [],
            age20to39: [],
            age40to64: [],
            age65older: []
          },
          female: {
            age0to9: [],
            age10to19: [],
            age20to39: [],
            age40to64: [],
            age65older: []
          }
        }

        ALL_GENDERS.forEach(gender => {
          ALL_AGES.forEach(age => {
            ARRAYS[gender][age] = GetRangeArray(visibleLastMinute).map((_, i) => dict.get(i * 5) ? dict.get(i * 5)![gender][age] : 0);
          })
        })

        const modified: Pick<AttributeChartData, "male" | "female"> = ARRAYS;

        modifiedPair[direction] = modified
      })
    }
    return modifiedPair as AttributeChartDataPair
  }


  useEffect(() => {
    if (!apiData) return;
    const modifiedPair = apiDataToChartDataPair(apiData, rangeInfo)
    setChartDataPair(modifiedPair);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [apiData]);


  useEffect(() => {
    if (setValidRangeInfo(now, setRangeInfo, rangeInfo)) {
      return
    }

    const params: Parameters<typeof API.CrowdDetection.getChartData>[0] = {
      rangeInfo: rangeInfo,
      lineInfos: lines.map(l => ({ deviceID: l.camera.deviceID, cameraID: l.camera.cameraID, lineNo: l.no })),
      deviceIDs: devices.map(d => d.deviceID)
    }
    getChartData(params)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeInfo, lines, devices]);

  return { isLoading, chartDataPair, setRangeInfo, rangeInfo, callback, deviceIDs: devices, lineInfos: lines };
}

type Props = {
  activeTabKey: string
  csvExportLogic: CsvExportLogic
  imageExportLogic: ImageExportLogic
};
export const AttributeAnalysisView: React.FC<Props> = ({ activeTabKey, csvExportLogic, imageExportLogic }) => {
  const { chartDataPair, setRangeInfo, rangeInfo, isLoading, callback, deviceIDs, lineInfos } = useAttributeChartData();

  return (
    <div>
      <div style={{ justifyContent: "flex-start", display: "flex", }}>
        <div style={{
          display: "flex", marginLeft: "5rem",
          alignItems: "center"
        }} >
          <DropdownOfRange setRangeInfo={setRangeInfo} rangeInfo={rangeInfo} />
          <DropdownOfYear setRangeInfo={setRangeInfo} rangeInfo={rangeInfo} />
          <DropdownOfMonth setRangeInfo={setRangeInfo} rangeInfo={rangeInfo} />
          <DropdownOfDay setRangeInfo={setRangeInfo} rangeInfo={rangeInfo} />
          <DropdownOfHour setRangeInfo={setRangeInfo} rangeInfo={rangeInfo} />
        </div>
        <div className="DeviceSelectHelpText" style={{ marginLeft: "5px", paddingRight: "120px" }}>
          <DeviceLineSelectContainer callback={callback} />
        </div>
      </div>

      <AttributeAnalysisChart
        isLoading={isLoading}
        activeTabKey={activeTabKey} chartDataPair={chartDataPair} rangeInfo={rangeInfo}
        csvExportLogic={csvExportLogic}
        imageExportLogic={imageExportLogic}
        deviceIDs={deviceIDs}
        lineInfos={lineInfos}
      />
    </div>
  );
};
