import React from "react";
import API from "../api";
import useAPIService from "../api/useAPIService";
import { User } from "../store/types/models";

export type AuthContextProps = {
  user: User | null;
};
const defaultValue: AuthContextProps = {
  user: null,
};
export const AuthContext = React.createContext<AuthContextProps>(defaultValue);
export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<User | null>(null);

  const { invoke: getCurrentUser, data: dataUser } = useAPIService(
    API.User.getUser
  );

  React.useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  React.useEffect(() => {
    if (dataUser) {
      setUser(dataUser);
    }
  }, [dataUser]);
  const value: AuthContextProps = { user };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
