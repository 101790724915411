import Axios, { AxiosInstance } from "axios";
import { ACCESS_TOKEN_KEY } from "../constant";
import { CamerasMethod, CamerasType } from "./method/Cameras";
import {
  DetectionHistoryMethod,
  DetectionHistoryType,
} from "./method/DetectionHistory";
import { DevicesMethod, DevicesType } from "./method/Devices";
import { UserMethod, UserType } from "./method/User";
import { LogMethod, LogType } from "./method/Log";
import {
  UserManagementMethod,
  UserManagementType,
} from "./method/UserManagement";
import { CrowdDetectionMethod, CrowdDetectionType } from "./method/CrowdDetection";
import { AttributeAnalysisMethod, AttributeAnalysisType } from "./method/AttributeAnalysis";


let instance: SDK;

const baseUrl = process.env.REACT_APP_API_URL || "";
const siginUrl = process.env.REACT_APP_SIGIN_URL || "";

class SDK {
  private request!: AxiosInstance;
  public accessToken: string | null | undefined;
  public DetectionHistory: DetectionHistoryType;
  public CrowdDetection: CrowdDetectionType;
  public AttributeAnalysis: AttributeAnalysisType;
  public Devices: DevicesType;
  public Cameras: CamerasType;
  public UserManagement: UserManagementType;
  public User: UserType;
  public Log: LogType;

  constructor() {
    if (!instance) {
      instance = this;
    }
    this.request = Axios.create({
      baseURL: `${baseUrl}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
      },
    });
    this.request?.interceptors?.request.use((config) => {
      if (this.accessToken && config.headers) {
        config.headers.Authorization = `Bearer ${this.accessToken}`;
      }
      return config;
    });
    this.request?.interceptors?.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          window.location.href = `${siginUrl}`;
        }
        return Promise.reject(error);
      }
    );

    this.CrowdDetection = CrowdDetectionMethod(this.request);
    this.AttributeAnalysis = AttributeAnalysisMethod(this.request);
    this.DetectionHistory = DetectionHistoryMethod(this.request);
    this.Devices = DevicesMethod(this.request);
    this.Cameras = CamerasMethod(this.request);
    this.UserManagement = UserManagementMethod(this.request);
    this.User = UserMethod(this.request);
    this.Log = LogMethod(this.request);
    return instance;
  }
  setToken = (token: string | null | undefined) => {
    this.accessToken = token;
  };
}
const API = new SDK();

export default API;
