import { RangeInfo, RangeType } from "./types";

export const GetRangeArray = (len: number): number[] => {
    return Array.from(new Array(len)).map((v, i) => i)
}

export const GetAllDaysInMonth = (year: number, month: number) => {
    const monthIndex = month - 1
    const date = new Date(year, monthIndex, 1);
    const dates = [];

    while (date.getMonth() === monthIndex) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
};

export const GetXAxisName = (rangeType: RangeType | null): string => {

    switch (rangeType) {
        case '年':
            return "月"
        case '月':
            return '日'
        case '日':
            return '時'
        case '時':
            return '分'
        default:
            return ""
    }
}

export const GetXAxisLabels = (rangeInfo: RangeInfo): string[] => {

    switch (rangeInfo.rangeType) {
        case '年':
            return GetRangeArray(12).map((x) => (x + 1).toString());
        case '月':
            if (rangeInfo.year && rangeInfo.month) {
                return GetAllDaysInMonth(rangeInfo.year, rangeInfo.month).map((x) =>
                    x.getDate().toString()
                );
            }
            break;
        case '日':
            return GetRangeArray(24).map((x) => (x).toString());
        case '時':
            return GetRangeArray(12).map((x) => (x * 5).toString());
    }

    return []
}