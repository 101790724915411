/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, ListGroup, Spinner } from "react-bootstrap";
import styled from "styled-components";
import objectHash from "object-hash";
import API from "../../../api";
import useAPIService from "../../../api/useAPIService";
import { ResponseStatus } from "../../../constant";
import {
  handleErrorNotification,
  handleSuccessNotification,
} from "../../../utils";
import { AREA_COLORS, AreaController } from "./AreaController";
import DataBasicForm from "./type";
import { AuthContext } from "../../../contexts/AuthContext";
import { LineCrossLayer, LINELAYER_ZINDEX_DOWN, LINELAYER_ZINDEX_UP } from "./linecross-layer/LineCrossLayer";
import { LineCrossContext } from "./linecross-layer/LineCrossContext";
import { CropAreaContext } from "./linecross-layer/CropAreaContext";

type Props = {
  setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
  dataForm: DataBasicForm;
  areaDetection: React.MutableRefObject<any>;
  setAreaHash: (hash: string) => void;
};

const CaptureImageWrapper = styled.div`
  flex: 3;
  justify-content: center;
  display: flex;
`;

const CaptureImageContent = styled.div`
  position: relative;
  width: 1028px;
  height: 578px;
  &:before {
    height: 40px;
    margin: auto;
    content: "No image";
    position: absolute;
    color: rgb(187, 182, 182);
    z-index: 0;
    font-size: 1.5rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 104px;
  }
`;
const CaptureImage = styled.img`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  border: 1px solid #efeeee;
`;

const MockArea = [
  { id: 1, label: "エリア 1" },
  { id: 2, label: "エリア 2" },
  { id: 3, label: "エリア 3" },
  { id: 4, label: "エリア 4" },
];

const MockLine = [
  { id: 1, label: "ライン 1" },
  { id: 2, label: "ライン 2" },
  { id: 3, label: "ライン 3" },
  { id: 4, label: "ライン 4" },
]

const renderSubHeaderConfig = (text: string) => {
  return (
    <div
      style={{
        width: "180px",
        padding: "0.1rem",
        textAlign: "center",
        backgroundColor: "#dc3545",
        borderRadius: 5,
        color: "#fff",
        fontSize: "1.1rem",
      }}
    >
      {text}
    </div>
  );
};

export const AreaSetting: React.FC<Props> = ({
  setDataForm,
  dataForm,
  areaDetection,
  setAreaHash,
}) => {

  const {
    selectLineCross,
    setLineCrossesData,
    removeLineCross,
    setlineLayerZindex,
    isCreatingRef: isLineCreatingRef,
    selectedLineIndex
  } = React.useContext(LineCrossContext)!;

  const {
    rmeoveCropArea,
    selectCropArea,
    isCreatingRef: isCropCreatingRef
  } = React.useContext(CropAreaContext)!;

  useEffect(() => {
    const LINE_CROSS_MAX_NUM = 4
    const formatted = Array.from({ length: LINE_CROSS_MAX_NUM }).map((_, i) => {
      return dataForm.line_crosses[i] !== undefined ? dataForm.line_crosses[i] : null
    })
    setLineCrossesData(formatted)
  }, [dataForm, setLineCrossesData])

  const { user } = React.useContext(AuthContext);
  const userData = (user === null) ? { tenant_id: "00000" } : user;
  const [capturing, setCapturing] = React.useState(false);
  const [captureImageSrc, setCaptureImageSrc] = React.useState<string>(
    dataForm.lastCaptureImage
  );
  const [isEnableEditArea, setIsEnableEditArea] = React.useState(false);
  const name = `CAP-${userData.tenant_id}-` + dataForm.cameraID;

  React.useEffect(() => {
    if (document.querySelector("#canvas")) {
      setIsEnableEditArea(true);
      areaDetection.current = new AreaController();
      areaDetection.current.loadAreas(dataForm.area);
      areaDetection.current.redraw();
      areaDetection.current.onChangeArea((area: any) =>
        setAreaHash(objectHash(area))
      );
    }

    if (userData) {
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');

      for (const row of ca) {
        if (row.indexOf(name) === 0) {
          setCapturing(true);
        }
      }

      try {
        const ws = new WebSocket(
          `${process.env.REACT_APP_WEBSOCKET_URL?.toString() || ""}/${userData.tenant_id}`
        );
        ws.onopen = function (event) {
          console.log("[websocket]: connected camera.")
        };
        ws.onmessage = function (event) {
          console.log("[websocket]: received message camera.");
          let data = event.data;
          data = data.replace(/'/g, '"');
          const dataObj = JSON.parse(data);

          if (dataObj.type === 'capture') {
            setCaptureImageSrc(dataObj.capture_url);
            dataForm.lastCaptureImage = dataObj.capture_url;

            const buttonSaveSetting = document.querySelector("#buttonSaveSetting-" + dataObj.id);
            if (buttonSaveSetting) buttonSaveSetting.removeAttribute('disabled');

            document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            setCapturing(false);
          }
        };

        return () => {
          ws.close()
        }
      } catch (err) {
        const logData = {
          level: 'ERROR',
          message: err instanceof Error ? err.message : '内部サーバーエラーが発生しました!',
        };
        API.Log.clientLog(logData);
      }
    }
  }, [userData, name, setCaptureImageSrc]);

  const {
    invoke: captureImage,
    data: lastCaptureImage,
    status: captureImageStatus,
    error: captureImageError,
  } = useAPIService(API.Cameras.captureImage);

  React.useEffect(() => {
    if (lastCaptureImage)
      setDataForm((prev) => {
        return {
          ...prev,
          lastCaptureImage,
        };
      });
  }, [lastCaptureImage]);

  React.useEffect(() => {
    if (captureImageStatus) {
      if (captureImageStatus === ResponseStatus.SUCCESS) {
        handleSuccessNotification("正常にキャプチャされました");
      } else {
        handleErrorNotification(captureImageError?.response?.data?.detail);
        setCapturing(false);
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    }
  }, [captureImageStatus, captureImageError, setCapturing, name]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          id="captureImage"
          variant="success"
          style={{ padding: "0.5rem 1rem" }}
          onClick={async () => {
            setCapturing(true);

            if (userData) {
              const d = new Date();
              d.setTime(d.getTime() + (2 * 60 * 1000));
              const expires = "expires=" + d.toUTCString();
              document.cookie = name + "=1;" + expires + ";path=/";

              setTimeout(function () {
                const decodedCookie = decodeURIComponent(document.cookie);
                const ca = decodedCookie.split(';');

                for (const row of ca) {
                  if (row.indexOf(name) === 0) {
                    handleErrorNotification('静止画取得タイムアウトしました');
                    setCapturing(false);
                    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                  }
                }
              }, 15000);
            }

            await captureImage(dataForm.cameraID);
          }}
          disabled={!dataForm.rtspUrl || capturing}
        >
          {capturing ? (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginRight: 4 }}
            />
          ) : (
            <i className="bi bi-camera" style={{ marginRight: 4 }} />
          )}
          静止画取得
        </Button>
      </div>
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div style={{ flex: 1 }}>
          {renderSubHeaderConfig("検知エリア設定")}
          <ListGroup style={{ minWidth: 300, marginRight: 10 }}>
            {MockArea.map((item, index) => {
              return (
                <ListGroup.Item key={item.id}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        color: AREA_COLORS[index],
                        fontWeight: 500,
                      }}
                    >
                      {item.label}
                    </div>
                    <div>
                      <Button
                        disabled={!isEnableEditArea}
                        id={`edit-area-${item.id}`}
                        onClick={() => {
                          if (selectedLineIndex !== null && isLineCreatingRef.current) {
                            isLineCreatingRef.current = false
                            removeLineCross(selectedLineIndex, setDataForm)
                          }
                          if (isCropCreatingRef.current) {
                            isCropCreatingRef.current = false
                            rmeoveCropArea()
                          }
                          selectLineCross(null)
                          areaDetection.current.selectArea(index);
                          setlineLayerZindex(LINELAYER_ZINDEX_DOWN)
                          selectCropArea(false)
                        }}
                        variant="primary"
                        style={{ marginRight: "1rem" }}
                      >
                        <i
                          className="bi bi-pencil-square"
                          style={{ marginRight: 4 }}
                        />
                        設定
                      </Button>
                      <Button
                        variant="danger"
                        disabled={!isEnableEditArea}
                        onClick={() => {
                          if (selectedLineIndex !== null && isLineCreatingRef.current) {
                            isLineCreatingRef.current = false
                            removeLineCross(selectedLineIndex, setDataForm)
                          }
                          if (isCropCreatingRef.current) {
                            isCropCreatingRef.current = false
                            rmeoveCropArea()
                          }
                          selectLineCross(null)
                          areaDetection.current.removeArea(index);
                          setlineLayerZindex(LINELAYER_ZINDEX_DOWN)
                          selectCropArea(false)
                        }}
                        id={`del-area-${item.id}`}
                      >
                        <i style={{ marginRight: 4 }} className="bi bi-trash" />
                        削除
                      </Button>
                    </div>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          <div className="mt-3">{renderSubHeaderConfig("ラインクロス設定")}</div>
          <ListGroup style={{ minWidth: 300, marginRight: 10 }}>
            {MockLine.map((item, index) => {
              return (
                <ListGroup.Item key={item.id}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        color: "rgb(242,71,38)",
                        fontWeight: 500,
                      }}
                    >
                      {item.label}
                    </div>
                    <div>
                      <Button
                        id={`edit-line-${item.id}`}
                        onClick={() => {
                          if (index !== selectedLineIndex && selectedLineIndex !== null && isLineCreatingRef.current) {
                            isLineCreatingRef.current = false
                            removeLineCross(selectedLineIndex, setDataForm)
                          }
                          if (isCropCreatingRef.current) {
                            isCropCreatingRef.current = false
                            rmeoveCropArea()
                          }
                          areaDetection.current.DATA?.deselectAll()
                          areaDetection.current.redraw()
                          selectLineCross(index)
                          setlineLayerZindex(LINELAYER_ZINDEX_UP)
                          selectCropArea(false)
                        }}
                        variant="primary"
                        style={{ marginRight: "1rem" }}
                      >
                        <i
                          className="bi bi-pencil-square"
                          style={{ marginRight: 4 }}
                        />
                        設定
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          if (index !== selectedLineIndex && selectedLineIndex !== null && isLineCreatingRef.current) {
                            isLineCreatingRef.current = false
                            removeLineCross(selectedLineIndex, setDataForm)
                          }
                          if (isCropCreatingRef.current) {
                            isCropCreatingRef.current = false
                            rmeoveCropArea()
                          }
                          areaDetection.current.DATA?.deselectAll()
                          areaDetection.current.redraw()
                          removeLineCross(index, setDataForm);
                          setlineLayerZindex(LINELAYER_ZINDEX_UP)
                          selectCropArea(false)
                        }}
                        id={`del-line-${item.id}`}
                      >
                        <i style={{ marginRight: 4 }} className="bi bi-trash" />
                        削除
                      </Button>
                    </div>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          <div className="mt-3">{renderSubHeaderConfig("クロップエリア設定")}</div>
          <ListGroup style={{ minWidth: 300, marginRight: 10 }}>
            <ListGroup.Item >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    color: "rgb(242,71,38)",
                    fontWeight: 500,
                  }}
                >
                  {"クロップ"}
                </div>
                <div>
                  <Button
                    id={`edit-crop`}
                    onClick={() => {
                      if (selectedLineIndex !== null && isLineCreatingRef.current) {
                        isLineCreatingRef.current = false
                        removeLineCross(selectedLineIndex, setDataForm)
                      }
                      areaDetection.current.DATA?.deselectAll()
                      areaDetection.current.redraw()
                      setlineLayerZindex(LINELAYER_ZINDEX_UP)
                      selectLineCross(null)
                      selectCropArea(true)
                    }}
                    variant="primary"
                    style={{ marginRight: "1rem" }}
                  >
                    <i
                      className="bi bi-pencil-square"
                      style={{ marginRight: 4 }}
                    />
                    設定
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      if (selectedLineIndex !== null && isLineCreatingRef.current) {
                        isLineCreatingRef.current = false
                        removeLineCross(selectedLineIndex, setDataForm)
                      }
                      isCropCreatingRef.current = false
                      areaDetection.current.DATA?.deselectAll()
                      areaDetection.current.redraw()
                      setlineLayerZindex(LINELAYER_ZINDEX_UP)
                      selectLineCross(null)
                      selectCropArea(false)
                      rmeoveCropArea()
                    }}
                    id={`del-crop`}
                  >
                    <i style={{ marginRight: 4 }} className="bi bi-trash" />
                    削除
                  </Button>
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
        <CaptureImageWrapper>
          <CaptureImageContent>
            <CaptureImage
              id={`capture-image-${dataForm.cameraID}`}
              src={captureImageSrc}
              loading="lazy"
              onError={(event) => {
                event.currentTarget.src =
                  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
              }}
            />
            <canvas
              id="canvas"
              width="1028px"
              height="578px"
              style={{ position: "absolute", top: 0, left: 0, zIndex: 1000 }}
            ></canvas>
            <LineCrossLayer setDataForm={setDataForm} dataForm={dataForm} />
          </CaptureImageContent>
        </CaptureImageWrapper>
      </div>
    </>
  );
};
