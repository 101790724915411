import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CsvDataType, CsvExportLogic, ImageExportLogic } from '../Crowddetection';
import { DeviceType, LineType } from './DeviceLineSelect';
import { CountChartData, RangeInfo } from "./types";

const FIRST_CHART_IN_COLOR = "#2d9bf0"; // rgb(45, 155, 240)
const FIRST_CHART_OUT_COLOR = "#8fd14f"; // rgb(143, 209, 79)
const SECOND_CHART_IN_COLOR = "#1d1d8d"; // rgb(29, 29, 141)
const SECOND_CHART_OUT_COLOR = "#1d8d1d"; // rgb(29, 141, 29)

export const csvDeviceCameraString = (devices: DeviceType[], lines: LineType[]): string => {
    const str = devices.flatMap(d => {
        return d.cameras.flatMap(camera => {
            const found = lines.filter(l => l.camera.deviceID === d.deviceID && l.camera.cameraID === camera.cameraID)
            found.sort((a, b) => a.no - b.no)
            if (found.length === 0) return null
            const str = camera.cameraName + "(ライン" + found.map(l => l.no).join(",") + ")"
            return str
        }).filter(Boolean)
    }).join(",")

    return str
}

type PeopleCountChartProps = {
    compareEnabled: boolean
    activeTabKey: string
    csvExportLogic: CsvExportLogic
    imageExportLogic: ImageExportLogic

    firstChartData: CountChartData
    firstRangeInfo: RangeInfo
    firstIsLoading: boolean
    firstDevices: DeviceType[]
    firstLines: LineType[]

    secondChartData: CountChartData
    secondRangeInfo: RangeInfo
    secondIsLoading: boolean
    secondDevices: DeviceType[]
    secondLines: LineType[]
}
export const PeopleCountChart: React.FC<PeopleCountChartProps> = ({
    compareEnabled,
    firstIsLoading, secondIsLoading, activeTabKey, firstRangeInfo, firstChartData, secondRangeInfo, secondChartData, csvExportLogic, imageExportLogic,
    firstDevices, firstLines,
    secondDevices, secondLines
}) => {

    const shapeChartData = (compareEnabled: boolean) => {
        if (compareEnabled) {
            return firstChartData.labels.map((x, i) => {
                return {
                    name: firstChartData.labels[i],
                    in: firstChartData.ins[i],
                    out: firstChartData.outs[i],
                    in_second: secondChartData.ins[i],
                    out_second: secondChartData.outs[i]
                }
            })
        } else {
            return firstChartData.labels.map((x, i) => {
                return {
                    name: firstChartData.labels[i],
                    in: firstChartData.ins[i],
                    out: firstChartData.outs[i],
                }
            })
        }

    }

    const [visibleInLineArray, setVisibleInLineArray] = useState([true, true])
    const [visibleOutLineArray, setVisibleOutLineArray] = useState([true, true])


    const [data, setData] = useState<{
        name: string;
        in?: number;
        out?: number;
        in_second?: number;
        out_second?: number;
    }[]>([])
    const legendClickHandler = (eventData: any) => {
        if (eventData.dataKey === "in") {
            setVisibleInLineArray(visibleInLineArray.map((item, index) => (index === 0 ? !item : item)))
        } else if (eventData.dataKey === "out") {
            setVisibleOutLineArray(visibleOutLineArray.map((item, index) => (index === 0 ? !item : item)))
        } else if (eventData.dataKey === "in_second") {
            setVisibleInLineArray(visibleInLineArray.map((item, index) => (index === 1 ? !item : item)))
        } else if (eventData.dataKey === "out_second") {
            setVisibleOutLineArray(visibleOutLineArray.map((item, index) => (index === 1 ? !item : item)))
        }
    }

    useEffect(() => {
        if (compareEnabled && firstChartData.xAxisName !== secondChartData.xAxisName) return
        const data = shapeChartData(compareEnabled)
        setData(data)
        setVisibleInLineArray([true, true])
        setVisibleOutLineArray([true, true])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstChartData, secondChartData])

    const createCsvData = (chartData: CountChartData, rangeInfo: RangeInfo, visibleInLine: boolean, visibleOutLine: boolean, graphName: string) => {
        const csvData: CsvDataType = []

        chartData.labels.forEach((_, i) => {
            const csvLine: CsvDataType[0] = []
            const xAxisNameIndex = 0
            csvLine[xAxisNameIndex] = parseInt(chartData.labels[i])
            if (visibleInLine) {
                csvLine.push(chartData.ins[i])
            } else if (visibleInLineArray[0] || visibleInLineArray[1]) {
                csvLine.push("")
            }

            if (visibleOutLine) {
                csvLine.push(chartData.outs[i])
            } else if (visibleOutLineArray[0] || visibleOutLineArray[1]) {
                csvLine.push("")
            }

            //日付の追加
            const rangeType = rangeInfo.rangeType!
            if (rangeType === "年") {
                const monthIndex = (csvLine[xAxisNameIndex] as number) - 1
                const date = dayjs(new Date(rangeInfo.year!, monthIndex)).format("YYYY-MM-DD HH:mm")
                csvLine.unshift(date)
            } else if (rangeType === "月") {
                const day = (csvLine[xAxisNameIndex] as number)
                const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1, day)).format("YYYY-MM-DD HH:mm")
                csvLine.unshift(date)
            } else if (rangeType === "日") {
                const hour = (csvLine[xAxisNameIndex] as number)
                const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1, rangeInfo.day!, hour)).format("YYYY-MM-DD HH:mm")
                csvLine.unshift(date)
            } else if (rangeType === "時") {
                const minute = (csvLine[xAxisNameIndex] as number)
                const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1, rangeInfo.day!, rangeInfo.hour!, minute)).format("YYYY-MM-DD HH:mm")
                csvLine.unshift(date)
            }
            csvLine.unshift(graphName)
            csvData.push(csvLine)
        })

        return csvData
    }


    // CSVデータの更新・画像ファイル名更新
    const updateExportData = () => {
        if (activeTabKey !== "counter") return
        if (firstRangeInfo.rangeType === null) return

        const csvFileName = "人数カウント"

        let csvData: CsvDataType = []

        //デバイス・カメラ選択情報(グラフ１)
        if (firstDevices.length !== 0 && firstLines.length !== 0) {
            const str = csvDeviceCameraString(firstDevices, firstLines)
            const firstHeader = ["グラフ１のデバイス・カメラ選択状態", str]
            csvData.push(firstHeader)
        } else if (firstDevices.length !== 0) {
            const firstHeader = ["グラフ１のデバイス・カメラ選択状態", firstDevices.map(d => d.deviceName).join(",")]
            csvData.push(firstHeader)
        } else {
            const firstHeader = ["グラフ１のデバイス・カメラ選択状態", ""]
            csvData.push(firstHeader)
        }


        if (compareEnabled) {
            //デバイス・カメラ選択情報(グラフ2)
            if (secondDevices.length !== 0 && secondLines.length !== 0) {
                const str = csvDeviceCameraString(secondDevices, secondLines)
                const firstHeader = ["グラフ２のデバイス・カメラ選択状態", str]
                csvData.push(firstHeader)
            } else if (secondDevices.length !== 0) {
                const firstHeader = ["グラフ２のデバイス・カメラ選択状態", secondDevices.map(d => d.deviceName).join(",")]
                csvData.push(firstHeader)
            } else {
                const firstHeader = ["グラフ２のデバイス・カメラ選択状態", ""]
                csvData.push(firstHeader)
            }
        }

        // header処理
        const headers: CsvDataType[0] = ["グラフ", "日付", firstChartData.xAxisName]
        if (visibleInLineArray[0] || visibleInLineArray[1]) {
            headers.push("in")
        }
        if (visibleOutLineArray[0] || visibleOutLineArray[1]) {
            headers.push("out")
        }
        csvData.push(headers)

        const firstCsvData = createCsvData(firstChartData, firstRangeInfo, visibleInLineArray[0], visibleOutLineArray[0], "グラフ1")
        csvData = csvData.concat(firstCsvData)

        if (compareEnabled) {
            const secondCsvData = createCsvData(secondChartData, secondRangeInfo, visibleInLineArray[1], visibleOutLineArray[1], "グラフ2")
            csvData = csvData.concat(secondCsvData)
        }

        csvExportLogic.setCsvState({
            fileName: csvFileName,
            data: csvData
        })
        imageExportLogic.imageStateRef.current.fileName = csvFileName
    }
    useEffect(() => {
        if (activeTabKey === "counter") {
            updateExportData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, visibleInLineArray, visibleOutLineArray, activeTabKey, compareEnabled])

    //比較グラフが非表示になったら

    // 初期表示中、APIデータ取得中にダウンロードしないようにする処理
    useEffect(() => {
        if (activeTabKey === "counter" && !firstIsLoading && !secondIsLoading) {
            updateExportData()
            csvExportLogic.setIsCsvAvailable(true)
            imageExportLogic.setIsImageAvailable(true)
        } else if (activeTabKey === "counter" && (firstIsLoading || secondIsLoading)) {
            csvExportLogic.setIsCsvAvailable(false)
            imageExportLogic.setIsImageAvailable(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstIsLoading, secondIsLoading, activeTabKey])

    const formatLegendText = (value: string, entry: any) => {
        if (value === "グラフ1: in") {
            return visibleInLineArray[0] ? value : <span style={{ textDecorationLine: "line-through", textDecorationColor: "black" }}>{value}</span>
        } else if (value === "グラフ1: out") {
            return (
                <>
                    {visibleOutLineArray[0] ? value : <span style={{ textDecorationLine: "line-through", textDecorationColor: "black" }}>{value}</span>}
                    <div style={{ marginBottom: "1.5rem" }}></div>
                </>
            );
        } else if (value === "グラフ2: in") {
            return visibleInLineArray[1] ? value : <span style={{ textDecorationLine: "line-through", textDecorationColor: "black" }}>{value}</span>
        } else if (value === "グラフ2: out") {
            return visibleOutLineArray[1] ? value : <span style={{ textDecorationLine: "line-through", textDecorationColor: "black" }}>{value}</span>
        }
    };

    return <div style={{ width: "100%" }}>
        <div id="PeopleCountChartPicture" style={{ backgroundColor: "white", width: "90%" }}>
            {activeTabKey === "counter" ? < ResponsiveContainer
                width="100%"
                aspect={3} >
                <LineChart
                    id='PeopleCountChart'
                    width={1000}
                    height={600}
                    data={data}
                    margin={{
                        top: 40,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" label={{ value: firstChartData.xAxisName, position: "right", offset: 15 }} />
                    <YAxis allowDecimals={false} label={{ value: "人", position: "top", offset: 15 }} />
                    <Tooltip />
                    <Legend align='right' verticalAlign='middle' layout='vertical' onClick={legendClickHandler} formatter={formatLegendText} />
                    <Line
                        hide={!visibleInLineArray[0]}
                        type="linear"
                        dataKey="in"
                        stroke={FIRST_CHART_IN_COLOR}
                        name="グラフ1: in"
                        strokeWidth={2}
                        dot={{ strokeWidth: 1 }}
                        isAnimationActive={false} //dotが表示されないバグのため：https://github.com/recharts/recharts/issues/1426
                    />
                    <Line
                        hide={!visibleOutLineArray[0]}
                        type="linear"
                        dataKey="out"
                        stroke={FIRST_CHART_OUT_COLOR}
                        name="グラフ1: out"
                        strokeWidth={2}
                        dot={{ strokeWidth: 1 }}
                        isAnimationActive={false}
                    />
                    {compareEnabled && (
                        <>
                            <Line
                                hide={!visibleInLineArray[1]}
                                type="linear"
                                dataKey="in_second"
                                stroke={SECOND_CHART_IN_COLOR}
                                name="グラフ2: in"
                                strokeWidth={2}
                                dot={{ strokeWidth: 1 }}
                                isAnimationActive={false}
                            />
                            <Line
                                hide={!visibleOutLineArray[1]}
                                type="linear"
                                dataKey="out_second"
                                stroke={SECOND_CHART_OUT_COLOR}
                                name="グラフ2: out"
                                strokeWidth={2}
                                dot={{ strokeWidth: 1 }}
                                isAnimationActive={false}
                            /></>
                    )}

                </LineChart>
            </ResponsiveContainer >
                : <></>}
        </div>
    </div>;
};