import { CSVLink } from "react-csv";
import React, { useRef, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import { PeopleCountView } from "./crowd-detection/PeopleCountView";
import { AttributeAnalysisView } from "./crowd-detection/AttributeAnalysisView";
import Logo from "../../components/Logo";
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";
import "./crowd-detection/legendExplainText.css"
import { StayingCountChartContainer } from "./crowd-detection/StayingCountChart";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;
const Heading = styled.div`
  padding-top: 1rem;
  margin: 0 2rem;
  display: flex;
  align-items: center;
`;

const PageHeadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const PageHeading = styled.h3`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  padding: 2rem;
  flex: 1;
  /*min-width: 1800px;*/
`;

const TabContentItem = styled.div`
  padding: 1em;
`;

// CSVエクスポート機能で使用する型
export type CsvDataType = (string | number)[][]
type CsvExportState = {
  fileName: string
  data: CsvDataType
}
export type CsvExportLogic = {
  isCsvAvailable: boolean
  setIsCsvAvailable: React.Dispatch<React.SetStateAction<boolean>>
  csvState: CsvExportState
  setCsvState: React.Dispatch<React.SetStateAction<CsvExportState>>
}

// 画像エクスポート機能で使用する型
type ImageExportState = {
  fileName: string
}
export type ImageExportLogic = {
  isImageDownloading: boolean
  setIsImageDownloading: React.Dispatch<React.SetStateAction<boolean>>
  isImageAvailable: boolean
  setIsImageAvailable: React.Dispatch<React.SetStateAction<boolean>>
  imageStateRef: React.MutableRefObject<ImageExportState>
}

export const Crowddetection: React.FC = () => {
  const defaultActiveTabKey = "counter"
  const [activeTabKey, setActiveTabKey] = useState<"counter" | "analysis" | "staying">(defaultActiveTabKey);

  // CSVエクスポート機能で使用する変数
  const initialCsvState: CsvExportState = {
    fileName: "",
    data: []
  }
  const [csvState, setCsvState] = useState<CsvExportState>(initialCsvState)
  const [isCsvAvailable, setIsCsvAvailable] = useState(false)
  const csvExportLogic: CsvExportLogic = {
    isCsvAvailable: isCsvAvailable,
    setIsCsvAvailable: setIsCsvAvailable,
    csvState: csvState,
    setCsvState: setCsvState
  }

  // 画像エクスポート機能で使用する変数
  const [isImageDownloading, setIsImageDownloading] = useState(false)
  const initialImageState: ImageExportState = { fileName: "" }
  const imageDataRef = useRef<ImageExportState>(initialImageState)
  const [isImageAvailable, setIsImageAvailable] = useState(false)
  const imageExportLogic: ImageExportLogic = {
    isImageAvailable: isImageAvailable,
    setIsImageAvailable: setIsImageAvailable,
    imageStateRef: imageDataRef,
    setIsImageDownloading: setIsImageDownloading,
    isImageDownloading: isImageDownloading
  }

  const downloadChartImage = async () => {
    if (!isImageAvailable) return false
    if (activeTabKey === "counter") {
      const imageElement = document.getElementById("PeopleCountChartPicture")
      // const imageElement = document.getElementsByClassName("ChartLegendHelpText")[0]
      if (imageElement) {
        setIsImageDownloading(true)//結局いらない
        // 凡例ヘルプテキストを除外するため。
        //SVGタグにスタイル情報も含まれているから機能しているが、ドロップメニューも含めるとレイアウトが崩れる
        const template = imageElement.cloneNode(true)
        saveAs(await toPng(template as any, {
          height: document.getElementById("PeopleCountChartPicture")?.clientHeight,
          width: document.getElementById("PeopleCountChartPicture")?.clientWidth,
        }), imageExportLogic.imageStateRef.current?.fileName);
        // saveAs(await toPng(imageElement), imageExportLogic.imageStateRef.current?.fileName);
        setIsImageDownloading(false)
      }
    } else if (activeTabKey === "analysis") {
      // const imageElement = document.getElementById("AttributeAnalysisChartPicture")
      const imageElement = document.getElementById("AttributeAnalysisChartPicture")
      if (imageElement) {
        setIsImageDownloading(true)
        const template = imageElement.cloneNode(true)
        saveAs(await toPng(template as any, {
          height: document.getElementById("AttributeAnalysisChartPicture")?.clientHeight,
          width: document.getElementById("AttributeAnalysisChartPicture")?.clientWidth,
        }), imageExportLogic.imageStateRef.current?.fileName);
        // saveAs(await toPng(imageElement), imageExportLogic.imageStateRef.current?.fileName);
        setIsImageDownloading(false)
      }
    } else if (activeTabKey === "staying") {
      const imageElement = document.getElementById("StayingCountChartPicture")
      if (imageElement) {
        setIsImageDownloading(true)
        const template = imageElement.cloneNode(true)
        saveAs(await toPng(template as any, {
          height: document.getElementById("StayingCountChartPicture")?.clientHeight,
          width: document.getElementById("StayingCountChartPicture")!.clientWidth,
        }), imageExportLogic.imageStateRef.current?.fileName);
        // saveAs(await toPng(imageElement), imageExportLogic.imageStateRef.current?.fileName);
        setIsImageDownloading(false)
      }
    }
  }

  return (
    <Wrapper className="ChartLegendHelpText">
      <Heading id={"devices-heading"}>
        <Logo />
      </Heading>
      <ContentWrapper>
        <div
          style={{
            padding: "1rem",
            backgroundColor: "#fff",
            borderRadius: 10,
          }}
        >
          <PageHeadingWrap>
            <PageHeading>人流解析</PageHeading>
            <div>
              <Button
                id="CrowdDetection-ImageExportButton"
                className="me-2"
                onClick={(e) => downloadChartImage()}>画像エクスポート</Button>
              <CSVLink
                id="CrowdDetection-CsvExportButton"
                className="btn btn-primary"
                target="_blank"
                filename={csvExportLogic.csvState.fileName}
                data={csvExportLogic.csvState.data}
                onClick={(event: any) => {
                  if (!isCsvAvailable)
                    return false;
                }}
              >
                CSVエクスポート
              </CSVLink>
            </div>

          </PageHeadingWrap>
          <Tabs
            id="Crowddetection-tabs"
            className="mb-3"
            style={{ width: "100%" }}
            activeKey={activeTabKey}
            onSelect={(eventkey) => {
              if (eventkey !== null) {
                setActiveTabKey(eventkey as typeof activeTabKey)
              }
            }}
          >
            <Tab eventKey="counter" title="人数カウント">
              <TabContentItem>
                <PeopleCountView
                  activeTabKey={activeTabKey} //MORE:activeTabKeyを引数に取らない
                  csvExportLogic={csvExportLogic}
                  imageExportLogic={imageExportLogic}
                />
              </TabContentItem>
            </Tab>
            <Tab eventKey="analysis" title="属性分析">
              <TabContentItem>
                <AttributeAnalysisView
                  activeTabKey={activeTabKey}
                  csvExportLogic={csvExportLogic}
                  imageExportLogic={imageExportLogic}
                />
              </TabContentItem>
            </Tab>
            <Tab eventKey="staying" title="混雑状況">
              <TabContentItem>
                <StayingCountChartContainer
                  activeTabKey={activeTabKey}
                  csvExportLogic={csvExportLogic}
                  imageExportLogic={imageExportLogic}
                />
              </TabContentItem>
            </Tab>
          </Tabs>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};
