import { IRect } from "konva/lib/types";
import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Stage,
  Layer,
  Group
} from "react-konva";
import { LineCross } from "../../../../store/types/models";
import DataBasicForm from "../type";
import { CropAreaContext } from "./CropAreaContext";
import { dataFormCropArea, KonvaCropArea } from "./KonvaCropArea";
import { KonvaLineCross } from "./KonvaLineCross";
import { LineCrossContext } from "./LineCrossContext";

export const CANVAS_WIDTH = 1028
export const CANVAS_HEIGHT = 578
export const LINELAYER_ZINDEX_DOWN = 990
export const LINELAYER_ZINDEX_UP = 1010


export const clamp = (a: number, min: number, max: number) => Math.min(Math.max(a, min), max);

/** 枠外にはみ出したGroupを引き戻す処理 */
export const pullBackGroupPosition = (lineRect: IRect, groupPosition: any) => {

  const offsetX = lineRect.x - groupPosition.x;
  const offsetY = lineRect.y - groupPosition.y;

  const newPosition = { ...groupPosition }
  if (lineRect.x < 0) {
    newPosition.x = -offsetX;
  }
  if (lineRect.y < 0) {
    newPosition.y = -offsetY;
  }
  if (lineRect.x + lineRect.width > CANVAS_WIDTH) {
    newPosition.x = CANVAS_WIDTH - lineRect.width - offsetX;
  }
  if (lineRect.y + lineRect.height > CANVAS_HEIGHT) {
    newPosition.y = CANVAS_HEIGHT - lineRect.height - offsetY;
  }

  return newPosition
}

type LineCrossLayerProps = {
  dataForm: DataBasicForm
  setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>
}

export const LineCrossLayer = ({ dataForm, setDataForm }: LineCrossLayerProps) => {
  const {
    isCreatingRef,
    selectedLineIndex,
    lineCrossesData,
    setLineCrossData,
    lineLayerZindex,
  } = useContext(LineCrossContext)!

  const {
    cropAreaData,
    isCreatingRef: isCropCreatingRef,
    isCropAreaSelected,
    setCropAreaData,
  } = useContext(CropAreaContext)!

  const [mousePosition, setMousePosition] = useState<{
    x: number
    y: number
  } | null>(null)

  const onStageClicked = (e: any) => {
    const stage = e.target.getStage()
    if (selectedLineIndex !== null && isCreatingRef.current) {
      if (!lineCrossesData[selectedLineIndex]) {
        isCreatingRef.current = false
        return
      }
      //新規作成終了の処理
      const startPoint = lineCrossesData[selectedLineIndex]!.p1
      const endPoint = mousePosition!
      setLineCrossData(selectedLineIndex, { p1: startPoint, p2: endPoint }, setDataForm)
      isCreatingRef.current = false
    } else if (selectedLineIndex !== null && lineCrossesData[selectedLineIndex] === null && !isCreatingRef.current) {
      // 新規作成開始の処理
      const pointerPosition = stage.getPointerPosition()
      const pointerRoundedPosition = { x: Math.round(pointerPosition.x), y: Math.round(pointerPosition.y) }
      setMousePosition(pointerRoundedPosition)
      setLineCrossData(selectedLineIndex, { p1: pointerRoundedPosition, p2: pointerRoundedPosition }, null)
      isCreatingRef.current = true
    }


    if (isCropAreaSelected && isCropCreatingRef.current) {
      //新規作成終了の処理
      const startPoint = cropAreaData!.p1
      const endPoint = mousePosition!
      const data = { p1: startPoint, p2: endPoint }
      setCropAreaData(data)
      setDataForm((prev) => ({
        ...prev,
        crop_area: dataFormCropArea(data)
      }))
      isCropCreatingRef.current = false
    } else if (isCropAreaSelected && cropAreaData === null && !isCropCreatingRef.current) {
      // 新規作成開始の処理
      const pointerPosition = stage.getPointerPosition()
      const pointerRoundedPosition = { x: Math.round(pointerPosition.x), y: Math.round(pointerPosition.y) }
      setMousePosition(pointerRoundedPosition)
      setCropAreaData({ p1: pointerRoundedPosition, p2: pointerRoundedPosition })
      isCropCreatingRef.current = true
    }
  }

  const onStageMouseMove = (e: any) => {
    const stage = e.target.getStage()
    if (selectedLineIndex !== null && isCreatingRef.current) {
      const pointerPos = stage.getPointerPosition()
      const pointerPosRound = { x: Math.round(pointerPos.x), y: Math.round(pointerPos.y) }
      setMousePosition(pointerPosRound)
    }

    if (isCropAreaSelected && isCropCreatingRef.current) {
      const pointerPos = stage.getPointerPosition()
      const pointerPosRound = { x: Math.round(pointerPos.x), y: Math.round(pointerPos.y) }
      setMousePosition(pointerPosRound)
    }
  }

  //選択中のラインを最後に描画することで最前面に表示
  const konvaLineCrosses = lineCrossesData
    .map((lineCross, lineIndex) => [lineCross, lineIndex] as const)
    .filter((xs): xs is [LineCross, number] => xs[0] !== null)

  if (selectedLineIndex !== null) {
    const item = konvaLineCrosses.find((x) => x[1] === selectedLineIndex)
    if (item !== undefined) {
      const index = konvaLineCrosses.indexOf(item);
      const [selected] = konvaLineCrosses.splice(index, 1)
      konvaLineCrosses.push(selected)
    }
  }

  const CropAreaLayer = useCallback(() => {
    return <Layer>

      {
        cropAreaData === null ? <></> :
          <KonvaCropArea
            setCropAreaData={setCropAreaData}
            setDataForm={setDataForm}
            initialData={cropAreaData}
            selected={isCropAreaSelected}
            mousePosition={isCropCreatingRef.current && isCropAreaSelected ? mousePosition : null}
          />
      }
    </Layer>
  }, [cropAreaData, isCropAreaSelected, isCropCreatingRef, mousePosition, setCropAreaData, setDataForm])

  const LinesLayer = useCallback(() => {
    return <Layer >
      {
        konvaLineCrosses.map(xs => {
          const lineCross = xs[0]!
          const lineIndex = xs[1]
          return <Group key={lineIndex}>
            <KonvaLineCross
              mousePosition={isCreatingRef.current && selectedLineIndex === lineIndex ? mousePosition : null}
              initialData={lineCross}
              lineIndex={lineIndex}
              selected={selectedLineIndex === lineIndex}
              setLineDataToFormData={(l) => setLineCrossData(lineIndex, l, setDataForm)}
            />
          </Group>
        })
      }

    </Layer>
  }, [isCreatingRef, konvaLineCrosses, mousePosition, selectedLineIndex, setDataForm, setLineCrossData])

  const LinesLayerMemo = useMemo(() => LinesLayer(), [LinesLayer])
  const CropAreaLayerMemo = useMemo(() => CropAreaLayer(), [CropAreaLayer])

  return (
    <>
      <div id="canvas_linecross"
        style={{ position: "absolute", top: 0, left: 0, zIndex: lineLayerZindex }}
      ></div>
      <Stage
        container="#canvas_linecross"
        width={CANVAS_WIDTH}
        height={CANVAS_HEIGHT}
        onClick={onStageClicked}
        onMouseMove={onStageMouseMove}>
        {
          isCropAreaSelected ?
            <>
              {LinesLayerMemo}
              {CropAreaLayerMemo}
            </>
            :
            <>
              {CropAreaLayerMemo}
              {LinesLayerMemo}
            </>
        }
      </Stage>
    </>
  );
}