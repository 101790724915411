import { TimePicker } from "antd";
import dayjs from "dayjs";
import { NotificationRule } from "../../../../store/types/models";
import DataBasicForm from "../type";

const TIME_FORMAT = "HH:mm";

type StartTimePickerProps = {
    setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
    dataForm: DataBasicForm;
    formNumber: number;
    rule: NotificationRule

    disableTime: {
        hour: number[];
        minute: number[];
    }
    setDisableTime: React.Dispatch<React.SetStateAction<{
        hour: number[];
        minute: number[];
    }>>

    getDisabledTime: () => {
        disabledHours: () => number[];
        disabledMinutes: () => number[];
    }
}


export const StartTimePicker = ({ getDisabledTime, disableTime, setDisableTime, dataForm, formNumber, rule, setDataForm }: StartTimePickerProps) => {
    const endTime = rule.endTime ? rule.endTime : "23:59"

    const hourEnd = Number(endTime.split(":")[0]);
    const minEnd = Number(endTime.split(":")[1]);

    return (
        <TimePicker
            id={`startTimePicker${formNumber}`}
            disabled={dataForm.isCloneConfig}
            status={rule.startTime === null || rule.startTime === "" || (rule.startTime !== null && rule.endTime !== null && rule.startTime > rule.endTime) ? "error" : undefined}
            size="large"
            onClick={() => {
                setDisableTime({
                    ...disableTime,
                    hour: Array.from(
                        { length: 24 - hourEnd - 1 },
                        (_, i) => i + hourEnd + 1
                    ),
                    minute: Array.from(
                        { length: 60 - minEnd },
                        (_, i) => i + minEnd
                    ),
                });
            }}
            disabledTime={getDisabledTime}
            defaultValue={rule.startTime ? dayjs(rule.startTime, TIME_FORMAT) : undefined}
            format={TIME_FORMAT}
            showNow={false}
            onSelect={(e) => {
                const val = e.format("HH:mm");
                const h = Number(val.split(":")[0]);
                const m = Number(val.split(":")[1]);

                if (h < hourEnd) {
                    if (m < minEnd) {
                        setDisableTime({
                            hour: Array.from(
                                { length: 24 - hourEnd - 1 },
                                (_, i) => i + hourEnd + 1
                            ),
                            minute: [],
                        });
                    } else {
                        setDisableTime({
                            hour: Array.from(
                                { length: 24 - hourEnd },
                                (_, i) => i + hourEnd
                            ),
                            minute: [],
                        });
                    }
                } else {
                    setDisableTime({
                        ...disableTime,
                        minute: Array.from(
                            { length: 60 - minEnd },
                            (_, i) => i + minEnd
                        ),
                    });
                }
            }}
            onChange={(_, time) => {
                setDataForm((prev) => {
                    const newSchedules = dataForm.notification_schedules.concat()
                    const ruleIndex = newSchedules[formNumber].rules.findIndex(x => rule.reactId === x.reactId)
                    newSchedules[formNumber].rules[ruleIndex] = {
                        ...newSchedules[formNumber].rules[ruleIndex],
                        startTime: time,
                        endTime: rule.endTime,
                    }
                    return {
                        ...prev,
                        notification_schedules: newSchedules,
                    };
                });
            }}
        />
    );
}

type EndTimePickerProps = {
    setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
    dataForm: DataBasicForm;
    formNumber: number;
    rule: NotificationRule

    disableTime: {
        hour: number[];
        minute: number[];
    }
    setDisableTime: React.Dispatch<React.SetStateAction<{
        hour: number[];
        minute: number[];
    }>>
    getDisabledTime: () => {
        disabledHours: () => number[];
        disabledMinutes: () => number[];
    }
}
export const EndTimePicker = ({ getDisabledTime, disableTime, setDisableTime, rule, dataForm, setDataForm, formNumber }: EndTimePickerProps) => {
    const startTime = rule.startTime ? rule.startTime : "00:00"

    const hourStart = Number(startTime.split(":")[0]);
    const minStart = Number(startTime.split(":")[1]);
    return (
        <TimePicker
            id={`endTimePicker${formNumber}`}
            disabled={dataForm.isCloneConfig}
            status={rule.endTime === null || rule.endTime === "" || (rule.startTime !== "" && rule.endTime !== "" && rule.startTime !== null && rule.endTime !== null && rule.startTime > rule.endTime) ? "error" : undefined}
            size="large"
            onClick={() => {
                setDisableTime({
                    hour: Array.from({ length: hourStart }, (_, i) => i),
                    minute: Array.from({ length: minStart + 1 }, (_, i) => i),
                });
            }}
            disabledTime={getDisabledTime}
            defaultValue={rule.endTime ? dayjs(rule.endTime, TIME_FORMAT) : undefined}
            format={TIME_FORMAT}
            showNow={false}
            onSelect={(e) => {
                const val = e.format("HH:mm");
                const h = Number(val.split(":")[0]);
                const m = Number(val.split(":")[1]);

                if (h > hourStart) {
                    if (m < minStart) {
                        setDisableTime({
                            hour: Array.from({ length: hourStart + 1 }, (_, i) => i),
                            minute: [],
                        });
                    } else {
                        setDisableTime({
                            hour: Array.from({ length: hourStart }, (_, i) => i),
                            minute: [],
                        });
                    }
                } else {
                    setDisableTime({
                        ...disableTime,
                        minute: Array.from({ length: minStart + 1 }, (_, i) => i),
                    });
                }
            }}
            onChange={(_, time) => {
                setDataForm((prev) => {
                    const newSchedules = dataForm.notification_schedules.concat()
                    const ruleIndex = newSchedules[formNumber].rules.findIndex(x => rule.reactId === x.reactId)
                    newSchedules[formNumber].rules[ruleIndex] = {
                        ...newSchedules[formNumber].rules[ruleIndex],
                        startTime: rule.startTime,
                        endTime: time,
                    }
                    return {
                        ...prev,
                        notification_schedules: newSchedules,
                    };
                });
            }}
        />
    );
}