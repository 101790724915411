
import React, { useMemo } from "react";
import DataBasicForm from "../type";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { FormControl as MaterialFormControl, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DayName, NotificationRule } from "../../../../store/types/models";
import { StartTimePicker, EndTimePicker } from "./TimePickers";

const ALL_DAY_NAMES: [string, DayName][] = [["日曜日", "Sun"], ["月曜日", "Mon"], ["火曜日", "Tue"], ["水曜日", "Wed"], ["木曜日", "Thu"], ["金曜日", "Fri"], ["土曜日", "Sat"]]

type ScheduleRuleProps = {
    formNumber: number;
    rule: NotificationRule
    dataForm: DataBasicForm;
    setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
};

export const ScheduleRule = ({ dataForm, setDataForm, formNumber, rule }: ScheduleRuleProps) => {

    const [disableTime, setDisableTime] = React.useState({
        hour: [] as number[],
        minute: [] as number[],
    });

    const getDisabledTime = () => ({
        disabledHours: () => disableTime.hour,
        disabledMinutes: () => disableTime.minute,
    });

    const onDayNameChange = (event: SelectChangeEvent) => {
        const newDayName = event.target.value as DayName
        setDataForm((prev) => {
            const newSchedules = prev.notification_schedules.concat()
            const ruleIndex = newSchedules[formNumber].rules.findIndex(x => rule.reactId === x.reactId)
            const prevRule = newSchedules[formNumber].rules[ruleIndex]
            newSchedules[formNumber].rules[ruleIndex] = {
                ...prevRule,
                dayName: newDayName
            }
            return {
                ...prev,
                notification_schedules: newSchedules
            }
        })
    };

    const ScheduleRuleMemo = useMemo(() => {
        return (
            <div style={{ paddingLeft: "1.5rem", paddingTop: "0.4rem" }}>
                <MaterialFormControl
                    disabled={dataForm.isCloneConfig}
                    sx={{ minWidth: 95 }} size="small">
                    <InputLabel>曜日</InputLabel>
                    <Select
                        data-cy={`ScheduleRule-DayName-${formNumber}`}
                        value={rule.dayName ? rule.dayName : ""}
                        label="曜日"
                        error={rule.dayName === null}
                        onChange={onDayNameChange}>
                        {ALL_DAY_NAMES.map(([displayName, dayName], i) => {
                            return <MenuItem data-testid={`ScheduleRule-DayName-MenuItem-${i}`} key={dayName} value={dayName}>{displayName}</MenuItem>
                        })}
                    </Select>
                </MaterialFormControl>
                <FormControlLabel
                    disabled={dataForm.isCloneConfig}
                    style={{
                        backgroundColor: "white",
                        width: "100px",
                        marginLeft: "0.9rem",
                        paddingRight: "2rem",
                        paddingBottom: "8px"
                    }}
                    control={
                        <Switch
                            data-cy={`ScheduleRule-Enable-${formNumber}`}
                            checked={rule.rule_enable}
                            onChange={(event) => {
                                setDataForm((prev) => {
                                    const newSchedules = prev.notification_schedules.concat()
                                    const ruleIndex = newSchedules[formNumber].rules.findIndex(x => rule.reactId === x.reactId)
                                    newSchedules[formNumber].rules[ruleIndex] = {
                                        ...newSchedules[formNumber].rules[ruleIndex],
                                        rule_enable: event.target.checked
                                    }
                                    return {
                                        ...prev,
                                        notification_schedules: newSchedules
                                    }
                                })
                            }}
                        />
                    }
                    label={
                        <span style={{ fontWeight: 'bold' }}>{rule.rule_enable ? "ON" : "OFF"}</span>
                    }
                />
                <div style={{ display: "inline-block" }}>
                    <StartTimePicker
                        dataForm={dataForm}
                        disableTime={disableTime}
                        formNumber={formNumber}
                        rule={rule}
                        setDataForm={setDataForm}
                        setDisableTime={setDisableTime}
                        getDisabledTime={getDisabledTime}
                    />
                    <div style={{ display: "inline-block", width: "50px", textAlign: "center" }}>TO</div>
                    <EndTimePicker
                        dataForm={dataForm}
                        disableTime={disableTime}
                        formNumber={formNumber}
                        rule={rule}
                        setDataForm={setDataForm}
                        setDisableTime={setDisableTime}
                        getDisabledTime={getDisabledTime}
                    />
                </div>
                <IconButton
                    data-cy={`ScheduleRule-Delete-${formNumber}`}
                    disabled={dataForm.isCloneConfig}
                    style={{
                        marginLeft: "10px",
                        paddingBottom: "12px"
                    }}
                    aria-label="delete"
                    onClick={() => {
                        setDataForm((prev) => {
                            const newSchedules = prev.notification_schedules.concat()
                            newSchedules[formNumber].rules = newSchedules[formNumber].rules.filter((x) => x.reactId !== rule.reactId)
                            return {
                                ...prev,
                                notification_schedules: newSchedules
                            }
                        })
                    }}>
                    <DeleteIcon />
                </IconButton>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rule, disableTime])

    return ScheduleRuleMemo
}
