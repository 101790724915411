import { AxiosInstance, AxiosPromise } from "axios";
import { Log } from "../../store/types/models";
export type LogType = {
  clientLog(params: Log): AxiosPromise;
};
export const LogMethod = (request: AxiosInstance): LogType => ({
  clientLog(params) {
    return request.post(`/client-log/`, params);
  },
});
