import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import { handleErrorNotification } from "../../../utils";
const G_CAM_PTZ = 0;
const G_CAM_FIXED = 1;

const G_AREA_A_COLOR = "#ED0000";
const G_AREA_B_COLOR = "#00B0F0";
const G_AREA_C_COLOR = "#66FF33";
const G_AREA_D_COLOR = "#BBBB00";

const AREA_COLORS = [
  G_AREA_A_COLOR,
  G_AREA_B_COLOR,
  G_AREA_C_COLOR,
  G_AREA_D_COLOR,
];
const G_AREA_EXCLUDE_COLOR = "#F7BD61";

const G_AREA_A = 1;
const G_AREA_B = 2;
const G_AREA_C = 3;
const G_AREA_D = 4;

const G_DETECT_AREA = 1;
const G_EXCLUDE_AREA = 2;

export class AreaController {
  g_canvas = document.querySelector("#canvas"); // Canvasオブジェクト
  g_ctx = this.g_canvas.getContext("2d"); // Canvasコンテキスト

  g_isShowCanvas = false;
  g_isShowAreas = false;
  g_isDrawAllowed = false;

  g_isDrawing = false;
  g_isFinishedPoint = true;
  g_drawing_point_no = 0;

  g_isSelectedPoint = false;
  g_isSelectedArea = false;
  g_isMousePressed = false;
  g_isAreaHover = false;

  g_hoverAreaId = "";
  g_hoverPointId = "";

  g_selectedAreaId = "";
  g_selectedPointId = "";

  g_dragStartX;
  g_dragStartY;
  g_currentX;
  g_currentY;

  g_drawingPolygon = {
    id: "",
    title: "検知エリア",
    camera_id: 0,
    type: 0,
    coordination_no: 0,
    monitor_flag: 0,
    crop_flg: 0,
    points: [],
    isSelected: false,
    color: "#FF0000",
    fill_color: "#FFFFFF",
  };

  g_drawingPoint = {
    id: "",
    isSelected: false,
    x: 0,
    y: 0,
  };

  /** 検知エリア対象 */
  Area = {
    id: 0,
    title: "検知エリア",
    camera_id: 0,
    type: 0,
    coordination_no: 0,
    monitor_flag: 0,
    crop_flg: 0,
    points: [], // 頂点一覧
    isSelected: false,
    color: "#FF0000",
    fill_color: "#FFFFFF",
  };

  Point = {
    id: "",
    x: 0,
    y: 0,
    isSelected: false,
  };

  constructor() {
    this.g_canvas = document.querySelector("#canvas"); // Canvasオブジェクト
    this.g_ctx = this.g_canvas.getContext("2d"); // Canvasコンテキスト
    this.redraw = this.redraw.bind(this);
    /* ---------------------------------------------------------------------------------------------------------------
     | Canvasの描くに関する処理
     | ----------------------------------------------------------------------------------------------------------------
     */
    $(this.g_canvas).mousedown((e) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.button === 0) {
        // Left click
        console.log("Allow to draw.");
        this.g_isMousePressed = true;

        if (
          !this.g_isDrawing &&
          !this.g_isSelectedPoint &&
          !this.COMMON.isPointHover(e)
        ) {
          let _isHoverArea = false;
          let _hoverAreaId = "";

          this.DATA.aHoverAreas.forEach((hoverArea, index, arr) => {
            if (hoverArea.status) {
              _isHoverArea = true;
              _hoverAreaId = hoverArea.id;
            }
          });
          if (_isHoverArea) {
            let hoverArea = this.COMMON.getArea(_hoverAreaId, null);
            if (
              this.DATA.cameraType === G_CAM_FIXED &&
              hoverArea.coordination_no !== this.DATA.areaCoordinationNo
            ) {
              this.DATA.deselectAll();
              return false;
            }

            console.log("Select Area.");
            this.g_isSelectedArea = true;

            this.g_selectedAreaId = _hoverAreaId;

            this.DATA.deselectAll();
            hoverArea.isSelected = true;

            this.g_dragStartX = e.offsetX;
            this.g_dragStartY = e.offsetY;

            return false;
          }
        }
        if (this.COMMON.isPointHover(e)) {
          let area = this.COMMON.getArea(
            this.g_hoverAreaId,
            this.g_hoverPointId
          );
          if (
            this.DATA.cameraType === G_CAM_FIXED &&
            area.coordination_no !== this.DATA.areaCoordinationNo
          ) {
            console.log("DDDDDDDDDDD");
            this.DATA.deselectAll();
            this.playNG();
            this.DATA.redraw();
            return false;
          }

          console.log("Select Point.");
          if (
            !this.DATA.idAreaSelected ||
            this.DATA.idAreaSelected !== area.id
          ) {
            return;
          }
          this.g_isSelectedPoint = true;
          this.DATA.isEditing = true;

          this.g_selectedAreaId = this.g_hoverAreaId;
          this.g_selectedPointId = this.g_hoverPointId;
          if (!$.isEmptyObject(area)) {
            area.isSelected = true;

            area.points.every((point) => {
              if (point.id === this.g_selectedPointId) {
                point.isSelected = true;
              } else {
                point.isSelected = false;
              }

              return true;
            });

            this.DATA.redraw();
          }
        }
        if (!this.DATA.isEditing) {
          return;
        }
        if (this.DATA.cameraType === G_CAM_PTZ) {
          if (
            this.DATA.areaMonitorFlag === G_DETECT_AREA &&
            this.DATA.aDetectAreas.length >= 1 &&
            !this.g_isSelectedPoint
          ) {
            console.log("A");
            this.DATA.deselectAll();
            this.playNG();
            return false;
          }
        } else if (this.DATA.cameraType === G_CAM_FIXED) {
          let isDrew = false;
          if (isDrew && !this.g_isSelectedPoint) {
            console.log("B");
            this.DATA.deselectAll();
            this.playNG();
            return false;
          }

          if (this.g_isSelectedPoint) {
            console.log("AAAAAA");
            let targetArea = this.COMMON.getArea(this.g_selectedAreaId, null);
            let g_drawValid = this.checkArea(targetArea.points);

            if (targetArea.coordination_no !== this.DATA.areaCoordinationNo || !g_drawValid) {
              if (!g_drawValid) {
                handleErrorNotification('エリアの辺が交差しています');
              }
              console.log(g_drawValid);
              this.DATA.deselectAll();
              this.playNG();
              return false;
            }
          }

          if (
            this.DATA.areaMonitorFlag === G_DETECT_AREA &&
            this.DATA.aDetectAreas.length >= 4 &&
            !this.g_isSelectedPoint &&
            !this.DATA.isEditing
          ) {
            console.log("C");
            this.DATA.deselectAll();
            this.playNG();
            return false;
          }
        }

        if (
          this.DATA.areaMonitorFlag === G_EXCLUDE_AREA &&
          this.DATA.aExcludeAreas.length >= 8
        ) {
          console.log("E");
          this.DATA.deselectAll();
          return false;
        }

        //max point is 10

        if (this.g_drawingPolygon.points.length > 9) {
          return;
        }
        if (
          !this.g_isSelectedPoint &&
          (!this.g_isSelectedArea ||
            !this.currentArea ||
            this.currentArea?.points?.length < 4)
        ) {
          console.log("Just click.");
          this.g_isFinishedPoint = false;

          this.g_selectedAreaId = "";
          this.g_selectedPointId = "";

          let clickPoint = {
            id: "p" + Date.now() + this.COMMON.generateUID(),
            x: e.offsetX,
            y: e.offsetY,
          };

          this.g_drawingPoint.x = e.offsetX;
          this.g_drawingPoint.y = e.offsetY;

          if (!this.g_isDrawing) {
            this.g_isDrawing = true;

            let coordinationNo = 0;
            if (this.DATA.cameraType === G_CAM_PTZ) {
              coordinationNo = this.DATA.areaCoordinationNo;
            } else if (this.DATA.cameraType === G_CAM_FIXED) {
              coordinationNo =
                this.DATA.areaMonitorFlag === G_DETECT_AREA
                  ? this.DATA.areaCoordinationNo
                  : 0;
            }

            let _polygon = {
              id: "a" + Date.now() + this.COMMON.generateUID(),
              title: "検知エリア",
              camera_id: this.DATA.activeCameraId,
              type: this.DATA.areaType,
              coordination_no: coordinationNo,
              monitor_flag: this.DATA.areaMonitorFlag,
              crop_flg: 0,
              points: [],
              isSelected: false,
              color: "#FF0000",
              fill_color: "#FFFFFF",
            };
            _polygon.points.push(clickPoint);
            this.g_drawing_point_no++;

            _polygon.color = this.COMMON.getColor();

            this.g_drawingPolygon = _polygon;
          } else {
            this.g_drawingPolygon.points.push(clickPoint);
            this.g_drawing_point_no++;
          }

          this.DATA.redraw();
          this.COMMON.draw(this.g_drawingPolygon);
          this.onChangeAreaCallback && this.onChangeAreaCallback();
        }
        this.g_dragStartX = e.offsetX;
        this.g_dragStartY = e.offsetY;
      } else if (e.button === 2) {
        // right click
        this.closeArea();
      }
    });

    $(this.g_canvas).mouseup((e) => {
      this.g_isMousePressed = false;
      this.g_isSelectedPoint = false;
      this.onChangeAreaCallback && this.onChangeAreaCallback();
    });

    $(this.g_canvas).dblclick((e) => {
      this.closeArea();
    });

    $(this.g_canvas).mousemove((e) => {
      this.g_currentX = e.offsetX;
      this.g_currentY = e.offsetY;
      if (this.g_isDrawing) {
        this.DATA.redraw();
        this.COMMON.draw(this.g_drawingPolygon);

        this.g_ctx.strokeStyle = this.COMMON.getColor();
        this.g_ctx.lineWidth = 4;
        this.g_ctx.beginPath();

        this.g_ctx.moveTo(this.g_drawingPoint.x, this.g_drawingPoint.y);
        this.g_ctx.lineTo(e.offsetX, e.offsetY);
        this.g_ctx.stroke();
      } else {
        if (this.g_isMousePressed && this.g_isSelectedPoint) {
          let dragPoint = this.COMMON.getPoint(this.g_selectedPointId);
          if (!$.isEmptyObject(dragPoint)) {
            dragPoint.x = e.offsetX;
            dragPoint.y = e.offsetY;

            this.DATA.isSaved = false;
            this.DATA.redraw();
          }
        } else if (
          this.g_isMousePressed &&
          this.g_isSelectedArea &&
          this.g_dragStartX &&
          this.g_dragStartY
        ) {
          let dx = e.offsetX - this.g_dragStartX;
          let dy = e.offsetY - this.g_dragStartY;

          let w = Math.round($(this.g_canvas).width());
          let h = Math.round($(this.g_canvas).height());

          let minX = 99999;
          let minY = 99999;
          let maxX = 0;
          let maxY = 0;
          let currentArea = this.COMMON.getArea(this.g_selectedAreaId, null);
          currentArea.points.forEach((point) => {
            if (minX > point.x) {
              minX = point.x;
            }
            if (minY > point.y) {
              minY = point.y;
            }
            if (maxX < point.x) {
              maxX = point.x;
            }
            if (maxY < point.y) {
              maxY = point.y;
            }
          });
          if (!$.isEmptyObject(currentArea)) {
            if (
              !(
                minX + dx < 0 ||
                minY + dy < 0 ||
                maxX + dx > w ||
                maxY + dy > h
              )
            ) {
              currentArea.points.every((point) => {
                point.x += dx;
                point.y += dy;

                return true;
              });
            }
            this.DATA.isSaved = false;
            this.DATA.redraw();

            this.g_dragStartX = e.offsetX;
            this.g_dragStartY = e.offsetY;
          }
        } else {
          if (this.g_isShowCanvas && this.g_isDrawAllowed) {
            console.log("Just moving...");
            this.DATA.redraw();
          }
        }
      }
    });

    $(this.g_canvas).contextmenu((e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  }

  playNG() {
    const audio = new Audio("/sound/Quiz_Wrong_Buzzer02_1.mp3");
    audio.play();
  }
  playOK() {
    const audio = new Audio("/sound/Onmtp_Pop03_1.mp3");
    audio.play();
  }

  redraw() {
    this.DATA.redraw();
  }

  selectArea = (index) => {
    this.DATA.isEditing = true;
    this.DATA.areaMonitorFlag = 1;
    this.DATA.indexArea = index + 1;
    let selectedId = "";
    if (this.DATA.aDetectAreas[index]) {
      const area = this.DATA.aDetectAreas[index];
      selectedId = area.id;
      this.g_hoverAreaId = selectedId;
      this.g_isSelectedArea = true;
      this.g_selectedPointId = "";
      this.DATA.cameraType = G_CAM_FIXED;
      area.isSelected = true;
      this.g_selectedAreaId = selectedId;
      this.currentArea = area;
      this.DATA.areaCoordinationNo = area.coordination_no;
    }

    this.DATA.idAreaSelected = selectedId;
    this.DATA.redraw();
  };

  onChangeArea(callback) {
    if (callback)
      this.onChangeAreaCallback = () => callback(this.DATA.aDetectAreas);
  }

  getArea(index) {
    return {
      id: "a" + Date.now() + this.COMMON.generateUID(),
      title: "検知エリア",
      camera_id: this.DATA.activeCameraId,
      type: this.DATA.areaType,
      coordination_no: index,
      monitor_flag: this.DATA.areaMonitorFlag,
      crop_flg: 0,
      points: [],
      isSelected: false,
      color: AREA_COLORS[index],
      fill_color: "#FFFFFF",
    };
  }

  loadAreas = (areas) => {
    this.DATA.aDetectAreas = areas.map((item, index) => ({
      ...this.getArea(index),
      points: item.points,
    }));

    for (let index = areas.length; index < 4; index++) {
      this.DATA.aDetectAreas.push(this.getArea(index));
    }
  };

  getDataStore = () => {
    return this.DATA.aDetectAreas.map(({ points }) => ({ points }));
  };

  removeArea = (index) => {
    this.COMMON.removeArea(index);
  };

  checkArea = (points) => {
    let checkResult = true;

    if (points.length >= 4) {
      let polygonLines = points;

      for (let i = 0; i < points.length - 1; i++) {
        let _currentLine = polygonLines[i];

        for (let j = 0; j < points.length - 1; j++) {
          let _checkLine = polygonLines[j];

          if (_currentLine.id === _checkLine.id) {
            continue;
          }

          if (
            this.lineIntersects(
              polygonLines[i].x,
              polygonLines[i].y,
              polygonLines[i + 1].x,
              polygonLines[i + 1].y,
              polygonLines[j].x,
              polygonLines[j].y,
              polygonLines[j + 1].x,
              polygonLines[j + 1].y
            )
          ) {
            checkResult = false;
          }
        }
      }

      for (let j = 0; j < points.length - 1; j++) {
        if (
          this.lineIntersects(
            polygonLines[points.length - 1].x,
            polygonLines[points.length - 1].y,
            polygonLines[0].x,
            polygonLines[0].y,
            polygonLines[j].x,
            polygonLines[j].y,
            polygonLines[j + 1].x,
            polygonLines[j + 1].y
          )
        ) {
          checkResult = false;
        }
      }
    }

    return checkResult;
  }

  closeArea = () => {
    this.DATA.redraw();
    this.DATA.isEditing = false;
    let g_drawValid = this.checkArea(this.g_drawingPolygon.points);
    console.log(g_drawValid);

    if (this.g_drawing_point_no >= 4 && g_drawValid) {
      this.COMMON.draw(this.g_drawingPolygon);

      let area = this.COMMON.clone(this.g_drawingPolygon);

      if (this.DATA.areaMonitorFlag === G_DETECT_AREA) {
        if (
          this.DATA.aDetectAreas[this.DATA.indexArea - 1]?.points
            ?.length === 0
        ) {
          this.DATA.aDetectAreas[this.DATA.indexArea - 1] = area;
          this.DATA.idAreaSelected = area.id;
        } else if (this.DATA.aDetectAreas.length < 4) {
          this.DATA.aDetectAreas.push(area);
          this.DATA.idAreaSelected = area.id;
        }

        $("#chk-crop-status-" + area.coordination_no).prop(
          "disabled",
          false
        );
      } else if (this.DATA.areaMonitorFlag === G_EXCLUDE_AREA) {
        this.DATA.aExcludeAreas.push(area);
      }

      this.playOK();
      this.DATA.isSaved = false;
      this.onChangeAreaCallback && this.onChangeAreaCallback();
    } else {
      this.DATA.aHoverAreas = this.DATA.aHoverAreas.filter((area) => {
        return area.id !== this.g_drawingPolygon.id;
      });
      console.log("D");
      this.playNG();

      if (!g_drawValid) {
        handleErrorNotification('エリアの辺が交差しています');
      }
    }
    this.onChangeAreaCallback && this.onChangeAreaCallback();
    this.DATA.cancel();
  }

  lineIntersects(a, b, c, d, p, q, r, s) {
    let det, gamma, lambda;
    det = (c - a) * (s - q) - (r - p) * (d - b);

    if (det === 0) {
      return false;
    } else {
      lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
      gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;

      return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
    }
  }

  DATA = {
    // モード種別（1: 読み取り専用、2: 変更）
    mode: 1,

    // 監視中カメラID
    activeCameraId: 0,

    isEditing: false,

    idAreaSelected: "",

    indexArea: 0,

    // カメラ種別
    cameraType: G_CAM_FIXED,

    // エリアタイプ (０：なし、１：Fixed1連携、２：Fixed2連携、３：360連携、４：監視ﾎﾟｼﾞｼｮﾝ）
    areaType: 0,

    // 連携ポジション番号（０：なし、１：A、２：B、３：C、４：D）
    areaCoordinationNo: 0,

    // エリア監視フラグ (0: 除外エリア、1: 検知エリア)
    areaMonitorFlag: G_DETECT_AREA,

    // 検知エリア一覧
    aDetectAreas: [],

    // 除外エリア一覧
    aExcludeAreas: [],

    // マウス(カーソル)がエリアの上に乗っている状態のエリア一覧
    aHoverAreas: [],

    //
    isAreaCollide: false,

    // 編集した検知エリアが保存されたかどうか？
    isSaved: true,

    /*
     * 監視エリア一のCanvasを再描画する。
     */
    redraw: () => {
      this.g_ctx.clearRect(0, 0, this.g_canvas.width, this.g_canvas.height);

      this.DATA.aDetectAreas.forEach((area, index, arr) => {
        this.COMMON.draw(area);
      });

      this.DATA.aExcludeAreas.forEach((area, index, arr) => {
        this.COMMON.draw(area);
      });
    },

    /*
     * 監視エリア一のCanvasをクリアーする。
     */
    clear: () => {
      this.g_isShowCanvas = false;
      this.g_isDrawAllowed = false;

      this.DATA.aDetectAreas = [];
      this.DATA.aExcludeAreas = [];
      this.DATA.aHoverAreas = [];

      this.g_ctx.clearRect(0, 0, this.g_canvas.width, this.g_canvas.height);
    },

    /**
     *
     */
    cancel: () => {
      this.g_isDrawing = false;
      this.g_drawingPolygon = {
        id: "",
        title: "検知エリア",
        camera_id: 0,
        type: 0,
        coordination_no: 0,
        monitor_flag: 0,
        crop_flg: 0,
        points: [],
        isSelected: false,
        color: "#FF0000",
        fill_color: "#FFFFFF",
      };
      this.g_drawing_point_no = 0;
      this.g_isFinishedPoint = true;

      this.DATA.redraw();
    },

    /**
     *
     */
    deselectAll: () => {
      this.DATA.aDetectAreas.forEach((area, index, arr) => {
        area.isSelected = false;
      });

      this.DATA.aExcludeAreas.forEach((area, index, arr) => {
        area.isSelected = false;
      });
    },

    hasSelectedArea: () => {
      let rs = false;
      this.DATA.aDetectAreas.forEach((area, index, arr) => {
        if (area.isSelected) {
          rs = true;
        }
      });
      return rs;
    },
  };

  /**********************************************************************************************************************
   * FUNCTIONS DEFINITION - 関数定義
   *********************************************************************************************************************/
  COMMON = {
    draw: (polygon) => {
      this.g_ctx.fillStyle = "#ff0000";

      // Draw the line & build shape.
      this.g_ctx.lineWidth = 4;
      this.g_ctx.strokeStyle = polygon.color;
      const checkArea = (area, index, arr) => {
        if (area.id !== this.g_selectedAreaId) {
          area.isSelected = false;
        }

        area.points.forEach((point) => {
          if (point.id !== this.g_selectedPointId) {
            point.isSelected = false;
          }
        });
      };
      this.DATA.aDetectAreas.forEach(checkArea);

      this.DATA.aExcludeAreas.forEach(checkArea);
      if (polygon.isSelected) {
        this.g_ctx.strokeStyle = "#A5E3ED";
      }

      this.g_ctx.beginPath();
      polygon.points.forEach((point, index, arr) => {
        if (arr.length > 1) {
          if (index === 0) {
            this.g_ctx.moveTo(point.x, point.y);
          }

          if (index !== arr.length - 1) {
            this.g_ctx.lineTo(arr[index + 1].x, arr[index + 1].y);
          }
        }
      });

      // Fill shapes color.
      this.g_ctx.fillStyle = "rgba(255, 255, 255, 0.0)";

      if (this.g_ctx.isPointInPath(this.g_currentX, this.g_currentY)) {
        this.g_isAreaHover = true;
      } else {
        this.g_isAreaHover = false;
      }

      let hoverArea = this.COMMON.getHoverArea(polygon.id);
      if (hoverArea) {
        hoverArea.status = this.g_isAreaHover;
      } else {
        hoverArea = {
          id: polygon.id,
          status: this.g_isAreaHover,
        };
        this.DATA.aHoverAreas.push(hoverArea);
      }

      this.g_ctx.closePath();
      this.g_ctx.fill();
      this.g_ctx.stroke();

      // Draw the dots.
      polygon.points.forEach((point, index, arr) => {
        this.g_ctx.fillStyle = "#FF0000";
        if (point.isSelected) {
          this.g_ctx.fillStyle = "#F39C12";
        }

        this.g_ctx.beginPath();
        this.g_ctx.arc(point.x, point.y, 6, 0, 6 * Math.PI);
        this.g_ctx.fill();
      });
    },

    isPointHover: (e) => {
      let result = false;

      let areas = [];
      if (this.DATA.areaMonitorFlag === G_DETECT_AREA) {
        areas = this.DATA.aDetectAreas;
      } else if (this.DATA.areaMonitorFlag === G_EXCLUDE_AREA) {
        areas = this.DATA.aExcludeAreas;
      }

      areas.every((area) => {
        let isSkip = false;
        area.points.every((point) => {
          let x = e.offsetX; // e.pageX - canvasOffset.left
          let y = e.offsetY; // e.pageY - canvasOffset.top
          let dx = point.x - x;
          let dy = point.y - y;

          if (dx * dx + dy * dy < 6 * 6) {
            if (!result) {
              result = true;

              this.g_hoverAreaId = area.id;
              this.g_hoverPointId = point.id;

              isSkip = true;
              return false;
            }
          }

          return true;
        });

        if (isSkip) {
          return false;
        }
        return true;
      });

      return result;
    },

    getPoint: (pointId) => {
      let obj = {};

      let areas = [];
      if (this.DATA.areaMonitorFlag === G_DETECT_AREA) {
        areas = this.DATA.aDetectAreas;
      } else if (this.DATA.areaMonitorFlag === G_EXCLUDE_AREA) {
        areas = this.DATA.aExcludeAreas;
      }

      areas.every((area) => {
        area.points.every((point) => {
          if (point.id === pointId) {
            obj = point;
            return false;
          }
          return true;
        });
        return true;
      });

      return obj;
    },

    getArea: (areaId, pointId) => {
      let obj = {};

      let areas = [];
      if (this.DATA.areaMonitorFlag === G_DETECT_AREA) {
        areas = this.DATA.aDetectAreas;
      } else if (this.DATA.areaMonitorFlag === G_EXCLUDE_AREA) {
        areas = this.DATA.aExcludeAreas;
      }
      areas.every((area) => {
        if (areaId) {
          if (area.id === areaId) {
            obj = area;
            return false;
          }
        } else {
          area.points.every((point) => {
            if (point.id === pointId) {
              obj = area;
              return false;
            }
            return true;
          });
        }
        return true;
      });

      return obj;
    },

    getHoverArea: (areaId) => {
      let obj;

      this.DATA.aHoverAreas.every((area) => {
        if (areaId) {
          if (area.id === areaId) {
            obj = area;
            return false;
          }
        }
        return true;
      });

      return obj;
    },

    removeArea: (index) => {
      this.DATA.aDetectAreas = this.DATA.aDetectAreas.map((item, idx) => ({
        ...item,
        points: index === idx ? [] : item.points,
      }));

      this.DATA.redraw();
      this.onChangeAreaCallback && this.onChangeAreaCallback();
    },

    /**
     * Line色を取得する。
     */
    getColor: () => {
      let _color = "#FF0000";

      if (this.DATA.areaMonitorFlag === G_DETECT_AREA) {
        switch (this.DATA.indexArea) {
          case G_AREA_A:
            _color = G_AREA_A_COLOR;
            break;
          case G_AREA_B:
            _color = G_AREA_B_COLOR;
            break;
          case G_AREA_C:
            _color = G_AREA_C_COLOR;
            break;
          case G_AREA_D:
            _color = G_AREA_D_COLOR;
            break;
          default:
            break;
        }
      } else if (this.DATA.areaMonitorFlag === G_EXCLUDE_AREA) {
        _color = G_AREA_EXCLUDE_COLOR;
      }
      return _color;
    },

    /*
     * オブジェクトのコピーを作成。
     */
    clone: (obj) => {
      let copy;

      // Handle the 3 simple types, and null or undefined
      if (null == obj || "object" != typeof obj) return obj;

      // Handle Date
      if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
      }

      // Handle Array
      if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
          copy[i] = this.COMMON.clone(obj[i]);
        }
        return copy;
      }

      // Handle Object
      if (obj instanceof Object) {
        copy = {};
        for (let attr in obj) {
          if (obj.hasOwnProperty(attr))
            copy[attr] = this.COMMON.clone(obj[attr]);
        }
        return copy;
      }

      throw new Error("Unable to copy obj! Its type isn't supported.");
    },

    /*
     * UIDを作成する。
     */
    generateUID: () => {
      return uuidv4();
    },
  };
}

export { AREA_COLORS };
