import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CameraSettings } from "./CameraSettings";
import { Crowddetection } from "./Crowddetection";
import { DetectionHistory } from "./DetectionHistory";
import { DeviceSettings } from "./DeviceSettings";
import { UserManagement } from "./UserManagement";

export const ContentLayout: React.FC = () => {
  return (
    <Routes>
      <Route path="/detection-history" element={<DetectionHistory />} />
      <Route path="/crowd-detection" element={<Crowddetection />} />
      <Route path="/devices" element={<DeviceSettings />} />
      <Route path="/cameras" element={<CameraSettings />} />
      <Route path="/user-management" element={<UserManagement />} />
      <Route
        path="/*"
        element={<Navigate to={"/detection-history"} replace />}
      />
    </Routes>
  );
};
