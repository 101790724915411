import { NotificationEmail } from "./NotificationEmail";
import DataBasicForm from "../type";
import { NOTIFICATION_INPUT_NUM } from "./utils";
import { Fragment } from "react";

type Props = {
    setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
    dataForm: DataBasicForm;
};

export const NotificationEmailGroup: React.FC<Props> = ({
    dataForm,
    setDataForm,
}) => {
    return <>
        {
            Array.from({ length: NOTIFICATION_INPUT_NUM }).map((_, formNumber) => {
                return <Fragment key={formNumber}>
                    <div className="my-3">
                        <NotificationEmail
                            formNumber={formNumber}
                            dataForm={dataForm}
                            setDataForm={setDataForm}
                            disabled={dataForm.isCloneConfig}
                        />
                    </div>
                </Fragment>
            })
        }
    </>
}