import { DayName, NotificationRule, NotificationScheduleFE } from "../../../../store/types/models";
import { handleErrorNotification } from "../../../../utils";

export const NOTIFICATION_INPUT_NUM = 5

const isRuleWellDefined = (x: NotificationRule): boolean => {
  return x.dayName !== null && x.startTime !== null && x.endTime !== null &&
    x.startTime <= x.endTime && x.startTime !== "" && x.endTime !== ""
}

export const dayNameToJPstring = (x: DayName | null): string => {
  if (x === "Mon") {
    return "月"
  } else if (x === "Tue") {
    return "火"
  } else if (x === "Wed") {
    return "水"
  } else if (x === "Thu") {
    return "木"
  } else if (x === "Fri") {
    return "金"
  } else if (x === "Sat") {
    return "土"
  } else if (x === "Sun") {
    return "日"
  } else {
    return "null"
  }
}

export function validateNotificationData(emails: string[], schedulePairs: [number, NotificationScheduleFE][]): boolean {
  const notification_emails_set = new Set(emails);
  if (notification_emails_set.size !== emails.length) {
    handleErrorNotification("通知先メールアドレスが重複しています");
    return false;
  }

  for (let i = 0; i < schedulePairs.length; i++) {
    const schedule = schedulePairs[i][1];
    for (let j = 0; j < schedule.rules.length; j++) {
      const rule = schedule.rules[j];
      if (!isRuleWellDefined(rule)) {
        handleErrorNotification(`不適切な時間帯ルールがあります`)
        return false;
      }
    }
  }

  for (let i = 0; i < schedulePairs.length; i++) {
    const schedule = schedulePairs[i][1];
    const formNumber = schedulePairs[i][0]
    const enabledRules = schedule.rules.filter(rule => rule.rule_enable)
    for (let j = 0; j < enabledRules.length; j++) {
      const rule = enabledRules[j];
      if (enabledRules.filter(rule2 => rule !== rule2 && rule.dayName === rule2.dayName).some(rule2 => isRuleIntersection(rule2, rule))) {
        handleErrorNotification(`通知先アドレス${formNumber + 1}における${dayNameToJPstring(rule.dayName)}曜日の設定に、アクティブな時間帯ルール同士の交わりがあります`)
        return false
      }
    }
  }

  return true

}

const isRuleIntersection = (x: NotificationRule, y: NotificationRule): boolean => {
  return x.startTime !== null && x.endTime !== null && y.startTime !== null && y.endTime !== null
    && y.startTime <= x.endTime && x.startTime <= y.endTime
}
