import React, { } from "react";
import { FormControl as BootstrapFormControl, InputGroup } from "react-bootstrap";
import { ValidateEmail } from "../../../../utils/common";
import DataBasicForm from "../type";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { NotificationRule } from "../../../../store/types/models";
import { handleErrorNotification } from "../../../../utils";
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from "uuid";
import { ScheduleRule } from "./ScheduleRule";
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

const DEFAULT_RULE = (): NotificationRule => ({ reactId: uuidv4(), dayName: null, rule_enable: true, startTime: null, endTime: null })

type NotificationEmailProps = {
  formNumber: number
  setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
  dataForm: DataBasicForm;
  disabled: boolean;
}

export const NotificationEmail = ({ formNumber, setDataForm, dataForm, disabled }: NotificationEmailProps) => {
  const [notificationEmailFocused, setNotificationEmailFocused] = React.useState(false);

  const email = dataForm.notification_emails[formNumber]
  const schedule = dataForm.notification_schedules[formNumber]
  const scheduleEnabled = schedule.enabled

  const onChangeScheduleEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataForm((prev) => {
      const newSchedules = prev.notification_schedules.concat()
      newSchedules[formNumber] = {
        ...newSchedules[formNumber],
        enabled: event.target.checked
      }
      return {
        ...prev,
        notification_schedules: newSchedules
      }
    })
  };

  return <>
    <div style={{ width: "100%" }}>
      <InputGroup style={{ width: "70%" }}>
        <InputGroup.Text style={{ width: "210px" }}>
          {"通知先メールアドレス" + (formNumber + 1).toString()}
        </InputGroup.Text>
        <BootstrapFormControl
          style={{ borderRadius: "0.25rem" }} // ここでborderRadiusを指定しないとメールアドレス入力フォームの右端が丸くならない
          id={"notificationEmail" + formNumber}
          data-cy={"_notificationEmail" + formNumber}
          value={email}
          onChange={(e) => {
            setDataForm((prev) => {
              const currentEmails = dataForm.notification_emails.concat()
              currentEmails[formNumber] = e.target.value
              return {
                ...prev,
                notification_emails: currentEmails,
              };
            });
          }}
          disabled={disabled}
          onFocus={() => setNotificationEmailFocused(true)}
          onBlur={() => setNotificationEmailFocused(false)}
          isInvalid={
            !notificationEmailFocused
              ? email !== "" && !ValidateEmail(email)
              : undefined
          }
          placeholder={"自由入力"}
        />
        <div style={{ display: "inline-block", marginLeft: "20px", width: "175px", marginRight: "20px" }}>
          <FormControlLabel
            disabled={dataForm.isCloneConfig}
            control={
              <Switch
                data-cy={"_notificationEmail_scheduleEnabled" + formNumber}
                disabled={dataForm.isCloneConfig}
                checked={scheduleEnabled}
                onChange={onChangeScheduleEnabled}
              />
            }
            label={
              <span>{`詳細設定(${scheduleEnabled ? "ON" : "OFF"})`}</span>
            }
          />
        </div>
        <Button
          style={{ paddingLeft: "5px" }}
          variant="outlined"
          disabled={!scheduleEnabled || dataForm.isCloneConfig}
          data-cy={"_notificationEmail_addRule" + formNumber}
          onClick={() => {
            if (schedule.rules.length === 20) {
              handleErrorNotification("時間帯ルールはこれ以上追加できません")
              return
            }
            setDataForm((prev) => {
              const newSchedules = prev.notification_schedules.concat()
              newSchedules[formNumber].rules.push(DEFAULT_RULE())
              return {
                ...prev,
                notification_schedules: newSchedules
              }
            })
          }}>
          <AddIcon fontSize="small" />時間帯ルールを追加
        </Button>
        <div style={{
          marginLeft: "10px",
          display: "grid",
          placeItems: "center"
        }}>
          <Tooltip
            style={{ verticalAlign: "middle" }}
            title="詳細設定がOFFの場合は、検知されたら24時間メールが送信されます。また、詳細設定がONの場合に有効な時間帯ルールが1つもないと、検知メールは届きません。"
            placement="right">
            <HelpIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
          </Tooltip>
        </div>
      </InputGroup>
      {schedule.enabled && schedule.rules.map((rule) => {
        return <ScheduleRule
          key={rule.reactId}
          formNumber={formNumber}
          rule={rule}
          dataForm={dataForm}
          setDataForm={setDataForm}
        />
      })}
    </div>
  </>
}


