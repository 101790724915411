import {FunctionComponent} from "react";

export const Logo: FunctionComponent = () => {
  return (
    <img
      src="/images/OWLai_logo.png"
      alt="映像AIプラットフォーム OWLai logo"
      style={{ height: "3rem" }}
    />
  );
};

export default Logo;
