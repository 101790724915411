import { IRect } from "konva/lib/types";
import { useRef, useState, useEffect } from "react";
import { Group, Label, Tag, Arrow, Line, Circle, Text } from "react-konva";
import { LineCross, LineCrossPoint } from "../../../../store/types/models";
import { clamp, CANVAS_WIDTH, CANVAS_HEIGHT, pullBackGroupPosition } from "./LineCrossLayer";

const FONT_SIZE = 11
const LINE_STROKE_WIDTH = 2
const CIRCLE_RADIUS = 3;

// 透明度の設定
const NAME_LABEL_OPACITY = 1
const ARROW_OPACITY = 1
const DIRECTION_LABEL_OPACITY = 1

// 色の設定
export const IN_COLOR = "rgb(45,155,240)";
export const OUT_COLOR = "rgb(242,71,38)";
const LINE_COLOR = "black";
const CIRCLE_COLOR = "black";
const LINE_SELECT_COLOR = "yellow"
const CIRCLE_SELECT_COLOR = "red"

// 矢印のストローク長やヘッドの大きさの設定
const ARROW_STROKE_WIDTH = 9;
const ARROW_HEAD_WIDTH = 7;
const ARROW_STROKE_HEIGHT = 150;
const ARROW_HEIGHT_SCALE = 0.2;

// ラベルの設定
const LABEL_WIDTH_IN = 15
const LABEL_WIDTH_OUT = 26
const LABEL_PADDING = 3
const LABEL_HEIGHT = FONT_SIZE + LABEL_PADDING


type KonvaLineCrossProps = {
    initialData: LineCross
    setLineDataToFormData: (crossline: LineCross) => void;
    selected: boolean
    lineIndex: number
    mousePosition: LineCrossPoint | null
};
export const KonvaLineCross = ({ mousePosition, lineIndex, selected, initialData, setLineDataToFormData }: KonvaLineCrossProps) => {
    const startCircleRef = useRef<any>(null);
    const endCircleRef = useRef<any>(null);

    const [startCircle, setStartCircle] = useState<LineCrossPoint>(initialData.p1);
    const [endCircle, setEndCircle] = useState<LineCrossPoint>(initialData.p2);

    useEffect(() => {
        if (mousePosition !== null) {
            const newData = { ...mousePosition }
            setEndCircle(newData)
        }
    }, [mousePosition])

    const [isStartCircleDragging, setIsStartCircleDragging] = useState(false)
    const [isEndCircleDragging, setIsEndCircleDragging] = useState(false)

    const middlePoint = {
        x: (startCircle.x + endCircle.x) / 2,
        y: (startCircle.y + endCircle.y) / 2
    };

    const lineDegree = (Math.atan2(endCircle.y - startCircle.y, endCircle.x - startCircle.x) / Math.PI) * 180;

    const absoluteLinePosition = (): LineCross => {
        const startPoint = startCircleRef.current.absolutePosition()
        const endPoint = endCircleRef.current.absolutePosition()
        return {
            p1: startPoint,
            p2: endPoint
        }
    }

    const onCircleDragMove = (e: any, circleType: "start" | "end") => {
        const relativeX = e.target.attrs.x;
        const relativeY = e.target.attrs.y;

        const absoluteX = e.target.absolutePosition().x;
        const absoluteY = e.target.absolutePosition().y;

        e.target.absolutePosition({
            x: clamp(absoluteX, 0, CANVAS_WIDTH),
            y: clamp(absoluteY, 0, CANVAS_HEIGHT)
        });

        const group = e.target.findAncestors('Group')[0]
        const groupPosition = group.getAbsolutePosition();

        const setter = circleType === "start" ? setStartCircle : setEndCircle
        setter({
            x: clamp(relativeX, 0 - groupPosition.x, CANVAS_WIDTH - groupPosition.x),
            y: clamp(relativeY, 0 - groupPosition.y, CANVAS_HEIGHT - groupPosition.y)
        });
    }

    const onCircleDragEnd = (e: any, circleType: "start" | "end") => {
        const setter = circleType === "start" ? setIsStartCircleDragging : setIsEndCircleDragging
        setter(false)
        setLineDataToFormData(absoluteLinePosition())
        const group = e.target.findAncestors('Group')[0]
        pullBackGroup(group)
    }

    /** 枠外にはみ出したGroupを引き戻す処理 */
    const pullBackGroup = (group: any) => {
        const linePosition = absoluteLinePosition()
        const lineRect: IRect = {
            x: Math.min(linePosition.p1.x, linePosition.p2.x),
            y: Math.min(linePosition.p1.y, linePosition.p2.y),
            width: Math.abs(linePosition.p1.x - linePosition.p2.x),
            height: Math.abs(linePosition.p1.y - linePosition.p2.y),
        }
        const groupPosition = group.getAbsolutePosition();
        group.setAbsolutePosition(pullBackGroupPosition(lineRect, groupPosition))
    }

    const onGroupDragMove = (e: any) => {
        if (e.target.getClassName() === 'Group') {
            const group = e.target
            pullBackGroup(group)
        }
    }

    return <Group
        draggable={selected}
        onDragEnd={() => setLineDataToFormData(absoluteLinePosition())}
        onDragMove={onGroupDragMove}
    >
        <Label
            x={startCircle.x}
            y={startCircle.y}
            offsetY={-LINE_STROKE_WIDTH / 2}
            rotation={lineDegree}>
            <Tag
                fill={"gray"}
                opacity={NAME_LABEL_OPACITY}
                pointerWidth={40}
                pointerDirection="none"
            />
            <Text
                text={"ライン " + (lineIndex + 1).toString()}
                fontFamily="Calibri"
                fontSize={FONT_SIZE}
                padding={1}
                fill="white"
                align="center"
            />
        </Label>
        <Arrow
            x={middlePoint.x}
            y={middlePoint.y}
            points={[0, 0, 0, ARROW_STROKE_HEIGHT]}
            pointerWidth={ARROW_HEAD_WIDTH}
            strokeWidth={ARROW_STROKE_WIDTH}
            scaleY={ARROW_HEIGHT_SCALE}
            rotation={lineDegree}
            draggable={false}
            opacity={ARROW_OPACITY}
            fill={OUT_COLOR}
            stroke={OUT_COLOR}
        />
        <Arrow
            opacity={ARROW_OPACITY}
            x={middlePoint.x}
            y={middlePoint.y}
            points={[0, 0, 0, ARROW_STROKE_HEIGHT]}
            pointerWidth={ARROW_HEAD_WIDTH}
            strokeWidth={ARROW_STROKE_WIDTH}
            scaleY={ARROW_HEIGHT_SCALE}
            rotation={lineDegree + 180}
            draggable={false}
            fill={IN_COLOR}
            stroke={IN_COLOR}
        />
        <Label
            x={middlePoint.x}
            y={middlePoint.y}
            offsetX={-20 - LABEL_WIDTH_IN}
            offsetY={-20 - LABEL_HEIGHT}
            rotation={lineDegree + 90}
            opacity={DIRECTION_LABEL_OPACITY}
        >
            <Tag
                offsetX={LABEL_WIDTH_IN / 2}
                offsetY={LABEL_HEIGHT / 2}
                pointerWidth={40}
                pointerDirection="right"
                rotation={-1 * (lineDegree + 90)}
                fill={IN_COLOR}
            />
            <Text
                text="IN"
                width={LABEL_WIDTH_IN}
                height={LABEL_HEIGHT}
                offsetX={LABEL_WIDTH_IN / 2}
                offsetY={LABEL_HEIGHT / 2}
                rotation={-1 * (lineDegree + 90)}
                fontSize={FONT_SIZE}
                fontFamily="Calibri"
                align="center"
                verticalAlign="middle"
                padding={2}
                fill="white"
            />
        </Label>
        <Label
            x={middlePoint.x}
            y={middlePoint.y}
            offsetX={20}
            offsetY={20}
            rotation={lineDegree + 90}
            opacity={DIRECTION_LABEL_OPACITY}
        >
            <Tag fill={OUT_COLOR}
                rotation={-1 * (lineDegree + 90)}
                offsetX={LABEL_WIDTH_OUT / 2}
                offsetY={LABEL_HEIGHT / 2}
                pointerWidth={40}
                pointerDirection="left" />
            <Text
                text="OUT"
                width={LABEL_WIDTH_OUT}
                height={LABEL_HEIGHT}
                offsetX={LABEL_WIDTH_OUT / 2}
                offsetY={LABEL_HEIGHT / 2}
                rotation={-1 * (lineDegree + 90)}
                fontFamily="Calibri"
                fontSize={FONT_SIZE}
                align="center"
                verticalAlign="middle"
                padding={2}
                fill="white"
            />
        </Label>
        <Line
            draggable={false}
            points={[startCircle.x, startCircle.y, endCircle.x, endCircle.y]}
            stroke={selected ? LINE_SELECT_COLOR : LINE_COLOR}
            strokeWidth={LINE_STROKE_WIDTH}
            x={0}
            y={0}
        />
        <Circle
            opacity={isStartCircleDragging ? 0.5 : 1}
            stroke={CIRCLE_SELECT_COLOR}
            strokeWidth={selected ? 7 : 0}
            draggable={selected}
            onDragStart={() => { setIsStartCircleDragging(true) }}
            onDragMove={(e) => onCircleDragMove(e, "start")}
            onDragEnd={(e) => onCircleDragEnd(e, "start")}
            x={startCircle.x}
            y={startCircle.y}
            radius={CIRCLE_RADIUS}
            fill={isStartCircleDragging ? CIRCLE_SELECT_COLOR : CIRCLE_COLOR}
            ref={startCircleRef}
        />
        <Circle
            opacity={isEndCircleDragging ? 0.5 : 1}
            stroke={CIRCLE_SELECT_COLOR}
            strokeWidth={selected ? 7 : 0}
            draggable={selected}
            onDragStart={() => { setIsEndCircleDragging(true) }}
            onDragMove={(e) => onCircleDragMove(e, "end")}
            onDragEnd={(e) => onCircleDragEnd(e, "end")}
            x={endCircle.x}
            y={endCircle.y}
            radius={CIRCLE_RADIUS}
            fill={isEndCircleDragging ? CIRCLE_SELECT_COLOR : CIRCLE_COLOR}
            ref={endCircleRef}
        />
    </Group>
};