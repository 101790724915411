import { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CsvDataType, CsvExportLogic, ImageExportLogic } from "../Crowddetection";
import { DeviceLineSelectContainer, DeviceType, LineType } from "./DeviceLineSelect";
import { DropdownOfDay } from "./DropdownOfDay";
import { DropdownOfHour } from "./DropdownOfHour";
import { DropdownOfMonth } from "./DropdownOfMonth";
import { DropdownOfRange } from "./DropdownOfRange";
import { DropdownOfYear } from "./DropdownOfYear";
import { RangeInfo } from "./types";
import objectHash from 'object-hash';
import API from '../../../api';
import useAPIService from '../../../api/useAPIService';
import { ResponseStatus } from '../../../constant';
import { handleErrorNotification } from '../../../utils';
import { setValidRangeInfo, XRange } from './AttributeAnalysisView';
import { GetXAxisLabels, GetXAxisName } from './common';
import { PeopleCountBE, transformChartData } from './PeopleCountView';
import { csvDeviceCameraString } from './PeopleCountChart';
import dayjs from 'dayjs';

type StayingCountChartProps = {
    activeTabKey: string
    chartData: StayingChartData
    csvExportLogic: CsvExportLogic
    imageExportLogic: ImageExportLogic
    isLoading: boolean,
    setRangeInfo: React.Dispatch<React.SetStateAction<RangeInfo>>,
    rangeInfo: RangeInfo
    callback: (params: { devices: DeviceType[], lines: LineType[] }) => void
    devices: DeviceType[]
    lines: LineType[]
}

/**
 * 累積和を計算する 
 * 例：[1,2,3,4] => [1,3,6,10] 
 * */
const cumulativeSum = (arr: readonly number[]): number[] => {
    const xs: number[] = []
    for (let i = 0; i < arr.length; i++) {
        const x = arr[i] + (xs[i - 1] || 0)
        xs.push(x)
    }
    return xs
}

export type StayingChartData = {
    data: number[];
    labels: string[];
    xAxisName: string
};


type useStayingChartReturnType = {
    chartData: StayingChartData,
    isLoading: boolean,
    setRangeInfo: React.Dispatch<React.SetStateAction<RangeInfo>>,
    rangeInfo: RangeInfo
    callback: (params: { devices: DeviceType[], lines: LineType[] }) => void
    devices: DeviceType[]
    lines: LineType[]
}

export function useStayingChartData(): useStayingChartReturnType {

    const now = new Date()

    const defaultRangeInfo: RangeInfo = {
        rangeType: '時',
        year: now.getFullYear(),
        month: now.getMonth() + 1,
        day: now.getDate(),
        hour: now.getHours()
    };


    const initChartData: StayingChartData = {
        data: [],
        labels: [],
        xAxisName: ""
    };

    const [chartData, setChartData] = useState<StayingChartData>(initChartData);
    const [rangeInfo, setRangeInfo] = useState<RangeInfo>(defaultRangeInfo);

    const [devices, setDevices] = useState<DeviceType[]>([])
    const [lines, setLines] = useState<LineType[]>([])

    const callback: (params: { devices: DeviceType[], lines: LineType[] }) => void = ({ devices: devicesA, lines: linesA }) => {
        if (objectHash(devices) === objectHash(devicesA) && objectHash(lines) === objectHash(linesA)) {
            return
        } else {
            setDevices(devicesA)
            setLines(linesA)
        }
    }

    const { invoke: getChartDataHour, status: statusHour, isLoading: isLoadingHour } = useAPIService<PeopleCountBE<XRange>[]>(API.CrowdDetection.getChartData)
    const { invoke: getChartDataDay, status: statusDay, isLoading: isLoadingDay } = useAPIService<PeopleCountBE<XRange>[]>(API.CrowdDetection.getChartData)

    useEffect(() => {
        if (statusHour === ResponseStatus.FAIL || statusDay === ResponseStatus.FAIL) {
            handleErrorNotification("データ取得に失敗しました");
            const empty: StayingChartData = {
                data: [],
                labels: GetXAxisLabels(rangeInfo),
                xAxisName: GetXAxisName(rangeInfo.rangeType)
            };
            setChartData(empty)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusHour, statusDay])


    useEffect(() => {
        if (setValidRangeInfo(now, setRangeInfo, rangeInfo)) {
            return
        }

        const asyncFunc = async () => {

            if (rangeInfo.rangeType === "日") {
                const params: Parameters<typeof API.CrowdDetection.getChartData>[0] = {
                    rangeInfo: rangeInfo,
                    lineInfos: lines.map(l => ({ deviceID: l.camera.deviceID, cameraID: l.camera.cameraID, lineNo: l.no })),
                    deviceIDs: devices.map(d => d.deviceID)
                }

                const dayData = await getChartDataDay(params)
                if (dayData) {
                    const dayChartData = transformChartData(dayData, rangeInfo, now)
                    const sumIns = cumulativeSum(dayChartData.ins)
                    const sumOuts = cumulativeSum(dayChartData.outs);
                    const stayingCounts = sumIns.map((sumIn, i) => {
                        return sumIn - sumOuts[i]
                    })

                    //0時は滞留者数0と仮定しており、X座標が0のＹ座標は必ず0なので、配列の先頭に0を追加する
                    stayingCounts.unshift(0)
                    //23時台の累積は次の日の０時における滞留者数で、0と仮定するので、配列から除外する
                    if (stayingCounts.length === 25) {
                        stayingCounts.pop()
                    }

                    const stayingChartData: StayingChartData = {
                        data: stayingCounts,
                        labels: dayChartData.labels,
                        xAxisName: dayChartData.xAxisName
                    }
                    setChartData(stayingChartData)
                }


            } else if (rangeInfo.rangeType === "時") {
                //選択された日付の「日」APIを呼ぶ
                const dayParams: Parameters<typeof API.CrowdDetection.getChartData>[0] = {
                    rangeInfo: { ...rangeInfo, rangeType: "日" },
                    lineInfos: lines.map(l => ({ deviceID: l.camera.deviceID, cameraID: l.camera.cameraID, lineNo: l.no })),
                    deviceIDs: devices.map(d => d.deviceID)
                }

                //選択された日付の「時」APIを呼ぶ
                const hourParams: Parameters<typeof API.CrowdDetection.getChartData>[0] = {
                    rangeInfo: rangeInfo,
                    lineInfos: lines.map(l => ({ deviceID: l.camera.deviceID, cameraID: l.camera.cameraID, lineNo: l.no })),
                    deviceIDs: devices.map(d => d.deviceID)
                }

                const [dayData, hourData] = await Promise.all([getChartDataDay(dayParams), getChartDataHour(hourParams)])

                if (dayData && hourData) {
                    const dayChartData = transformChartData(dayData, { ...rangeInfo, rangeType: "日" }, now)
                    const hourChartData = transformChartData(hourData, rangeInfo, now)

                    const daySumIns = dayChartData.ins.slice(0, rangeInfo.hour!).reduce((x, y) => x + y, 0)
                    const daySumOuts = dayChartData.outs.slice(0, rangeInfo.hour!).reduce((x, y) => x + y, 0)
                    const dayStayingCount = daySumIns - daySumOuts

                    const hourDiffs = hourChartData.ins.map((In, i) => {
                        return In - hourChartData.outs[i]
                    })

                    const stayingCounts = cumulativeSum(hourDiffs).map(x => x + dayStayingCount)

                    stayingCounts.unshift(dayStayingCount)
                    //55分以降のデータは次の時間選択の０時に表示される値なので取り除く
                    if (stayingCounts.length === 13) {
                        stayingCounts.pop()
                    }

                    const stayingChartData: StayingChartData = {
                        data: stayingCounts,
                        labels: hourChartData.labels,
                        xAxisName: hourChartData.xAxisName
                    }
                    setChartData(stayingChartData)
                }
            }
        }

        asyncFunc()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeInfo, lines, devices]);

    return { isLoading: (isLoadingDay || isLoadingHour), chartData, setRangeInfo, rangeInfo, callback, devices, lines }
}


const createCsvData = (chartData: StayingChartData, rangeInfo: RangeInfo) => {
    const csvData: CsvDataType = []

    chartData.labels.forEach((_, i) => {
        const csvLine: CsvDataType[0] = []
        const xAxisNameIndex = 0
        csvLine[xAxisNameIndex] = parseInt(chartData.labels[i])
        csvLine.push(chartData.data[i])

        //日付の追加
        const rangeType = rangeInfo.rangeType!
        if (rangeType === "年") {
            const monthIndex = (csvLine[xAxisNameIndex] as number) - 1
            const date = dayjs(new Date(rangeInfo.year!, monthIndex)).format("YYYY-MM-DD HH:mm")
            csvLine.unshift(date)
        } else if (rangeType === "月") {
            const day = (csvLine[xAxisNameIndex] as number)
            const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1, day)).format("YYYY-MM-DD HH:mm")
            csvLine.unshift(date)
        } else if (rangeType === "日") {
            const hour = (csvLine[xAxisNameIndex] as number)
            const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1, rangeInfo.day!, hour)).format("YYYY-MM-DD HH:mm")
            csvLine.unshift(date)
        } else if (rangeType === "時") {
            const minute = (csvLine[xAxisNameIndex] as number)
            const date = dayjs(new Date(rangeInfo.year!, rangeInfo.month! - 1, rangeInfo.day!, rangeInfo.hour!, minute)).format("YYYY-MM-DD HH:mm")
            csvLine.unshift(date)
        }
        csvData.push(csvLine)
    })

    return csvData
}

export const StayingCountChartContainer: React.FC<Pick<StayingCountChartProps, "activeTabKey" | "csvExportLogic" | "imageExportLogic">> = ({ activeTabKey, csvExportLogic, imageExportLogic }) => {

    const { chartData, callback, devices, isLoading, lines, rangeInfo, setRangeInfo } = useStayingChartData()

    // CSVデータの更新・画像ファイル名更新
    const updateExportData = () => {
        if (activeTabKey !== "staying") return
        if (rangeInfo.rangeType === null) return

        const csvFileName = "混雑状況"

        let csvData: CsvDataType = []

        //デバイス・カメラ選択情報
        if (devices.length !== 0 && lines.length !== 0) {
            const str = csvDeviceCameraString(devices, lines)
            const firstHeader = ["デバイス・カメラ選択状態", str]
            csvData.push(firstHeader)
        } else if (devices.length !== 0) {
            const firstHeader = ["デバイス・カメラ選択状態", devices.map(d => d.deviceName).join(",")]
            csvData.push(firstHeader)
        } else {
            const firstHeader = ["デバイス・カメラ選択状態", ""]
            csvData.push(firstHeader)
        }

        // header処理
        const headers: CsvDataType[0] = ["日付", chartData.xAxisName, "滞留者数"]

        csvData.push(headers)

        const rowData = createCsvData(chartData, rangeInfo)

        csvData = csvData.concat(rowData)

        csvExportLogic.setCsvState({
            fileName: csvFileName,
            data: csvData
        })
        imageExportLogic.imageStateRef.current.fileName = csvFileName
    }

    useEffect(() => {
        if (activeTabKey === "staying") {
            updateExportData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData, activeTabKey])

    // 初期表示中、APIデータ取得中にダウンロードしないようにする処理
    useEffect(() => {
        if (activeTabKey === "staying" && !isLoading) {
            updateExportData()
            csvExportLogic.setIsCsvAvailable(true)
            imageExportLogic.setIsImageAvailable(true)
        } else if (activeTabKey === "staying" && (isLoading)) {
            csvExportLogic.setIsCsvAvailable(false)
            imageExportLogic.setIsImageAvailable(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, activeTabKey])


    return <StayingCountChart
        callback={callback}
        devices={devices}
        isLoading={isLoading}
        lines={lines}
        rangeInfo={rangeInfo}
        setRangeInfo={setRangeInfo}
        activeTabKey={activeTabKey} chartData={chartData}
        csvExportLogic={csvExportLogic}
        imageExportLogic={imageExportLogic}
    />
}


export const StayingCountChart: React.FC<StayingCountChartProps> = ({ activeTabKey, chartData, setRangeInfo, rangeInfo, callback }) => {

    const data = chartData.labels.map((x, i) => ({
        name: chartData.labels[i],
        data: chartData.data[i]
    }))

    return <div>
        <div style={{ marginLeft: "5rem", }}>

            <div style={{ justifyContent: "flex-start", display: "flex", }}>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <DropdownOfRange setRangeInfo={setRangeInfo} rangeInfo={rangeInfo} isOnlyDayHour={true} />
                    <DropdownOfYear setRangeInfo={setRangeInfo} rangeInfo={rangeInfo} />
                    <DropdownOfMonth setRangeInfo={setRangeInfo} rangeInfo={rangeInfo} />
                    <DropdownOfDay setRangeInfo={setRangeInfo} rangeInfo={rangeInfo} />
                    <DropdownOfHour setRangeInfo={setRangeInfo} rangeInfo={rangeInfo} />
                </div>
                <div className="DeviceSelectHelpText" style={{ marginLeft: "5px", paddingRight: "120px" }}>
                    <DeviceLineSelectContainer callback={callback} />
                </div>
            </div>

        </div>
        <div style={{ width: "100%", display: "flex", placeItems: "center", backgroundColor: "white" }}>
            <div id="StayingCountChartPicture" style={{ backgroundColor: "white", width: "100%" }}>
                {activeTabKey === "staying" ? < ResponsiveContainer
                    width="95%"
                    aspect={3} >
                    <LineChart
                        id='StayingCountChart'
                        width={1000}
                        height={600}
                        data={data}
                        margin={{
                            top: 40,
                            right: 35,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" label={{ value: chartData.xAxisName, position: "right", offset: 15 }} />
                        <YAxis allowDecimals={false} label={{ value: "人", position: "top", offset: 15 }} />
                        <Tooltip />
                        <Line
                            type="linear"
                            dataKey="data"
                            stroke={"#2d9bf0"}
                            name="滞留者数"
                            strokeWidth={2}
                            dot={{ strokeWidth: 1 }}
                            isAnimationActive={false} //dotが表示されないバグのため：https://github.com/recharts/recharts/issues/1426
                        />
                    </LineChart>
                </ResponsiveContainer >
                    : <></>}
            </div>
        </div>
    </div>
}