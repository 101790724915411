import { AxiosInstance, AxiosPromise } from "axios"
import { ChartDataAPIParams } from "../../routes/DashBoard/crowd-detection/types";

/**人数カウントと属性分析API補関数 */
export function getChartDataAux(
    endPoint: "count" | "analysis",
    request: AxiosInstance,
    params: ChartDataAPIParams,
    abortSignal?: AbortSignal
): AxiosPromise {
    const rangeInfo = params.rangeInfo
    const deviceIDs = params.deviceIDs
    const lineInfos = params.lineInfos

    if (rangeInfo.rangeType === '年' && rangeInfo.year) {
        if (deviceIDs.length === 0 && lineInfos.length === 0) {
            return request.get(`/crowd-detection/${endPoint}/year`, {
                params: {
                    "year": rangeInfo.year
                },
                signal: abortSignal
            })
        } else if (deviceIDs.length > 0 && lineInfos.length === 0) {
            return request.post(`/crowd-detection/${endPoint}/device/year`,
                [{ "device_id": deviceIDs }]
                , {
                    params: {
                        "year": rangeInfo.year
                    },
                    signal: abortSignal
                })
        } else if (deviceIDs.length > 0 && lineInfos.length > 0) {
            const lineInfoPairs = lineInfos.map(lineInfo => ({
                "device_id": lineInfo.deviceID,
                "camera_id": lineInfo.cameraID,
                "line_no": lineInfo.lineNo
            }))
            return request.post(`/crowd-detection/${endPoint}/camera/year`,
                lineInfoPairs
                , {
                    params: {
                        "year": rangeInfo.year
                    },
                    signal: abortSignal
                })
        } else {
            throw new Error("FRONT_DEV_ERROR");
        }
    } else if (rangeInfo.rangeType === '月' && rangeInfo.year && rangeInfo.month) {
        if (deviceIDs.length === 0 && lineInfos.length === 0) {
            return request.get(`/crowd-detection/${endPoint}/month`, {
                params: {
                    "year": rangeInfo.year,
                    "month": rangeInfo.month
                },
                signal: abortSignal
            })
        } else if (deviceIDs.length > 0 && lineInfos.length === 0) {
            return request.post(`/crowd-detection/${endPoint}/device/month`,
                [{ "device_id": deviceIDs }]
                , {
                    params: {
                        "year": rangeInfo.year,
                        "month": rangeInfo.month
                    },
                    signal: abortSignal
                })
        } else if (deviceIDs.length > 0 && lineInfos.length > 0) {
            const lineInfoPairs = lineInfos.map(lineInfo => ({
                "device_id": lineInfo.deviceID,
                "camera_id": lineInfo.cameraID,
                "line_no": lineInfo.lineNo
            }))
            return request.post(`/crowd-detection/${endPoint}/camera/month`,
                lineInfoPairs
                , {
                    params: {
                        "year": rangeInfo.year,
                        "month": rangeInfo.month
                    },
                    signal: abortSignal
                })
        } else {
            throw new Error("FRONT_DEV_ERROR");
        }
    } else if (rangeInfo.rangeType === "日" && rangeInfo.year && rangeInfo.month && rangeInfo.day) {
        if (deviceIDs.length === 0 && lineInfos.length === 0) {
            return request.get(`/crowd-detection/${endPoint}/day`, {
                params: {
                    "year": rangeInfo.year,
                    "month": rangeInfo.month,
                    "day": rangeInfo.day,
                },
                signal: abortSignal
            })
        } else if (deviceIDs.length > 0 && lineInfos.length === 0) {
            return request.post(`/crowd-detection/${endPoint}/device/day`,
                [{ "device_id": deviceIDs }]
                , {
                    params: {
                        "year": rangeInfo.year,
                        "month": rangeInfo.month,
                        "day": rangeInfo.day
                    },
                    signal: abortSignal
                })
        } else if (deviceIDs.length > 0 && lineInfos.length > 0) {
            const lineInfoPairs = lineInfos.map(lineInfo => ({
                "device_id": lineInfo.deviceID,
                "camera_id": lineInfo.cameraID,
                "line_no": lineInfo.lineNo
            }))
            return request.post(`/crowd-detection/${endPoint}/camera/day`,
                lineInfoPairs
                , {
                    params: {
                        "year": rangeInfo.year,
                        "month": rangeInfo.month,
                        "day": rangeInfo.day
                    },
                    signal: abortSignal
                })
        } else {
            throw new Error("FRONT_DEV_ERROR");
        }
    } else if (rangeInfo.rangeType === '時' && rangeInfo.year && rangeInfo.month && rangeInfo.day && rangeInfo.hour != null) {
        if (deviceIDs.length === 0 && lineInfos.length === 0) {
            return request.get(`/crowd-detection/${endPoint}/hour`, {
                params: {
                    "year": rangeInfo.year,
                    "month": rangeInfo.month,
                    "day": rangeInfo.day,
                    "hour": rangeInfo.hour
                },
                signal: abortSignal
            })
        } else if (deviceIDs.length > 0 && lineInfos.length === 0) {
            return request.post(`/crowd-detection/${endPoint}/device/hour`,
                [{ "device_id": deviceIDs }]
                , {
                    params: {
                        "year": rangeInfo.year,
                        "month": rangeInfo.month,
                        "day": rangeInfo.day,
                        "hour": rangeInfo.hour
                    },
                    signal: abortSignal
                })
        } else if (deviceIDs.length > 0 && lineInfos.length > 0) {
            const lineInfoPairs = lineInfos.map(lineInfo => ({
                "device_id": lineInfo.deviceID,
                "camera_id": lineInfo.cameraID,
                "line_no": lineInfo.lineNo
            }))
            return request.post(`/crowd-detection/${endPoint}/camera/hour`,
                lineInfoPairs
                , {
                    params: {
                        "year": rangeInfo.year,
                        "month": rangeInfo.month,
                        "day": rangeInfo.day,
                        "hour": rangeInfo.hour
                    },
                    signal: abortSignal
                })
        } else {
            throw new Error("FRONT_DEV_ERROR");
        }
    } else {
        throw new Error("FRONT_DEV_ERROR");
    }
}

export type CrowdDetectionType = {
    getChartData(params: ChartDataAPIParams, abortSignal?: AbortSignal): AxiosPromise
}

export const CrowdDetectionMethod = (
    request: AxiosInstance
): CrowdDetectionType => ({
    getChartData(params, abortSignal) {
        return getChartDataAux("count", request, params, abortSignal)
    },
})