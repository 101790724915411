import React, { useEffect, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { RangeInfo } from './types';
import { GetRangeArray } from './common';

type DropdownOfHourProps = {
    rangeInfo: RangeInfo
    setRangeInfo: React.Dispatch<React.SetStateAction<RangeInfo>>;
}

export const DropdownOfHour: React.FC<DropdownOfHourProps> = ({ setRangeInfo, rangeInfo }) => {

    const items = GetRangeArray(24).map((x) => (x).toString());

    const now = new Date()

    const [disabled, setDisabled] = useState(true)

    const [labelHour, setLabelHour] = useState<string | null>(null);

    const onSelector = (hour: string | null) => {
        if (!hour) {
            return
        }

        setLabelHour(hour);
        setRangeInfo((prev) => {
            return {
                ...prev,
                hour: parseInt(hour)
            };
        });
    };


    useEffect(() => {
        if (rangeInfo.hour != null && labelHour !== rangeInfo.hour.toString()) {
            const newLabel = rangeInfo.hour.toString()
            setLabelHour(newLabel)
        }

        if (rangeInfo.rangeType && ["時"].includes(rangeInfo.rangeType)) {
            setDisabled(false)
        } else if (!disabled) {
            setDisabled(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeInfo])


    return (
        <Dropdown className="mx-2" as={ButtonGroup} onSelect={onSelector} style={{ width: "72px" }}>
            <Dropdown.Toggle variant="secondary" id="DropdownHour" disabled={disabled}>
                {labelHour ? labelHour + "時" : "時"}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ maxHeight: "500px", overflowY: "auto" }}>
                {items.map((item) => (
                    <Dropdown.Item key={item} eventKey={item} disabled={now.getFullYear() === rangeInfo.year && now.getMonth() + 1 === rangeInfo.month && now.getDate() === rangeInfo.day && now.getHours() < parseInt(item)}>{item}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
