import { AxiosInstance, AxiosPromise } from "axios";
import { IncidentCreate, IncidentUpdate } from "../../store/types/models";
export type DetectionHistoryType = {
  getIncidents(): AxiosPromise;
  updateIncident(params: { id: string; body: IncidentUpdate }): AxiosPromise;
  createIncident(params: IncidentCreate): AxiosPromise;
  deleteIncident(id: string): AxiosPromise;
  countIncident(params: string): AxiosPromise;
};
export const DetectionHistoryMethod = (
  request: AxiosInstance
): DetectionHistoryType => ({
  getIncidents() {
    return request.get(`/incidents/`);
  },
  updateIncident(params) {
    return request.put(`/incidents/${params.id}`, params.body);
  },
  createIncident(params) {
    return request.post(`/incidents/`, params);
  },
  deleteIncident(id) {
    return request.delete(`/incidents/${id}`);
  },
  countIncident(params) {
    return request.get(`/incidents/count?start=${params}`);
  },
});
