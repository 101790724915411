import React from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../../constant";
import { AuthContext } from "../../contexts/AuthContext";
import { UserRole } from "../../store/types/models";
import { ContentLayout } from "./ContentLayout";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  border-radius: 4px;
  background: white;
`;

const HeaderTab = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabContent = styled.div`
  display: flex;
  flex: 1;
  background-color: #f0f0f0;
`;

const HeaderItem = styled.div<{ isSelected?: boolean }>`
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? "#202020" : "none")};
`;

const Item = styled.div`
  padding: 0.5rem;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
`;

export const DashBoard: React.FC = () => {
  const { user } = React.useContext(AuthContext);

  const path = useLocation().pathname.split("/");
  const pathRoute = path[path.length - 1];
  const [showNav, setShowNav] = React.useState(false);

  const HEADER_TAB = React.useMemo(() => {
    if (user?.role !== UserRole.OPERATOR) {
      return [
        {
          link: "detection-history",
          title: "検知履歴",
          className: "bi bi-eye",
        },
        {
          link: "crowd-detection",
          title: "人流解析",
          className: "bi bi-graph-up",
        },
        {
          link: "devices",
          title: "デバイス設定",
          className: "bi bi-gear",
        },
        {
          link: "cameras",
          title: "カメラ設定",
          className: "bi bi-camera-video",
        },
        {
          link: "user-management",
          title: "ユーザー設定",
          className: "bi bi-people",
        },
      ];
    }
    return [
      {
        link: "detection-history",
        title: "検知履歴",
        className: "bi bi-eye",
      },
      {
        link: "crowd-detection",
        title: "人流解析",
        className: "bi bi-graph-up",
      },
    ];
  }, [user]);

  return (
    <Wrapper>
      <HeaderTab
        style={{
          width: showNav ? 250 : 70,
          transition: "width 0.5s",
          backgroundColor: "#2F2F2F",
          color: "white",
        }}
        data-cy="header_tab"
      >
        <div
          style={{
            display: "flex",
            justifyContent: showNav ? "flex-end" : "center",
            padding: "0.5rem 1rem",
          }}
        >
          <Button
            id="navButton"
            variant="outline-secondary"
            onClick={() => setShowNav(!showNav)}
            data-cy="nav_button"
          >
            <i className={showNav ? "bi bi-x-lg" : "bi bi-list"} />
          </Button>
        </div>
        <div style={{ flex: 1 }}>
          {HEADER_TAB.map((item) => {
            return (
              <HeaderItem
                id={item.title}
                key={item.link}
                isSelected={item.link === pathRoute}
              >
                <Link
                  to={`/dashboard/${item.link}`}
                  style={{ textDecoration: "none" }}
                >
                  <Item>
                    <i
                      className={item.className}
                      style={{ marginRight: 8, fontSize: 20 }}
                    />
                    {showNav && item.title}
                  </Item>
                </Link>
              </HeaderItem>
            );
          })}
        </div>
        <div
          style={{
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button
            id="changePassword"
            style={{ marginBottom: "0.5rem" }}
            variant="dark"
            href={process.env.REACT_APP_RECOVERY_PASSWORD_URL}
            target="_blank"
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_RECOVERY_PASSWORD_URL}`;
            }}
          >
            <i className="bi bi-pen" style={{ marginRight: 4 }} />
            {showNav && "パスワード変更"}
          </Button>
          <Button
            id="logoutButton"
            variant="danger"
            onClick={() => {
              const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
              localStorage.removeItem(ACCESS_TOKEN_KEY);
              localStorage.removeItem(REFRESH_TOKEN_KEY);
              window.location.href = `${process.env.REACT_APP_SIGOUT_URL}?access_token=${accessToken}`;
            }}
          >
            <i className="bi bi-box-arrow-right" style={{ marginRight: 4 }} />
            {showNav && "ログアウト"}
          </Button>
        </div>
      </HeaderTab>
      <TabContent>
        <ContentLayout />
      </TabContent>
    </Wrapper>
  );
};
