import { AxiosInstance, AxiosPromise } from "axios";
export type UserType = {
  getUser(): AxiosPromise;
};
export const UserMethod = (request: AxiosInstance): UserType => ({
  getUser() {
    const linkAPI = `/current-user/`;
    return request.get(linkAPI);
  },
});
