import React from "react";
import {
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DataBasicForm, { InputMetadata } from "./type";

type Props = {
  setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
  dataForm: DataBasicForm;
  item: InputMetadata;
};

export const MultipleAIThreadholdInput: React.FC<Props> = ({
  dataForm,
  setDataForm,
  item,
}) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {item.message}
    </Tooltip>
  );
  return (
    <div
      key={item.name}
      className="col"
      style={{
        display: "flex",
        padding: "0.5rem 2rem",
        justifyContent: "space-around",
      }}
    >
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1" style={{ width: "30%" }}>
            {item.label}
          </InputGroup.Text>
          <FormControl
            id={item.name}
            maxLength={item.maxLength}
            value={
              dataForm[`${item.name as keyof DataBasicForm}`]?.toString() || ""
            }
            onChange={(e) => {
              const re = /^[a-zA-Z0-9~`!@#$%^&*()_\-+=[\]|\\{}:;"'<>,.?/]*$/;
              const aiThreadCount = e.target.value.split(";");

              if (aiThreadCount.length <= 100 && re.test(e.target.value)) {
                setDataForm((prev) => {
                  return { ...prev, [item.name]: e.target.value };
                });
              }
            }}
            style={{ width: "70%" }}
            placeholder={"自由入力"}
            aria-label={item.label}
            aria-describedby="basic-addon1"
            onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('100文字以下で入力してください')}
            onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
          />
        </InputGroup>
      </OverlayTrigger>
    </div>
  );
};
