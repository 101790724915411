import React, { useState, useEffect } from 'react';
import { CountChartData, RangeInfo } from './types';
import { GetRangeArray, GetXAxisLabels, GetXAxisName } from './common';
import { PeopleCountChart } from "./PeopleCountChart";
import { DropdownOfRange } from "./DropdownOfRange";
import { DropdownOfDay } from "./DropdownOfDay";
import { DropdownOfHour } from "./DropdownOfHour";
import { DropdownOfMonth } from "./DropdownOfMonth";
import { DropdownOfYear } from "./DropdownOfYear";
import API from '../../../api';
import { handleErrorNotification } from '../../../utils';
import { ResponseStatus } from '../../../constant';
import { CsvExportLogic, ImageExportLogic } from '../Crowddetection';
import { DeviceLineSelectContainer, DeviceType, LineType } from './DeviceLineSelect';
import objectHash from 'object-hash';
import { XRange, setValidRangeInfo } from './AttributeAnalysisView';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import useChartDataAPI from '../../../api/useChartDataAPI';

export const transformChartData = (arraysOfTime: PeopleCountBE<XRange>[], rangeInfo: RangeInfo, now: Date): CountChartData => {

  let unit_of_time: XRange
  if (rangeInfo.rangeType === "年") {
    unit_of_time = "month"
  } else if (rangeInfo.rangeType === "月") {
    unit_of_time = "day"
  } else if (rangeInfo.rangeType === "日") {
    unit_of_time = "hour"
  } else if (rangeInfo.rangeType === "時") {
    unit_of_time = "minute"
  } else {
    throw new Error("FRONT_DEV_ERROR");
  }

  const dict = new Map<number, { "in_total": number, "out_total": number }>()
  let in_array: number[] = []
  let out_array: number[] = []

  arraysOfTime.forEach((x) => dict.set(x[unit_of_time], { "in_total": x["in_total"] ? x["in_total"] : 0, "out_total": x["out_total"] ? x["out_total"] : 0 }))

  switch (unit_of_time) {
    case "month":
      const visibleLastMonth = now.getFullYear() === rangeInfo.year ? now.getMonth() + 1 : 12
      in_array = GetRangeArray(visibleLastMonth).map((_, i) => dict.get(i + 1) ? dict.get(i + 1)!["in_total"] : 0)
      out_array = GetRangeArray(visibleLastMonth).map((_, i) => dict.get(i + 1) ? dict.get(i + 1)!["out_total"] : 0)
      break;

    case "day":
      const visibleLastDay = now.getFullYear() === rangeInfo.year && now.getMonth() + 1 === rangeInfo.month ? now.getDate() : new Date(rangeInfo.year!, rangeInfo.month!, 0).getDate()
      in_array = GetRangeArray(visibleLastDay).map((_, i) => dict.get(i + 1) ? dict.get(i + 1)!["in_total"] : 0)
      out_array = GetRangeArray(visibleLastDay).map((_, i) => dict.get(i + 1) ? dict.get(i + 1)!["out_total"] : 0)
      break;

    case "hour":
      const visibleLastHour = now.getFullYear() === rangeInfo.year && now.getMonth() + 1 === rangeInfo.month && now.getDate() === rangeInfo.day ? now.getHours() : 23
      in_array = GetRangeArray(visibleLastHour + 1).map((_, i) => dict.get(i) ? dict.get(i)!["in_total"] : 0)
      out_array = GetRangeArray(visibleLastHour + 1).map((_, i) => dict.get(i) ? dict.get(i)!["out_total"] : 0)
      break;

    case "minute":
      const visibleLastMinute = now.getFullYear() === rangeInfo.year && now.getMonth() + 1 === rangeInfo.month && now.getDate() === rangeInfo.day && now.getHours() === rangeInfo.hour ? Math.floor(now.getMinutes() / 5) + 1 : 12
      in_array = GetRangeArray(visibleLastMinute).map((_, i) => dict.get(i * 5) ? dict.get(i * 5)!["in_total"] : 0)
      out_array = GetRangeArray(visibleLastMinute).map((_, i) => dict.get(i * 5) ? dict.get(i * 5)!["out_total"] : 0)
      break;
  }

  const labels = GetXAxisLabels(rangeInfo)

  const modified: CountChartData = {
    ins: in_array,
    outs: out_array,
    labels: labels,
    xAxisName: GetXAxisName(rangeInfo.rangeType)
  };

  return modified
}

type usePeopleChartDataType = {
  chartData: CountChartData,
  isLoading: boolean,
  setRangeInfo: React.Dispatch<React.SetStateAction<RangeInfo>>,
  rangeInfo: RangeInfo
  callback: (params: { devices: DeviceType[], lines: LineType[] }) => void
  devices: DeviceType[]
  lines: LineType[]
}

export type PeopleCountBE<X extends XRange> = Record<X, number> & {
  "in_total": number
  "out_total": number
}

export function usePeopleChartData(): usePeopleChartDataType {

  const abortController = new AbortController()
  const now = new Date()

  const defaultRangeInfo: RangeInfo = {
    rangeType: '時',
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
    hour: now.getHours()
  };


  const initChartData: CountChartData = {
    ins: [],
    outs: [],
    labels: [],
    xAxisName: ""
  };

  const [chartData, setChartData] = useState<CountChartData>(initChartData);
  const [rangeInfo, setRangeInfo] = useState<RangeInfo>(defaultRangeInfo);

  const [devices, setDevices] = useState<DeviceType[]>([])
  const [lines, setLines] = useState<LineType[]>([])

  const callback: (params: { devices: DeviceType[], lines: LineType[] }) => void = ({ devices: devicesA, lines: linesA }) => {
    if (objectHash(devices) === objectHash(devicesA) && objectHash(lines) === objectHash(linesA)) {
      return
    } else {
      setDevices(devicesA)
      setLines(linesA)
    }
  }

  const { invoke: getChartData, data: apiData, status, isLoading } = useChartDataAPI<PeopleCountBE<XRange>[]>(API.CrowdDetection.getChartData)

  useEffect(() => {
    if (status === ResponseStatus.FAIL) {
      handleErrorNotification("データ取得に失敗しました");
      const empty: CountChartData = {
        ins: [],
        outs: [],
        labels: GetXAxisLabels(rangeInfo),
        xAxisName: GetXAxisName(rangeInfo.rangeType)
      };
      setChartData(empty)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])



  useEffect(() => {
    if (!apiData) return
    setChartData(transformChartData(apiData, rangeInfo, now))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData])

  useEffect(() => {
    if (setValidRangeInfo(now, setRangeInfo, rangeInfo)) {
      return
    }

    const params: Parameters<typeof API.CrowdDetection.getChartData>[0] = {
      rangeInfo: rangeInfo,
      lineInfos: lines.map(l => ({ deviceID: l.camera.deviceID, cameraID: l.camera.cameraID, lineNo: l.no })),
      deviceIDs: devices.map(d => d.deviceID)
    }

    // if (isLoading) {
    //   abortController.abort()
    // }
    getChartData(params, abortController.signal)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeInfo, lines, devices]);

  return { isLoading, chartData, setRangeInfo, rangeInfo, callback, devices, lines }
}

type Props = {
  activeTabKey: string
  csvExportLogic: CsvExportLogic
  imageExportLogic: ImageExportLogic
}
export const PeopleCountView: React.FC<Props> = ({ activeTabKey, csvExportLogic, imageExportLogic }) => {

  const { chartData: firstChartData, setRangeInfo: setFirstRangeInfo, rangeInfo: firstRangeInfo, isLoading: firstIsLoading, callback: firstCallback, devices: firstDevices, lines: firstLineInfos } = usePeopleChartData()
  const { chartData: secondChartData, setRangeInfo: setSecondRangeInfo, rangeInfo: secondRangeInfo, isLoading: secondIsLoading, callback: secondCallback, devices: secondDevices, lines: secondLineInfos } = usePeopleChartData()
  const [compareEnabled, setCompareEnabled] = useState(false)

  useEffect(() => {
    if (compareEnabled) {
      setSecondRangeInfo({
        ...secondRangeInfo,
        rangeType: firstRangeInfo.rangeType
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRangeInfo.rangeType, compareEnabled])

  const onChangeCompareEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    setCompareEnabled(newValue)
  };

  return (
    <div>
      <div style={{ marginLeft: "5rem", }}>
        <FormControlLabel
          style={{ margin: "-25px 0 -10px 0" }}
          control={
            <Switch
              data-cy={"peopleCountCompareGraphSwitch"}
              checked={compareEnabled}
              onChange={onChangeCompareEnabled}
            />
          }
          label={
            <span>{"比較グラフ表示"}</span>
          }
        />
        <div style={{ justifyContent: "flex-start", display: "flex", }}>
          <div style={{
            display: "flex",
            alignItems: "center"
          }}>
            <DropdownOfRange setRangeInfo={setFirstRangeInfo} rangeInfo={firstRangeInfo} />
            <span style={{ fontWeight: "bold" }}>グラフ１</span>
            <DropdownOfYear setRangeInfo={setFirstRangeInfo} rangeInfo={firstRangeInfo} />
            <DropdownOfMonth setRangeInfo={setFirstRangeInfo} rangeInfo={firstRangeInfo} />
            <DropdownOfDay setRangeInfo={setFirstRangeInfo} rangeInfo={firstRangeInfo} />
            <DropdownOfHour setRangeInfo={setFirstRangeInfo} rangeInfo={firstRangeInfo} />
          </div>
          <div className="DeviceSelectHelpText" style={{ marginLeft: "5px", display: "flex", paddingRight: "120px" }} >
            <DeviceLineSelectContainer callback={firstCallback} />
            {/* {firstIsLoading && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "10px" }}><Spinner animation="border" variant="secondary" /></div>} */}
          </div>
        </div>

        <div id="PeopleCountView-SecondGraph" style={{ justifyContent: "flex-start", display: "flex", }} hidden={!compareEnabled}>
          <div style={{
            marginLeft: "4.4rem",
            display: "flex",
            alignItems: "center"
          }}>
            <span style={{ fontWeight: "bold" }}>グラフ２</span>
            <DropdownOfYear setRangeInfo={setSecondRangeInfo} rangeInfo={secondRangeInfo} />
            <DropdownOfMonth setRangeInfo={setSecondRangeInfo} rangeInfo={secondRangeInfo} />
            <DropdownOfDay setRangeInfo={setSecondRangeInfo} rangeInfo={secondRangeInfo} />
            <DropdownOfHour setRangeInfo={setSecondRangeInfo} rangeInfo={secondRangeInfo} />
          </div>
          <div style={{ marginLeft: "5px", display: "flex" }}>
            <DeviceLineSelectContainer callback={secondCallback} />
            {/* {secondIsLoading && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "10px" }}><Spinner animation="border" variant="secondary" /></div>} */}
          </div>
        </div>


      </div>
      <div style={{ backgroundColor: "white" }}>
        <PeopleCountChart
          compareEnabled={compareEnabled}
          activeTabKey={activeTabKey}
          csvExportLogic={csvExportLogic}
          imageExportLogic={imageExportLogic}

          firstIsLoading={firstIsLoading}
          firstRangeInfo={firstRangeInfo}
          firstChartData={firstChartData}
          firstDevices={firstDevices}
          firstLines={firstLineInfos}

          secondIsLoading={secondIsLoading}
          secondRangeInfo={secondRangeInfo}
          secondChartData={secondChartData}
          secondDevices={secondDevices}
          secondLines={secondLineInfos}
        />
      </div>
    </div>
  );
}
