import React, { useEffect, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { RangeInfo } from './types';
import { GetRangeArray } from './common';

type DropdownOfMonthProps = {
    rangeInfo: RangeInfo
    setRangeInfo: React.Dispatch<React.SetStateAction<RangeInfo>>;
}

export const DropdownOfMonth: React.FC<DropdownOfMonthProps> = ({ setRangeInfo, rangeInfo }) => {

    const items = GetRangeArray(12).map((x) => x + 1);

    const now = new Date()

    const [disabled, setDisabled] = useState(true)

    const [labelMonth, setLabelMonth] = useState<string | null>(null);

    const onSelector = (month: string | null) => {

        if (!month) {
            return
        }

        setLabelMonth(month);
        setRangeInfo((prev) => {
            return {
                ...prev,
                month: parseInt(month)
            };
        });
    };

    useEffect(() => {
        if (rangeInfo.month && labelMonth !== rangeInfo.month.toString()) {
            const newLabel = rangeInfo.month.toString()
            setLabelMonth(newLabel)
        }

        if (rangeInfo.rangeType && ["月", "日", "時"].includes(rangeInfo.rangeType)) {
            setDisabled(false)
        } else if (!disabled) {
            setDisabled(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeInfo])



    return (
        <Dropdown className="mx-2" as={ButtonGroup} onSelect={onSelector} style={{ width: "72px" }}>
            <Dropdown.Toggle variant="secondary" id="DropdownMonth" disabled={disabled}>
                {labelMonth ? labelMonth + "月" : "月"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {items.map((item) => (
                    <Dropdown.Item key={item} eventKey={item} disabled={now.getFullYear() === rangeInfo.year && now.getMonth() + 1 < item}>{item}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
