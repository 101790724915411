import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../../constant";

const rotate360 = keyframes`
0% {
  box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
5%,
95% {
  box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
10%,
59% {
  box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
}
20% {
  box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
}
38% {
  box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
}
100% {
  box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
`;
const round = keyframes`
0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
`;
const Wrapper = styled.div`
  color: #e5b1b1;
  margin: auto;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${rotate360} 1.7s infinite ease,
    ${round} 1.7s infinite ease;
  animation: ${rotate360} 1.7s infinite ease, ${round} 1.7s infinite ease;
`;
const siginUrl = process.env.REACT_APP_SIGIN_URL || "";

export const Login: React.FC = () => {
  useEffect(() => {
    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop.toString()),
    });
    if (params.access_token && params.refresh_token) {
      localStorage.setItem(ACCESS_TOKEN_KEY, params.access_token);
      localStorage.setItem(REFRESH_TOKEN_KEY, params.refresh_token);
      window.location.href = `${window.origin}/dashboard/detection-history`;
    } else {
      window.location.href = siginUrl;
    }
  }, []);

  return <Wrapper id="loginLoading">Loading...</Wrapper>;
};
