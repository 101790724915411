import React from "react";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Camera, NotificationScheduleFE } from "../../../store/types/models";
import { NotificationEmailGroup } from "./email-notification/NotificationEmailGroup";
import { NOTIFICATION_INPUT_NUM } from "./email-notification/utils";
import DataBasicForm, { InputMetadata } from "./type";
import { v4 as uuidv4 } from "uuid";
import { handleErrorNotification } from "../../../utils";

type Props = {
  setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
  dataForm: DataBasicForm;
  cameraIndex: number;
  camera: Camera | undefined;
  firstCamera: Camera | undefined;
};

const detailSettings: InputMetadata[] = [
  {
    label: "パトランプ連携URL",
    name: "patrolUrl",
  },
  {
    label: "通知間隔(秒)",
    name: "notificationInterval",
  },
];

const radioSettings = [
  {
    label: "通知機能",
    name: "alert",
  },
  {
    label: "検知動画格納",
    name: "movie",
  },
];

export const DetailSetting: React.FC<Props> = ({
  setDataForm,
  dataForm,
  cameraIndex,
  camera,
  firstCamera,
}) => {

  const renderTooltipInterval = (props: any) => (
    <Tooltip id="button-tooltip-interval" {...props}>
      15~600の範囲で1刻みで入力してください.
      そうでない場合、デフォルト値は15です。
    </Tooltip>
  );

  const cloneCamera = (cam: Camera | undefined): DataBasicForm => {
    const notificationEmails = cam?.config?.notification_emails || [];
    const notificationSchedules: NotificationScheduleFE[] = cam?.config?.notification_schedules?.map(
      schedule => ({
        enabled: schedule.enabled,
        rules: schedule.rules.map(rule => ({ ...rule, reactId: uuidv4() }))
      })) || [];

    return {
      ...dataForm,
      movie: cam?.config?.movie || false,
      alert: cam?.config?.alert || false,
      patrolUrl: cam?.config?.patrol_url || "",
      notification_emails: Array.from({ length: 5 }, (_, i) => notificationEmails[i] ? notificationEmails[i] : ""),
      notification_schedules: Array.from({ length: NOTIFICATION_INPUT_NUM }, (_, i) => notificationSchedules[i] ? notificationSchedules[i] : { enabled: false, rules: [] }),
      notificationInterval:
        cam?.config?.notification_interval?.toString() || "15",
      scheduleEnabled: cam?.config?.schedule_enabled || false,
      schedule: cam?.config?.schedule,
    };
  };

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <div className="row row-cols-2">
        {cameraIndex !== 1 && (
          <div
            className="col-6"
            style={{
              display: "flex",
              padding: "0.5rem 2rem",
            }}
          >
            <Form.Check
              id="isCloneConfig"
              type="checkbox"
              checked={dataForm.isCloneConfig}
              label="カメラ１の設定を引き継ぐ"
              onChange={(e) => {
                if (!firstCamera || !firstCamera.config) {
                  handleErrorNotification("カメラ１がまだ設定されていません")
                  return
                }
                if (dataForm.isCloneConfig) {
                  setDataForm({
                    ...cloneCamera(camera),
                    isCloneConfig: !dataForm.isCloneConfig,
                  });
                } else {
                  setDataForm({
                    ...cloneCamera(firstCamera),
                    isCloneConfig: !dataForm.isCloneConfig,
                  });
                }
              }}
            />
          </div>
        )}
      </div>

      <div className="row row-cols-2">
        <div className="col-6">
          <div className="row row-cols-1">
            {detailSettings.map((item) => {
              if (item.name === "notificationInterval") {
                return (
                  <div
                    key={item.name}
                    className="col"
                    style={{
                      display: "flex",
                      padding: "0.5rem 2rem",
                      justifyContent: "space-around",
                    }}
                  >
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipInterval}
                    >
                      <InputGroup className="mb-3">
                        <InputGroup.Text
                          id="basic-addon1"
                          style={{ width: "30%" }}
                        >
                          {item.label}
                        </InputGroup.Text>
                        <FormControl
                          disabled={dataForm.isCloneConfig}
                          id={item.name}
                          value={dataForm.notificationInterval}
                          onChange={(e) => {
                            const value = parseInt(e.target.value) || 0;
                            const re = /^[0-9\b]*$/;

                            if (value <= 600 && re.test(e.target.value)) {
                              return setDataForm({
                                ...dataForm,
                                notificationInterval: e.target.value,
                              });
                            }
                          }}
                          onBlur={(e) => {
                            if (
                              !e.target.value ||
                              Number.parseInt(e.target.value, 10) < 15
                            )
                              return setDataForm({
                                ...dataForm,
                                notificationInterval: "15",
                              });
                          }}
                          style={{ width: "70%" }}
                          placeholder={item.label}
                          aria-label={item.label}
                          aria-describedby="basic-addon1"
                          min={15}
                          max={600}
                          step={1}
                        />
                      </InputGroup>
                    </OverlayTrigger>
                  </div>
                );
              }
              return (
                <div
                  key={item.name}
                  className="col"
                  style={{
                    display: "flex",
                    padding: "0.5rem 2rem",
                    justifyContent: "space-around",
                  }}
                >
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1" style={{ width: "30%" }}>
                      {item.label}
                    </InputGroup.Text>
                    <FormControl
                      disabled={dataForm.isCloneConfig}
                      id={item.name}
                      value={
                        dataForm[
                          `${item.name as keyof DataBasicForm}`
                        ]?.toString() || ""
                      }
                      onChange={(e) => {
                        setDataForm((prev) => {
                          return { ...prev, [item.name]: e.target.value };
                        });
                      }}
                      style={{ width: "70%" }}
                      placeholder={"自由入力"}
                      aria-label={item.label}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-6">
          <div className="row row-cols-1">
            <div className="col">
              {radioSettings.map((item) => {
                return (
                  <div
                    key={item.name}
                    className="col-12"
                    style={{
                      display: "flex",
                      padding: "0.5rem 2rem",
                      fontSize: "1rem",
                    }}
                  >
                    <div className="col-4">{item.label}</div>
                    <div>
                      <Button
                        disabled={
                          dataForm.isCloneConfig ||
                          (item.name !== "alert" && !dataForm.alert)
                        }
                        id={`${item.name}On`}
                        style={{ padding: "0 1.5rem", marginRight: "1rem" }}
                        variant={
                          dataForm[item.name as keyof DataBasicForm]
                            ? "primary"
                            : "outline-primary"
                        }
                        onClick={() =>
                          setDataForm((prev) => {
                            return {
                              ...prev,
                              [item.name as keyof DataBasicForm]: true,
                            };
                          })
                        }
                      >
                        ON
                      </Button>
                      <Button
                        disabled={dataForm.isCloneConfig}
                        id={`${item.name}Off`}
                        style={{ padding: "0 1.5rem" }}
                        variant={
                          !dataForm[item.name as keyof DataBasicForm]
                            ? "danger"
                            : "outline-danger"
                        }
                        onClick={() => {
                          if (item.name === "alert") {
                            setDataForm((prev) => {
                              return {
                                ...prev,
                                alert: false,
                                scheduleEnabled: false,
                                movie: false,
                              };
                            });
                          } else {
                            setDataForm((prev) => {
                              return {
                                ...prev,
                                [item.name]: false,
                              };
                            });
                          }
                        }}
                      >
                        OFF
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <hr className="my-4"></hr>
      <div style={{ paddingLeft: "1.5rem" }}>
        <NotificationEmailGroup
          dataForm={dataForm}
          setDataForm={setDataForm}
        />
      </div>
    </div>
  );
};
