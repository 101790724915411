import { AxiosInstance, AxiosPromise } from "axios"
import { ChartDataAPIParams } from "../../routes/DashBoard/crowd-detection/types";
import { getChartDataAux } from "./CrowdDetection";
export type AttributeAnalysisType = {
    getChartData(params: ChartDataAPIParams): AxiosPromise
}

export const AttributeAnalysisMethod = (
    request: AxiosInstance
): AttributeAnalysisType => ({
    getChartData(params) {
        return getChartDataAux("analysis", request, params)
    }
})