import { toast } from "react-toastify";
import dayjs from "dayjs";

export const handleErrorNotification = (detail?: any) => {
  if (Array.isArray(detail))
    return toast.error(
        `${detail[0].msg || "内部サーバーエラーが発生しました!"}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
    );
  return toast.error(`${detail || "内部サーバーエラーが発生しました!"}`, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const handleSuccessNotification = (text: string) => {
  return toast.success(`${text}`, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const handleFormatDate = (date: Date) =>
  dayjs(date).format("YYYY-MM-DD HH:mm");
