/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDropDown, FilterAlt } from '@mui/icons-material';
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { saveAs } from "file-saver";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Badge,
  Button,
  Image,
  Modal,
  Overlay,
  Popover,
  Table
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import Lightbox from "react-image-lightbox";
import styled from "styled-components";
import API from "../../api";
import useAPIService from "../../api/useAPIService";
import Logo from "../../components/Logo";
import { ResponseStatus } from "../../constant";
import { AuthContext } from "../../contexts/AuthContext";
import { Incident } from "../../store/types/models";
import { handleErrorNotification, handleFormatDate } from "../../utils";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
const Heading = styled.div`
  padding-top: 1rem;
  margin: 0 2rem;
  display: flex;
  align-items: center;
`;

const PageHeadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const PageHeading = styled.h3`
  display: flex;
  align-items: center;
`;
const ContentWrapper = styled.div`
  padding: 2rem;
  flex: 1;
  /*min-width: 1800px;*/
`;

const BorderBagde = styled.div`
  padding: 0.75rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 10px;
`;

const ButtonTd = styled.td``;
const ButtonCell = styled.div`
  display: flex;
  justify-content: center;
`;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 600, //maxHeight以上項目があるとスクロールバーが追加される
      // width: 100   //クリック時の横幅：設定しないと自動調整される
    }
  }
};

const MENU_HEIGHT = "32px"

type csvIncidentDataType = (string | undefined)[][]

export const DetectionHistory: React.FC = () => {
  const {
    invoke: getIncidents,
    data: dataListIncidents,
    isLoading: getLoading,
    status: getIncidentStatus,
    error: getIncidentError,
  } = useAPIService<{
    data: Incident[];
    pagination: { skip: number; limit: number; total: number };
  }>(API.DetectionHistory.getIncidents);
  const { user } = React.useContext(AuthContext);
  const {
    invoke: updateDetectionHistory,
    status: updateDetectionHistoryStatus,
    isLoading: updateLoading,
    error: updateDetectionError,
  } = useAPIService(API.DetectionHistory.updateIncident);

  const { invoke: countIncident, data: incidentToday } = useAPIService(
    API.DetectionHistory.countIncident
  );

  const { invoke: countIncidentInMonth, data: incidentInMonth } = useAPIService(
    API.DetectionHistory.countIncident
  );
  const countIncidents = React.useCallback(() => {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    const firstDateInMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    countIncident(today.toISOString());
    countIncidentInMonth(firstDateInMonth.toISOString());
  }, [countIncident, countIncidentInMonth]);
  React.useEffect(() => {
    if (user) {
      try {
        const ws = new WebSocket(
          `${process.env.REACT_APP_WEBSOCKET_URL?.toString() || ""}/${user.tenant_id
          }`
        );
        ws.onopen = function (event) {
          console.log("[websocket]: connected.");
        };
        ws.onmessage = function (event) {
          console.log("[websocket]: received message.");
          getIncidents();
          countIncidents();
        };

        return () => {
          ws.close();
        };
      } catch (err) {
        console.log(err);
        const logData = {
          level: "ERROR",
          message:
            err instanceof Error
              ? err.message
              : "内部サーバーエラーが発生しました!",
        };
        API.Log.clientLog(logData);
      }
    }
  }, [user]);

  React.useEffect(() => {
    if (getIncidentStatus === ResponseStatus.FAIL) {
      handleErrorNotification(getIncidentError?.response?.data?.detail);
    }
  }, [getIncidentStatus, getIncidentError]);

  React.useEffect(() => {
    getIncidents();
  }, [getIncidents]);

  React.useEffect(() => {
    countIncidents();
  }, [countIncidents]);

  React.useEffect(() => {
    if (updateDetectionHistoryStatus) {
      if (updateDetectionHistoryStatus === ResponseStatus.SUCCESS) {
        getIncidents();
      } else {
        handleErrorNotification(updateDetectionError?.response?.data?.detail);
      }
    }
  }, [updateDetectionHistoryStatus, getIncidents, updateDetectionError]);

  const [selectedItem, setSelectedItem] = useState<
    Incident | undefined
  >();
  const [selectedField, setSelectedField] = useState<
    string | undefined
  >();
  const [dataListView, setDataListView] = useState<Incident[]>([]);

  React.useEffect(() => {
    dataListIncidents && setDataListView(dataListIncidents.data);
  }, [dataListIncidents]);

  const [showOverlay, setShowOverlay] = useState(false);
  const [target, setTarget] = useState(null);
  const overlayRef = useRef(null);

  const [tempInputComment, setTempInputComment] = useState("");
  const [showModalImage, setShowModalImage] = useState(false);
  const [isOpenLightBox, setIsOpenLightBox] = useState(false);

  const [csvData, setCsvData] = useState<csvIncidentDataType>([])

  const [selectedDeviceNames, setSelectedDeviceNames] = useState<string[]>([]);
  const [selectedCameraNames, setSelectedCameraNames] = useState<string[]>([]);
  const [selectedDetectionTypes, setSelectedDetectionTypes] = useState<string[]>([]);
  const [selectedProblemStatus, setSelectedProblemStatus] = useState<string[]>([]);
  const [selectedCorrespondenceStatus, setSelectedCorrespondenceStatus] = useState<string[]>([]);
  const [filterDataListView, setFilterDataListView] = useState<Incident[]>([]);

  useEffect(() => {
    // 絞り込み中に新しいデータが来た場合を考慮して、絞り込み機能をここで呼び出して再計算する
    onCloseFilterDataListView();
  }, [dataListView]);

  const handleCommentClick = (event: any) => {
    setShowOverlay(true);
    setTarget(event.target);
    overlayRef.current = event.target
  };

  const handleClearPopover = () => {
    setTempInputComment("");
    setShowOverlay(false);
    setTarget(null);
    setSelectedField(undefined);
    setSelectedItem(undefined);
  };

  if (selectedItem) {
    const selectedElement = document.querySelector(`#incident_ui_item${selectedItem.id}`)
    if (!selectedElement) {
      handleClearPopover()
    }
  }

  const handleUpdateItem = async () => {
    if (!selectedItem || !selectedField) {
      return;
    }
    const dataSubmit = Object.keys(selectedItem).reduce((acc, key) => {
      if (key === "id") {
        return acc;
      }
      if (key === "comment" && selectedField === key) {
        return { ...acc, comment: tempInputComment };
      }
      if (
        (key === "is_issue" || key === "is_confirmed") &&
        selectedField === key
      ) {
        return {
          ...acc,
          [key]: !selectedItem[key],
        };
      }
      return { ...acc, [key]: selectedItem[key as keyof Incident] };
    }, {});
    await updateDetectionHistory({ id: selectedItem.id, body: dataSubmit });
    handleClearPopover();
  };

  const handleUpdateButton = async (key: string, item: Incident) => {
    const dataSubmit = Object.keys(item).reduce((acc, k) => {
      if (key === "id") {
        return acc;
      }
      if (k === key) {
        return {
          ...acc,
          [k]: !item[k as keyof Incident],
        };
      }
      return { ...acc, [k]: item[k as keyof Incident] };
    }, {});
    await updateDetectionHistory({ id: item.id, body: dataSubmit });
  };

  const saveFile = (item: Incident) => {
    if (item?.detection_video_url) {
      fetch(item.detection_video_url)
        .then((res) => res.blob())
        .then((blob) => {
          saveAs(blob, `${item.camera.name}-report.mp4`);
        });
    }
  };

  const renderOverlay = useMemo(() => {
    return (
      <Overlay
        show={showOverlay}
        target={target}
        container={overlayRef}
        placement="bottom"
        containerPadding={16}
      >
        <Popover>
          <Popover.Header>コメントを編集</Popover.Header>
          <Popover.Body style={{ padding: "0.5rem" }}>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <textarea
                id="commentAreaText"
                data-cy={"_history_commentAreaText"}
                rows={6}
                maxLength={100}
                value={tempInputComment}
                onChange={(e) => setTempInputComment(e.target.value)}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                id="confirmChangeComment"
                data-testid="_history_commentAreaTextSave"
                data-cy={"_history_commentAreaTextSave"}
                variant="primary"
                onClick={() => {
                  setDataListView((prev) => {
                    const idx = prev.findIndex(
                      (i) => i.id === selectedItem?.id
                    );
                    return [
                      ...prev.slice(0, idx),
                      { ...prev[idx], comment: tempInputComment },
                      ...prev.slice(idx + 1, prev.length),
                    ];
                  });
                  handleUpdateItem();
                }}
              >
                保存
              </Button>
              <Button
                id="clearPopoverButton"
                data-testid="_history_commentAreaTextCancel"
                data-cy={"_history_commentAreaTextCancel"}
                variant="secondary"
                onClick={() => handleClearPopover()}
              >
                キャンセル
              </Button>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    );
  }, [showOverlay, tempInputComment, target, dataListView]);

  // CSVデータの更新
  useEffect(() => {
    let csvData: csvIncidentDataType = [];

    // header処理
    const headers: csvIncidentDataType[0] = ["デバイス名", "カメラ名", "検知内容", "検知時間", "問題ステータス", "対応ステータス", "コメント", "対応者"];
    csvData.push(headers);

    const incidentDataArray: csvIncidentDataType = dataListView.map((item, index) => {
      const csvLine: csvIncidentDataType[0] = []
      csvLine.push(item.camera.device?.name);
      csvLine.push(item.camera.name);
      csvLine.push(item.detection_type);
      csvLine.push(item.capture_time ? handleFormatDate(new Date(item.capture_time)) : "");
      csvLine.push(item.is_issue ? "問題あり" : "問題なし");
      csvLine.push(item.is_confirmed ? "対応済" : "未対応");
      csvLine.push(item.comment ? item.comment.replace(/"/g, '""') : "");
      csvLine.push(item.updated_by);
      return csvLine;
    });

    csvData = csvData.concat(incidentDataArray);
    setCsvData(csvData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataListView]);

  // 100件のデータから重複を排除してデバイス名、カメラ名、検知内容を抽出する
  const deviceNameList = dataListView.map((item, index, self) => {
    if (self.findIndex((e) => e.camera.device?.name === item.camera.device?.name) === index && item.camera.device?.name !== undefined) {
      return item.camera.device.name
    } else {
      return ""
    }
  }).filter((item) => !(item === "")).sort((a, b) => { return a.localeCompare(b, 'ja'); });

  const cameraNameList = dataListView.map((item, index, self) => {

    if (self.findIndex((e) => e.camera.name === item.camera.name) === index) {
      return item.camera.name
    } else {
      return ""
    }
  }).filter((item) => !(item === "")).sort((a, b) => { return a.localeCompare(b, 'ja'); });

  const detectionTypeList = dataListView.map((item, index, self) => {

    if (self.findIndex((e) => e.detection_type === item.detection_type) === index && item.detection_type !== undefined) {
      return item.detection_type
    } else {
      return ""
    }
  }).filter((item) => !(item === "")).sort((a, b) => { return a.localeCompare(b, 'ja'); });

  const onChangeDevice = (event: SelectChangeEvent<unknown>) => {
    const newDeviceNames = event.target.value as string[];
    setSelectedDeviceNames(newDeviceNames);
  };

  const onChangeCamera = (event: SelectChangeEvent<unknown>) => {
    const newCameraNames = event.target.value as string[];
    setSelectedCameraNames(newCameraNames);
  };

  const onChangeDetectionTypes = (event: SelectChangeEvent<unknown>) => {
    const newDetectionTypes = event.target.value as string[];
    setSelectedDetectionTypes(newDetectionTypes);
  };

  const onChangeProblemStatus = (event: SelectChangeEvent<unknown>) => {
    const newProblemStatus = event.target.value as string[];
    setSelectedProblemStatus(newProblemStatus);
  };

  const onChangeCorrespondenceStatus = (event: SelectChangeEvent<unknown>) => {
    const newCorrespondenceStatus = event.target.value as string[];
    setSelectedCorrespondenceStatus(newCorrespondenceStatus);
  };

  const onCloseFilterDataListView = () => {
    let newFilterDataList: Incident[] = Object.create(dataListView);

    if (selectedDeviceNames.length !== 0) {
      newFilterDataList = newFilterDataList.filter((item: Incident) => selectedDeviceNames.includes(item.camera.device?.name !== undefined ? item.camera.device.name : ""));
    }

    if (selectedCameraNames.length !== 0) {
      newFilterDataList = newFilterDataList.filter((item: Incident) => selectedCameraNames.includes(item.camera.name));
    }

    if (selectedDetectionTypes.length !== 0) {
      newFilterDataList = newFilterDataList.filter((item: Incident) => selectedDetectionTypes.includes(item.detection_type !== undefined ? item.detection_type : ""));
    }

    if (selectedProblemStatus.length !== 0) {
      newFilterDataList = newFilterDataList.filter((item: Incident) => selectedProblemStatus.includes(item.is_issue ? "問題あり" : "問題なし"));
    }

    if (selectedCorrespondenceStatus.length !== 0) {
      newFilterDataList = newFilterDataList.filter((item: Incident) => selectedCorrespondenceStatus.includes(item.is_confirmed ? "対応済" : "未対応"));
    }

    setFilterDataListView(newFilterDataList)
  }

  return (
    <Wrapper>
      <Heading id="detection-header" data-cy="detection-header">
        <Logo />
      </Heading>
      <ContentWrapper>
        <div
          style={{
            padding: "1rem",
            backgroundColor: "#fff",
            borderRadius: 10,
          }}
        >
          <PageHeadingWrap>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <PageHeading>検知履歴</PageHeading>
              <BorderBagde data-cy={"_date_summary_label"}>
                本日の検知件数{" "}
                <Badge bg="danger" style={{ marginLeft: 4 }} data-cy={"_date_summary_value"}>
                  {incidentToday}件
                </Badge>
              </BorderBagde>
              <BorderBagde
                style={{
                  marginLeft: "1rem",
                }}
                data-cy={"_month_summary_label"}
              >
                今月の累計検知件数
                <Badge bg="danger" style={{ marginLeft: 4 }} data-cy={"_month_summary_value"}>
                  {incidentInMonth}件
                </Badge>
              </BorderBagde>
            </div>
            <CSVLink
              id="DetectionHistory-CsvExportButton"
              className="btn btn-primary"
              target="_blank"
              filename="検知結果"
              data={csvData}
            >
              CSVエクスポート
            </CSVLink>
          </PageHeadingWrap>
          <Table className="table table-camera-list" style={{ width: "100%" }}>
            <thead className="table-primary">
              <tr>
                <th style={{ width: "10%" }}></th>
                <th data-cy={"_history_header_tenant"} style={{ width: "10%" }}>
                  <FormControl sx={{ m: 0, p: 0 }} size="small">
                    <Select
                      labelId="device-filter-checkbox-label"
                      id="device-filter-checkbox"
                      data-testid="device-filter-Select"
                      sx={{
                        "& .MuiSelect-select": {
                          margin: 0,
                          padding: 0
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none"
                        }
                      }}
                      multiple
                      displayEmpty={true}
                      value={selectedDeviceNames}
                      IconComponent={selectedDeviceNames.length > 0 ? FilterAlt : ArrowDropDown}
                      onChange={onChangeDevice}
                      onOpen={() => { setShowOverlay(false) }}
                      onClose={onCloseFilterDataListView}
                      renderValue={() => (
                        <span style={{ fontWeight: "bold" }}>
                          デバイス名
                        </span>
                      )}
                      MenuProps={MenuProps}
                    >
                      {deviceNameList.map((deviceName, i) => {
                        return (
                          <MenuItem key={deviceName + String(i)} value={deviceName} style={{ height: MENU_HEIGHT }}>
                            <Checkbox
                              checked={
                                !!selectedDeviceNames.find((x) => x === deviceName)
                              }
                            />
                            <ListItemText primary={deviceName} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </th>
                <th data-cy={"_history_header_camera"} style={{ width: "10%" }}>
                  <FormControl sx={{ m: 0, p: 0 }} size="small">
                    <Select
                      labelId="camera-filter-checkbox-label"
                      id="camera-filter-checkbox"
                      data-testid="camera-filter-Select"
                      sx={{
                        "& .MuiSelect-select": {
                          margin: 0,
                          padding: 0
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none"
                        }
                      }}
                      multiple
                      displayEmpty={true}
                      value={selectedCameraNames}
                      IconComponent={selectedCameraNames.length > 0 ? FilterAlt : ArrowDropDown}
                      onChange={onChangeCamera}
                      onOpen={() => { setShowOverlay(false) }}
                      onClose={onCloseFilterDataListView}
                      renderValue={() => (
                        <span style={{ fontWeight: "bold" }}>
                          カメラ名
                        </span>
                      )}
                      MenuProps={MenuProps}
                    >
                      {cameraNameList.map((cameraName, i) => {
                        return (
                          <MenuItem key={cameraName + String(i)} value={cameraName} style={{ height: MENU_HEIGHT }}>
                            <Checkbox
                              checked={
                                !!selectedCameraNames.find((x) => x === cameraName)
                              }
                            />
                            <ListItemText primary={cameraName} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </th>
                <th data-cy={"_history_header_detail"} style={{ width: "10%" }}>
                  <FormControl sx={{ m: 0, p: 0 }} size="small">
                    <Select
                      labelId="detectionType-filter-checkbox-label"
                      id="detectionType-filter-checkbox"
                      data-testid="detectionType-filter-Select"
                      sx={{
                        "& .MuiSelect-select": {
                          margin: 0,
                          padding: 0
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none"
                        }
                      }}
                      multiple
                      displayEmpty={true}
                      value={selectedDetectionTypes}
                      IconComponent={selectedDetectionTypes.length > 0 ? FilterAlt : ArrowDropDown}
                      onChange={onChangeDetectionTypes}
                      onOpen={() => { setShowOverlay(false) }}
                      onClose={onCloseFilterDataListView}
                      renderValue={() => (
                        <span style={{ fontWeight: "bold" }}>
                          検知内容
                        </span>
                      )}
                      MenuProps={MenuProps}
                    >
                      {detectionTypeList.map((detectionType, i) => {
                        return (
                          <MenuItem key={detectionType + String(i)} value={detectionType} style={{ height: MENU_HEIGHT }}>
                            <Checkbox
                              checked={
                                !!selectedDetectionTypes.find((x) => x === detectionType)
                              }
                            />
                            <ListItemText primary={detectionType} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </th>
                <th data-cy={"_history_header_time"} style={{ width: "10%" }}>
                  検知時間
                </th>
                <th
                  data-cy={"_history_header_problem_status"}
                  style={{ width: "10%", textAlign: "center" }}
                >
                  <FormControl sx={{ m: 0, p: 0 }} size="small">
                    <Select
                      labelId="problemStatus-filter-checkbox-label"
                      id="problemStatus-filter-checkbox"
                      data-testid="problemStatus-filter-Select"
                      sx={{
                        "& .MuiSelect-select": {
                          margin: 0,
                          padding: 0
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none"
                        }
                      }}
                      multiple
                      displayEmpty={true}
                      value={selectedProblemStatus}
                      IconComponent={selectedProblemStatus.length > 0 ? FilterAlt : ArrowDropDown}
                      onChange={onChangeProblemStatus}
                      onOpen={() => { setShowOverlay(false) }}
                      onClose={onCloseFilterDataListView}
                      renderValue={() => (
                        <span style={{ fontWeight: "bold" }}>
                          問題<span className="sp-text">ステータス</span>
                        </span>
                      )}
                      MenuProps={MenuProps}
                    >
                      {["問題なし", "問題あり"].map((problemStatus, i) => {
                        return (
                          <MenuItem key={problemStatus + String(i)} value={problemStatus} style={{ height: MENU_HEIGHT }}>
                            <Checkbox
                              checked={
                                !!selectedProblemStatus.find((x) => x === problemStatus)
                              }
                            />
                            <ListItemText primary={problemStatus} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </th>
                <th
                  data-cy={"_history_header_correspondence_status"}
                  style={{ width: "10%", textAlign: "center" }}
                >
                  <FormControl sx={{ m: 0, p: 0 }} size="small">
                    <Select
                      labelId="correspondenceStatus-filter-checkbox-label"
                      id="correspondenceStatus-filter-checkbox"
                      data-testid="correspondenceStatus-filter-Select"
                      sx={{
                        "& .MuiSelect-select": {
                          margin: 0,
                          padding: 0
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none"
                        }
                      }}
                      multiple
                      displayEmpty={true}
                      value={selectedCorrespondenceStatus}
                      IconComponent={selectedCorrespondenceStatus.length > 0 ? FilterAlt : ArrowDropDown}
                      onChange={onChangeCorrespondenceStatus}
                      onOpen={() => { setShowOverlay(false) }}
                      onClose={onCloseFilterDataListView}
                      renderValue={() => (
                        <span style={{ fontWeight: "bold" }}>
                          対応<span className="sp-text">ステータス</span>
                        </span>
                      )}
                      MenuProps={MenuProps}
                    >
                      {["未対応", "対応済"].map((correspondenceStatus, i) => {
                        return (
                          <MenuItem key={correspondenceStatus + String(i)} value={correspondenceStatus} style={{ height: MENU_HEIGHT }}>
                            <Checkbox
                              checked={
                                !!selectedCorrespondenceStatus.find((x) => x === correspondenceStatus)
                              }
                            />
                            <ListItemText primary={correspondenceStatus} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </th>
                <th
                  data-cy={"_history_header_comment"}
                  style={{ width: "10%" }}
                >
                  コメント
                </th>
                <th
                  data-cy={"_history_header_updated_by"}
                  style={{ width: "10%" }}
                >
                  対応者
                </th>

                <th
                  data-cy={"_history_header_action"}
                  style={{ width: "10%", textAlign: "center" }}
                >
                  動画<span className="sp-text">ダウンロード</span>
                </th>
              </tr>
            </thead>
            {showOverlay && renderOverlay}
            <tbody data-cy={"_history_items"}>
              {filterDataListView &&
                filterDataListView.map((item, index) => {
                  const date = item.capture_time
                    ? new Date(item.capture_time)
                    : "";

                  const dateString = date ? handleFormatDate(date) : "";

                  const showComment = item?.comment?.split("\n");
                  const img = (
                    <img
                      id="history_image"
                      data-testid="history_image"
                      style={{ cursor: "pointer" }}
                      src={item.detection_image_url}
                      alt=""
                      width={200}
                      height={100}
                      onClick={() => {
                        handleClearPopover()
                        setSelectedItem(item);
                        setShowModalImage(true);
                      }}
                    />
                  );

                  return (
                    <tr
                      id={"incident_ui_item" + item.id}
                      key={"incident_ui_item" + item.id} data-cy={"_history_item"}>
                      <td style={{ width: "10%" }} data-cy={"_history_item_thumbnail"}>
                        {!item?.camera?.config?.movie ? (
                          <span>No Image</span>
                        ) : (
                          img
                        )}
                      </td>
                      <td style={{ width: "10%" }}>
                        {item.camera.device?.name}
                      </td>
                      <td style={{ width: "10%" }}>{item.camera.name}</td>
                      <td style={{ width: "10%" }}>{item.detection_type}</td>
                      <td data-cy={"_history_item_time"} style={{ width: "10%" }}>{dateString}</td>
                      <ButtonTd style={{ width: "9%" }}>
                        <ButtonCell>
                          <Button
                            id="buttonIssue"
                            data-cy={"_history_item_problem"}
                            variant={
                              item.is_issue
                                ? "danger"
                                : "outline-primary btn-outline-primary-cus"
                            }
                            disabled={getLoading || updateLoading}
                            onClick={(e) => {
                              handleClearPopover()
                              setDataListView((prev) => {
                                const idx = prev.findIndex(
                                  (i) => i.id === item.id
                                );
                                return [
                                  ...prev.slice(0, idx),
                                  {
                                    ...prev[idx],
                                    is_issue: !prev[idx].is_issue,
                                  },
                                  ...prev.slice(idx + 1, prev.length),
                                ];
                              });
                              handleUpdateButton("is_issue", item);
                            }}
                            style={{ cursor: "pointer", padding: "0.375rem" }}
                          >
                            {item.is_issue ? "問題あり" : "問題なし"}
                          </Button>
                        </ButtonCell>
                      </ButtonTd>
                      <ButtonTd style={{ width: "9%" }}>
                        <ButtonCell>
                          <Button
                            id="buttonIsconfirm"
                            data-cy={"_history_item_process"}
                            disabled={getLoading || updateLoading}
                            variant={
                              item.is_confirmed
                                ? "outline-warning btn-outline-warning-cus"
                                : "secondary"
                            }
                            onClick={(e) => {
                              handleClearPopover()
                              setDataListView((prev) => {
                                const idx = prev.findIndex(
                                  (i) => i.id === item.id
                                );
                                return [
                                  ...prev.slice(0, idx),
                                  {
                                    ...prev[idx],
                                    is_confirmed: !prev[idx]?.is_confirmed,
                                  },
                                  ...prev.slice(idx + 1, prev.length),
                                ];
                              });
                              handleUpdateButton("is_confirmed", item);
                            }}
                            style={{ cursor: "pointer", padding: "0.375rem" }}
                          >
                            {item.is_confirmed ? "対応済" : "未対応"}
                          </Button>
                        </ButtonCell>
                      </ButtonTd>
                      <td
                        style={{
                          width: "10%",
                          position: "relative",
                        }}
                        data-cy={"_history_comment"}
                      >
                        <div ref={overlayRef}
                          id="commentArea"
                          data-testid="_history_commentArea"
                          onClick={(e) => {
                            if (getLoading || updateLoading) return
                            setSelectedItem(item);
                            setSelectedField("comment");
                            handleCommentClick(e);
                            setTempInputComment(item.comment || "");
                          }}
                          style={{
                            width: "200px",
                            height: "85px",
                            cursor: "pointer",
                            padding: "0.5rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            border: "2px #435ebf solid",
                          }}
                        >
                          {showComment?.map((i, idx) => {
                            return (
                              <p
                                key={idx}
                                style={{
                                  width: 200,
                                  margin: 0,
                                }}
                              >
                                {i}
                                <br />
                              </p>
                            );
                          })}
                        </div>
                      </td>
                      <td style={{ width: "10%" }} data-cy={"_history_updated_by"} >
                        <span className="update-by">{item.updated_by}</span>
                      </td>

                      <ButtonTd style={{ width: "12%" }}>
                        <ButtonCell>
                          <Button
                            id="saveFileButton"
                            variant={
                              item?.camera?.config?.movie
                                ? "primary"
                                : "secondary"
                            }
                            disabled={!item?.camera?.config?.movie}
                            onClick={() => {
                              handleClearPopover()
                              saveFile(item)
                            }}
                            style={{ padding: "0.375rem" }}
                          >
                            <i
                              className="bi bi-download"
                              style={{ marginRight: 4 }}
                            />
                            <span className="sp-text">ダウンロード</span>
                          </Button>
                        </ButtonCell>
                      </ButtonTd>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </ContentWrapper>
      <Modal
        size="lg"
        show={showModalImage}
        onHide={() => setShowModalImage(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ animation: "fadeIn 2s linear" }}>
          {selectedItem && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Image
                id="thumbnail_view"
                src={selectedItem.detection_image_url}
                rounded
                thumbnail
                height="auto"
                style={{ cursor: "pointer", flex: 1 }}
                data-cy={"_history_modal_image"}
                onClick={() => setIsOpenLightBox(true)}
              />
            </div>
          )}
          {isOpenLightBox && selectedItem?.detection_image_url && (
            <div data-cy="_history_lightbox_modal">
              <Lightbox
                mainSrc={selectedItem.detection_image_url}
                onCloseRequest={() => {
                  setShowModalImage(false);
                  setIsOpenLightBox(false);
                }}
                reactModalStyle={{ overlay: { zIndex: 1100 } }}
                wrapperClassName="_data-cy_history_scalable_image"
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div data-cy="_history_modal_device">
            {selectedItem?.camera.device?.name} -
          </div>
          <div data-cy="_history_modal_camera">{selectedItem?.camera.name}</div>
          <div data-cy="_history_modal_content">
            {selectedItem?.detection_type}
          </div>
          <div data-cy="_history_modal_time">
            {selectedItem?.capture_time
              ? handleFormatDate(new Date(selectedItem.capture_time))
              : ""}
          </div>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};
