import { AxiosPromise } from "axios";
import Axios from 'axios';
import React from "react";
import { ResponseStatus } from "../constant";
import API from "./index";
type useAPIServiceType<T> = {
  data: T | undefined;
  isLoading: boolean;
  error: any
  invoke: (...param: any) => Promise<T | undefined>;
  status?: string;
};
export default function useAPIService<T>(
  functionService: (...param: any) => AxiosPromise<T>
): useAPIServiceType<T> {
  const [data, setData] = React.useState<T | undefined>();
  const [error, setError] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<ResponseStatus | undefined>();
  const invoke = React.useCallback(
    async (...param: any) => {
      try {
        setIsLoading(true);
        const res = await functionService(...param);
        setData(res.data);
        setStatus(ResponseStatus.SUCCESS);
        return res.data
      } catch (e) {
        let errorMessage;
        let level = 'ERROR';
        if (Axios.isAxiosError(e) && e.response) {
          errorMessage = `${e.response.status}: ${e.message}`;
          if (e.response.status >= 500) level = 'WARN';
          else if (e.response.status >= 400) level = 'INFO';
        } else {
          errorMessage = e instanceof Error ? e.message : 'API呼び出し時に予期せぬエラーが発生しました';
        }
        setError(e);
        setStatus(ResponseStatus.FAIL);
        API.Log.clientLog({
          level,
          message: `Client Error: error=[${errorMessage}]`,
        });
      } finally {
        setIsLoading(false);
        setStatus(undefined);
      }
    },
    [functionService]
  );
  return { invoke, data, error, isLoading, status };
}
