import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-lightbox/style.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import "./App.css";
import { ACCESS_TOKEN_KEY } from "./constant";
import { AuthProvider } from "./contexts/AuthContext";
import { DashBoard } from "./routes/DashBoard";
import { Login } from "./routes/Login";
toast.configure();

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  background: #2F2F2F;
  overflow-y: hidden;
`;
const App: React.FC = () => {
  const hasAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  return (
    <Wrapper>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/authenticated" element={<Login />} />
            <Route path="/dashboard/*" element={<DashBoard />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    hasAccessToken
                      ? "dashboard/detection-history"
                      : "authenticated"
                  }
                  replace
                />
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </Wrapper>
  );
};

export default App;
