import React, { useEffect, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { RangeInfo } from './types';
import { GetAllDaysInMonth } from './common';

type DropdownOfDayProp = {
    rangeInfo: RangeInfo;
    setRangeInfo: React.Dispatch<React.SetStateAction<RangeInfo>>;
}

export const DropdownOfDay: React.FC<DropdownOfDayProp> = ({ rangeInfo, setRangeInfo }) => {

    let items: string[];
    if (rangeInfo.year && rangeInfo.month) {
        items = GetAllDaysInMonth(rangeInfo.year, rangeInfo.month).map((x) => x.getDate().toString());
    } else {
        items = ["なし"]
    }

    const now = new Date()

    const [disabled, setDisabled] = useState(true)

    const [labelDay, setLabelDay] = useState<string | null>(null);

    const onSelector = (day: string | null) => {

        if (!day) {
            return
        }

        setLabelDay(day);
        setRangeInfo((prev) => {
            return {
                ...prev,
                day: parseInt(day)
            };
        });
    };


    useEffect(() => {
        if (rangeInfo.day && labelDay !== rangeInfo.day.toString()) {
            const newLabel = rangeInfo.day.toString()
            setLabelDay(newLabel)
        }

        if (rangeInfo.rangeType && ["日", "時"].includes(rangeInfo.rangeType)) {
            setDisabled(false)
        } else if (!disabled) {
            setDisabled(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeInfo])


    return (
        <Dropdown className="mx-2" as={ButtonGroup} onSelect={onSelector} style={{ width: "72px" }}>
            <Dropdown.Toggle variant="secondary" id="DropdownDay" disabled={disabled}>
                {labelDay ? labelDay + "日" : "日"}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ maxHeight: "500px", overflowY: "auto" }}>
                {items.map((item) => (
                    <Dropdown.Item key={item} eventKey={item} disabled={now.getFullYear() === rangeInfo.year && now.getMonth() + 1 === rangeInfo.month && now.getDate() < parseInt(item)}>{item}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

