import React, { createContext, useRef, useState } from "react";
import { LineCross } from "../../../../store/types/models";
import DataBasicForm from "../type";
import { LINELAYER_ZINDEX_DOWN } from "./LineCrossLayer";


export type LineCrossContextProps = {
  setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
  lineLayerZindex: number;
  setlineLayerZindex: React.Dispatch<React.SetStateAction<number>>;
  selectLineCross: (index: number | null) => void;
  removeLineCross: (index: number, setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>) => void;
  selectedLineIndex: number | null;
  lineCrossesData: (LineCross | null)[];
  setLineCrossData: (i: number, line: LineCross, setDataForm: null | React.Dispatch<React.SetStateAction<DataBasicForm>>) => any;
  setLineCrossesData: React.Dispatch<React.SetStateAction<(LineCross | null)[]>>;
  isCreatingRef: React.MutableRefObject<boolean>;
};

export const LineCrossContext = createContext<LineCrossContextProps | null>(null);
type LineProviderType = {
  dataForm: DataBasicForm;
  children: React.ReactNode;
  setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>;
};
export const LineProvider = ({ children, dataForm, setDataForm }: LineProviderType) => {
  const [selectedLineIndex, setSelectedLineIndex] = useState<number | null>(null);

  const [lineCrossesData, setLineCrossesData] = useState<(LineCross | null)[]>(dataForm.line_crosses);

  const removeLineCross = (index: number, setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>) => {
    if (lineCrossesData[index] !== null) {
      selectLineCross(null)
      setLineCrossData(index, null, setDataForm);
    }
  };

  const setLineCrossData = (i: number, line: LineCross | null, setDataForm: null | React.Dispatch<React.SetStateAction<DataBasicForm>>) => {
    const newData = lineCrossesData.concat();
    newData[i] = line;
    if (setDataForm !== null) {
      setDataForm((prev: any) => {
        return {
          ...prev,
          line_crosses: newData
        };
      });
    }
    setLineCrossesData(newData);
  };

  const selectLineCross = (index: number | null) => {
    if (selectedLineIndex !== index) {
      setSelectedLineIndex(index);
    } else if (selectedLineIndex === null) {
      setSelectedLineIndex(null);
    }
  };

  const [lineLayerZindex, setlineLayerZindex] = useState(LINELAYER_ZINDEX_DOWN);

  const isCreatingRef = useRef(false);

  const value: LineCrossContextProps = {
    setDataForm,
    isCreatingRef,
    lineLayerZindex,
    setlineLayerZindex,
    selectedLineIndex,
    lineCrossesData,
    setLineCrossesData,
    removeLineCross,
    setLineCrossData,
    selectLineCross
  };
  return <LineCrossContext.Provider value={value}>{children}</LineCrossContext.Provider>;
};
