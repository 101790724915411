import { AxiosInstance, AxiosPromise } from "axios";
import { Camera, CameraCreate, CameraUpdate } from "../../store/types/models";
export type CamerasType = {
  getAllCameras(): AxiosPromise;
  createCameras(params: CameraCreate): AxiosPromise;
  updateCameraDetail(params: { body: CameraUpdate; id: string }): AxiosPromise<Camera>;
  deleteCamera(id: string): AxiosPromise;
  captureImage(id: string): AxiosPromise;
};
export const CamerasMethod = (request: AxiosInstance): CamerasType => ({
  getAllCameras() {
    return request.get(`/cameras/`);
  },
  createCameras(params) {
    return request.post(`/cameras`, params);
  },
  updateCameraDetail(params) {
    return request.put(`/cameras/${params.id}`, params.body);
  },
  captureImage(id) {
    return request.get(`/cameras/${id}/capture`);
  },
  deleteCamera(id) {
    return request.delete(`/cameras/${id}`);
  },
});
