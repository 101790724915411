import { IRect } from "konva/lib/types";
import { useRef, useState, useEffect } from "react";
import { Group, Rect, Circle, Label, Tag, Text } from "react-konva";
import { LineCrossPoint, LineCross, CropArea, CropAreaPoint } from "../../../../store/types/models";
import DataBasicForm from "../type";
import { CANVAS_WIDTH, CANVAS_HEIGHT, clamp, pullBackGroupPosition } from "./LineCrossLayer";

const CROP_RECT_STROKE_WIDTH = 3
const CROP_RECT_SELECT_COLOR = "yellow"
const CROP_RECT_COLOR = "black"
const CROP_CIRCLE_SELECT_COLOR = "red"
const CROP_CIRCLE_COLOR = "black"
const CROP_CIRCLE_RADIUS = 6
const CROP_SELECT_CIRCLE_RADIUS = 6
const CROP_CIRCLE_STROKE_WIDTH = 0
const CROP_CIRCLE_SELECT_STROKE_WIDTH = 0



export const dataFormCropArea = (data: CropArea): CropArea => {
    return {
        p1: {
            x: Math.min(data.p1.x, data.p2.x),
            y: Math.min(data.p1.y, data.p2.y)
        },
        p2: {
            x: Math.max(data.p1.x, data.p2.x),
            y: Math.max(data.p1.y, data.p2.y)
        }
    }
}

type KonvaCropCircleType = "LeftTop" | "LeftBottom" | "RightTop" | "RightBottom"

type KonvaCropAreaProps = {
    selected: boolean
    mousePosition: CropAreaPoint | null
    initialData: CropArea
    setDataForm: React.Dispatch<React.SetStateAction<DataBasicForm>>
    setCropAreaData: React.Dispatch<React.SetStateAction<CropArea | null>>
};

export const KonvaCropArea = ({ selected, initialData, mousePosition, setDataForm, setCropAreaData }: KonvaCropAreaProps) => {
    const startCircleRef = useRef<any>(null);
    const endCircleRef = useRef<any>(null);

    const [startCircle, setStartCircle] = useState<LineCrossPoint>(initialData.p1);
    const [endCircle, setEndCircle] = useState<LineCrossPoint>(initialData.p2);

    useEffect(() => {
        if (mousePosition !== null) {
            const newData = { ...mousePosition }
            setEndCircle(newData)
        }
    }, [mousePosition])

    const getRectWidth = () => {
        return Math.abs(endCircle.x - startCircle.x + 1)
    }

    const getRectHeight = () => {
        return Math.abs(endCircle.y - startCircle.y + 1)
    }

    const [isCircleDragging, setIsCircleDragging] = useState<KonvaCropCircleType | null>(null)

    const absolutePosition = (): LineCross => {
        const startPoint = startCircleRef.current.absolutePosition()
        const endPoint = endCircleRef.current.absolutePosition()
        return {
            p1: startPoint,
            p2: endPoint
        }
    }

    const onGroupDragMove = (e: any) => {
        if (e.target.getClassName() === 'Group') {

            const group = e.target
            const cropPosition = absolutePosition()
            const cropRect: IRect = {
                x: Math.min(cropPosition.p1.x, cropPosition.p2.x),
                y: Math.min(cropPosition.p1.y, cropPosition.p2.y),
                width: Math.abs(cropPosition.p1.x - cropPosition.p2.x),
                height: Math.abs(cropPosition.p1.y - cropPosition.p2.y),
            }
            const groupPosition = group.getAbsolutePosition();
            group.setAbsolutePosition(pullBackGroupPosition(cropRect, groupPosition))
        }
    }

    const cropAreaAbsolutePosition = (): CropArea => {
        const startPoint = startCircleRef.current.absolutePosition()
        const endPoint = endCircleRef.current.absolutePosition()
        return {
            p1: startPoint,
            p2: endPoint
        }
    }

    const onCircleDragStart = (e: any, circleType: KonvaCropCircleType) => {
        setIsCircleDragging(circleType)
    }

    const onCircleDragMove = (e: any, circleType: KonvaCropCircleType) => {
        const relativeX = e.target.attrs.x;
        const relativeY = e.target.attrs.y;

        const absoluteX = e.target.absolutePosition().x;
        const absoluteY = e.target.absolutePosition().y;

        e.target.absolutePosition({
            x: clamp(absoluteX, 0, CANVAS_WIDTH),
            y: clamp(absoluteY, 0, CANVAS_HEIGHT)
        });

        const group = e.target.findAncestors('Group')[0]
        const groupPosition = group.getAbsolutePosition();

        if (circleType === "LeftTop") {
            setStartCircle({
                x: clamp(relativeX, 0 - groupPosition.x, CANVAS_WIDTH - groupPosition.x),
                y: clamp(relativeY, 0 - groupPosition.y, CANVAS_HEIGHT - groupPosition.y)
            });

        } else if (circleType === "RightTop") {
            setStartCircle({
                x: startCircle.x,
                y: clamp(relativeY, 0 - groupPosition.y, CANVAS_HEIGHT - groupPosition.y),
            });
            setEndCircle({
                x: clamp(relativeX, 0 - groupPosition.x, CANVAS_WIDTH - groupPosition.x),
                y: endCircle.y
            });
        } else if (circleType === "LeftBottom") {
            setStartCircle({
                x: clamp(relativeX, 0 - groupPosition.x, CANVAS_WIDTH - groupPosition.x),
                y: startCircle.y,
            });
            setEndCircle({
                x: endCircle.x,
                y: clamp(relativeY, 0 - groupPosition.y, CANVAS_HEIGHT - groupPosition.y)
            });
        } else if (circleType === "RightBottom") {
            setEndCircle({
                x: clamp(relativeX, 0 - groupPosition.x, CANVAS_WIDTH - groupPosition.x),
                y: clamp(relativeY, 0 - groupPosition.y, CANVAS_HEIGHT - groupPosition.y)
            });
        }
    }

    const onCircleDragEnd = (e: any, circleType: KonvaCropCircleType) => {
        setIsCircleDragging(null)
        const data = cropAreaAbsolutePosition()
        setCropAreaData(data)
        setDataForm((prev) => ({
            ...prev,
            crop_area: dataFormCropArea(data)
        }))
    }

    return <Group
        draggable={selected}
        onDragMove={(e) => onGroupDragMove(e)}
        onDragEnd={(e) => {
            const data = cropAreaAbsolutePosition()
            setCropAreaData(data)
            setDataForm((prev) => ({
                ...prev,
                crop_area: dataFormCropArea(data)
            }))
        }}
    >
        <Label
            x={startCircle.x < endCircle.x ? startCircle.x : endCircle.x}
            y={startCircle.y < endCircle.y ? startCircle.y : endCircle.y}
            offsetY={-CROP_RECT_STROKE_WIDTH / 2}
        >
            <Tag
                fill={"gray"}
                opacity={1}
                pointerWidth={40}
                pointerDirection="none"
            />
            <Text
                text={" クロップエリア "}
                fontFamily="Calibri"
                fontSize={11}
                padding={1}
                fill="white"
                align="center"
            />
        </Label>
        <Rect
            x={startCircle.x < endCircle.x ? startCircle.x : endCircle.x}
            y={startCircle.y < endCircle.y ? startCircle.y : endCircle.y}
            draggable={false}
            width={getRectWidth()}
            height={getRectHeight()}
            strokeWidth={CROP_RECT_STROKE_WIDTH}
            stroke={selected ? CROP_RECT_SELECT_COLOR : CROP_RECT_COLOR}
        />
        <Circle
            ref={startCircleRef}
            x={startCircle.x}
            y={startCircle.y}
            onDragStart={(e) => { onCircleDragStart(e, "LeftTop") }}
            onDragMove={(e) => onCircleDragMove(e, "LeftTop")}
            onDragEnd={(e) => onCircleDragEnd(e, "LeftTop")}
            stroke={selected ? CROP_CIRCLE_SELECT_COLOR : CROP_CIRCLE_COLOR}
            strokeWidth={selected ? CROP_CIRCLE_SELECT_STROKE_WIDTH : CROP_CIRCLE_STROKE_WIDTH}
            draggable={selected}
            radius={CROP_CIRCLE_RADIUS}
            opacity={isCircleDragging === "LeftTop" ? 0.5 : 1}
            fill={selected ? CROP_CIRCLE_SELECT_COLOR : CROP_CIRCLE_COLOR}
        />
        <Circle
            x={endCircle.x}
            y={startCircle.y}
            onDragStart={(e) => { onCircleDragStart(e, "RightTop") }}
            onDragMove={(e) => onCircleDragMove(e, "RightTop")}
            onDragEnd={(e) => onCircleDragEnd(e, "RightTop")}
            stroke={selected ? CROP_CIRCLE_SELECT_COLOR : CROP_CIRCLE_COLOR}
            strokeWidth={selected ? CROP_CIRCLE_SELECT_STROKE_WIDTH : CROP_CIRCLE_STROKE_WIDTH}
            draggable={selected}
            radius={CROP_CIRCLE_RADIUS}
            opacity={isCircleDragging === "RightTop" ? 0.5 : 1}
            fill={selected ? CROP_CIRCLE_SELECT_COLOR : CROP_CIRCLE_COLOR}
        />
        <Circle
            x={startCircle.x}
            y={endCircle.y}
            onDragStart={(e) => { onCircleDragStart(e, "LeftBottom") }}
            onDragMove={(e) => onCircleDragMove(e, "LeftBottom")}
            onDragEnd={(e) => onCircleDragEnd(e, "LeftBottom")}
            stroke={selected ? CROP_CIRCLE_SELECT_COLOR : CROP_CIRCLE_COLOR}
            strokeWidth={selected ? CROP_CIRCLE_SELECT_STROKE_WIDTH : CROP_CIRCLE_STROKE_WIDTH}
            draggable={selected}
            radius={CROP_CIRCLE_RADIUS}
            opacity={isCircleDragging === "LeftBottom" ? 0.5 : 1}
            fill={selected ? CROP_CIRCLE_SELECT_COLOR : CROP_CIRCLE_COLOR}
        />
        <Circle
            ref={endCircleRef}
            x={endCircle.x}
            y={endCircle.y}
            onDragStart={(e) => { onCircleDragStart(e, "RightBottom") }}
            onDragMove={(e) => onCircleDragMove(e, "RightBottom")}
            onDragEnd={(e) => onCircleDragEnd(e, "RightBottom")}
            stroke={selected ? CROP_CIRCLE_SELECT_COLOR : CROP_CIRCLE_COLOR}
            strokeWidth={selected ? CROP_CIRCLE_SELECT_STROKE_WIDTH : CROP_CIRCLE_STROKE_WIDTH}
            draggable={selected}
            radius={selected ? CROP_SELECT_CIRCLE_RADIUS : CROP_CIRCLE_RADIUS}
            opacity={isCircleDragging === "RightBottom" ? 0.5 : 1}
            fill={selected ? CROP_CIRCLE_SELECT_COLOR : CROP_CIRCLE_COLOR}
        />
    </Group>
}