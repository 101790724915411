import React from "react";
import { Button, Table, Modal } from "react-bootstrap";
import styled from "styled-components";
import API from "../../api";
import useAPIService from "../../api/useAPIService";
import { LoadingComponent } from "../../components/LoadingLayout";
import { PermissionLayout } from "../../components/PermissionLayout";
import { ResponseStatus } from "../../constant";
import { AuthContext } from "../../contexts/AuthContext";
import { Camera, UserRole } from "../../store/types/models";
import { handleErrorNotification } from "../../utils";
import { ConfigCamera } from "./config-camera/ConfigCamera";
import Logo from "../../components/Logo";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
const Heading = styled.div`
  padding-top: 1rem;
  margin: 0 2rem;
  display: flex;
  align-items: center;
`;
const PageHeadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const PageHeading = styled.h3`
  display: flex;
  align-items: center;
`;
const ContentWrapper = styled.div`
  padding: 2rem;
  flex: 1;
  max-width: 1700px;
  min-width: 700px;
`;

export const CameraSettings: React.FC = () => {
  const { user } = React.useContext(AuthContext);
  const [currentScreen, setCurrentScreen] = React.useState(1);
  const [selectedItem, setSelectedItem] = React.useState<Camera | undefined>();
  const [isRefreshCamera, setRefreshCamera] = React.useState(false);
  const contentWrapperRef = React.useRef<HTMLDivElement>(null);

  const [changeFlg, setChangeFlg] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const {
    invoke: getAllCamera,
    data: dataListCamera,
    status: getAllCameraStatus,
    isLoading,
    error: getAllCameraError,
  } = useAPIService<{
    data: Camera[];
    pagination: { skip: number; limit: number; total: number };
  }>(API.Cameras.getAllCameras);

  React.useEffect(() => {
    getAllCamera();
  }, [getAllCamera]);

  React.useEffect(() => {
    if (getAllCameraStatus === ResponseStatus.FAIL) {
      handleErrorNotification(getAllCameraError?.response?.data?.detail);
    }
  }, [getAllCameraStatus, getAllCameraError]);

  const scrollTop = () => {
    contentWrapperRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };
  const isInListCameraScreen = currentScreen === 1;
  const listCamera = dataListCamera?.data || [];
  const renderScreen = () => {
    return isInListCameraScreen ? (
      renderTable()
    ) : (
      <ConfigCamera
        firstCamera={listCamera.find(
          (item) =>
            item.device_id === selectedItem?.device_id &&
            item.camera_index === 1
        )}
        backToScreen={(refresh) => {
          setCurrentScreen(1);
          setSelectedItem(undefined);
          if (refresh) {
            getAllCamera();
          }
        }}
        loadAllCamera={async () => {
          setRefreshCamera(true);
          await getAllCamera();
          setRefreshCamera(false);
        }}
        scrollTop={scrollTop}
        data={selectedItem}
        setChangeFlag={(isChangeFlag) => {
          setChangeFlg(isChangeFlag);
        }}
      />
    );
  };

  const renderTable = () => {
    return (
      <>
        <div
          className="list-setting"
          style={{
            padding: "1rem",
            backgroundColor: "#fff",
            borderRadius: 10,
          }}
        >
          <PageHeadingWrap>
            <PageHeading>カメラ設定</PageHeading>
          </PageHeadingWrap>
          <Table style={{ width: "100%" }}>
            <thead className="table-primary">
              <tr>
                <th>No</th>
                <th>デバイス名</th>
                <th>カメラ番号</th>
                <th>カメラ名</th>
                <th style={{ textAlign: "center" }}>編集</th>
              </tr>
            </thead>
            <tbody>
              {listCamera.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.device?.name}</td>
                    <td>{`カメラ${item.camera_index}`}</td>
                    <td>{item.name}</td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        id="editCameraConfig"
                        data-cy={"_editCameraConfig" + (index + 1)}
                        variant="primary"
                        onClick={() => {
                          setSelectedItem(item);
                          setCurrentScreen(2);
                        }}
                      >
                        編集
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  if (user?.role === UserRole.OPERATOR) {
    return <PermissionLayout />;
  }

  return (
    <Wrapper>
      <Heading>
        {isInListCameraScreen ? (
          <div style={{ flex: 1, display: "flex", fontWeight: "bold" }}>
            <Logo />
          </div>
        ) : (
          <div>
            <Button
              id="backButton"
              style={{ background: "#435ebf", marginRight: 10 }}
              onClick={() => {
                if (changeFlg) {
                  setShowModal(true);
                } else {
                  setCurrentScreen(1);
                  setSelectedItem(undefined);
                  setChangeFlg(false);
                }
              }}
            >
              <i className="bi bi-arrow-left" style={{ marginRight: 4 }} />
              戻る
            </Button>
          </div>
        )}
      </Heading>
      <ContentWrapper ref={contentWrapperRef}>
        {isLoading && !isRefreshCamera ? <LoadingComponent /> : renderScreen()}
      </ContentWrapper>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>設定を保存せずに戻りますか。</Modal.Body>
        <Modal.Footer>
          <Button
            id="backModal"
            variant="primary"
            onClick={() => {
              setCurrentScreen(1);
              setSelectedItem(undefined);
              setChangeFlg(false);
              handleCloseModal();
            }}
          >
            はい
          </Button>
          <Button
            id="closeModal"
            variant="secondary"
            onClick={handleCloseModal}
          >
            いいえ
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};
