import objectHash from "object-hash";
import React, { useState } from "react";
import { Button, Form, Spinner, Modal } from "react-bootstrap";
import styled from "styled-components";
import API from "../../../api";
import useAPIService from "../../../api/useAPIService";
import { ResponseStatus } from "../../../constant";
import { Camera, DayName, Device, NotificationRule, NotificationScheduleBE, NotificationScheduleFE } from "../../../store/types/models";
import {
  handleErrorNotification,
  handleSuccessNotification,
} from "../../../utils";
import { ToTruthValue, ValidateNotificationEmail } from "../../../utils/common";
import { AreaSetting } from "./AreaSetting";
import { BasicInfor } from "./BasicInfor";
import { DetailSetting } from "./DetailSetting";
import DataBasicForm from "./type";
import { encode as base64_encode } from "base-64";
import { LineProvider } from "./linecross-layer/LineCrossContext";
import { CropAreaProvider } from "./linecross-layer/CropAreaContext";
import { NOTIFICATION_INPUT_NUM, validateNotificationData } from "./email-notification/utils";
import { v4 as uuidv4 } from "uuid";
import { CANVAS_HEIGHT, CANVAS_WIDTH, clamp } from "./linecross-layer/LineCrossLayer";


const dayNameToNumber = (x: DayName | null): number => {
  if (x === "Sun") return 0
  else if (x === "Mon") return 1
  else if (x === "Tue") return 2
  else if (x === "Wed") return 3
  else if (x === "Thu") return 4
  else if (x === "Fri") return 5
  else if (x === "Sat") return 6
  else return -1
}

type AreaDetectionGetDataStore = {
  "points": {
    "id": string,
    "x": number,
    "y": number,
    "isSelected": boolean
  }[]
}[]

/**canvas外の座標をcanvas内の座標にmutableに引き戻す */
export const trimAreas = (areas: AreaDetectionGetDataStore): AreaDetectionGetDataStore => {
  for (const area of areas) {
    for (const point of area.points) {
      point.x = clamp(point.x, 0, CANVAS_WIDTH)
      point.y = clamp(point.y, 0, CANVAS_HEIGHT)
    }
  }
  return areas
}

/**曜日,活性,開始時刻,終了時刻の順にソートする関数 */
export const sortNotificationRule = (x: NotificationRule, y: NotificationRule): 0 | -1 | 1 => {

  if (dayNameToNumber(x.dayName) === dayNameToNumber(y.dayName)) {
    if (x.rule_enable === y.rule_enable) {
      if (x.startTime === y.startTime) {
        if (x.endTime === y.endTime) {
          return 0
        } else if (x.endTime !== null && y.endTime !== null && x.endTime < y.endTime) {
          return -1
        } else {
          return 1
        }
      } else if (x.startTime !== null && y.startTime !== null && x.startTime < y.startTime) {
        return -1
      } else {
        return 1
      }
    } else if (x.rule_enable < y.rule_enable) {
      return -1
    } else {
      return 1
    }
  } else if (dayNameToNumber(x.dayName) < dayNameToNumber(y.dayName)) {
    return -1
  } else if (dayNameToNumber(x.dayName) > dayNameToNumber(y.dayName)) {
    return 1
  } else {
    throw new Error("sortNotificationRule error");
  }
}

/**NotificationRuleのreactIdが保存データのハッシュ値に反映しないように取り除く関数 */
const objectHashNotificationSchedules = (notification_schedules: NotificationScheduleFE[]): string => {
  const array = notification_schedules.map(schedule =>
  ({
    ...schedule,
    rules: schedule.rules.map(rule => {
      const cloned: Partial<NotificationRule> = { ...rule }
      delete cloned.reactId
      return cloned
    })
  })
  )
  return objectHash(array)
}

const ConfigWrapper = styled.div<{ firstElement?: boolean }>`
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  margin-top: ${(props) => (props.firstElement ? "0" : "1.5rem")};
`;

const PageHeadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
`;

const PageHeading = styled.h3`
  display: flex;
  align-items: center;
`;

type Props = {
  backToScreen: (refresh?: boolean) => void;
  data: Camera | undefined;
  firstCamera: Camera | undefined;
  scrollTop: () => void;
  loadAllCamera: () => Promise<void>;
  setChangeFlag: (isChangeFlag: boolean) => void;
};

export const ConfigCamera: React.FC<Props> = ({
  loadAllCamera,
  backToScreen,
  scrollTop,
  data,
  firstCamera,
  setChangeFlag,
}) => {
  const formatValueString = (val: string | undefined) => {
    return val || "";
  };
  const formatValueBoolean = (val: boolean | undefined) => {
    return val || false;
  };
  const [showModal, setShowModal] = React.useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [isSaving, setSaving] = useState(false);
  const areaDetection = React.useRef<any | null>(null);
  const {
    invoke: getAllDevices,
    error: getDevicesError,
    status: getDeviceStatus,
  } = useAPIService<{
    data: Device[];
    pagination: { skip: number; limit: number; total: number };
  }>(API.Devices.getAllDevices);

  React.useEffect(() => {
    getAllDevices();
  }, [getAllDevices]);

  const convertToDataForm = (camera: Camera | undefined): DataBasicForm => {
    const notificationEmails = camera?.config?.notification_emails || [];
    const notificationSchedules: NotificationScheduleFE[] = camera?.config?.notification_schedules?.map(
      schedule => ({
        enabled: schedule.enabled,
        rules: schedule.rules.map(rule => ({ ...rule, reactId: uuidv4() }))
      })) || [];

    return {
      deviceName: formatValueString(camera?.device?.name),
      deviceID: formatValueString(camera?.device?.id),
      cameraName: formatValueString(camera?.name),
      cameraID: formatValueString(camera?.id),
      rtspUrl: formatValueString(camera?.config?.rtsp_url),
      notification_emails: Array.from({ length: NOTIFICATION_INPUT_NUM }, (_, i) => notificationEmails[i] ? notificationEmails[i] : ""),
      notification_schedules: Array.from({ length: NOTIFICATION_INPUT_NUM }, (_, i) => notificationSchedules[i] ? notificationSchedules[i] : { enabled: false, rules: [] }),
      notificationInterval: camera?.config?.notification_interval?.toString() || "15",
      movie: formatValueBoolean(camera?.config?.movie),
      alert: formatValueBoolean(camera?.config?.alert),
      scheduleEnabled: false, //AIコンテナ側で処理される曜日毎のスケジュール機能は常にfalseとする。
      schedule: camera?.config?.schedule || undefined,
      ai_threshold_first: formatValueString(
        camera?.config?.ai_threshold_first?.toString()
      ),
      ai_threshold_second: formatValueString(
        camera?.config?.ai_threshold_second?.toString()
      ),
      patrolUrl: camera?.config?.patrol_url || "",
      isCloneConfig: camera?.config?.is_clone_config || false,
      lastCaptureImage: formatValueString(camera?.last_capture_image),
      area: camera?.config?.area || [], //TODO:エリアに関する課題管理表の修正すべき箇所：.map(x => ({ ...x }))
      list_threshold_1: (camera?.config?.list_threshold_1 || []).join(";"),
      list_threshold_2: (camera?.config?.list_threshold_2 || []).join(";"),
      line_crosses: camera?.config?.line_crosses || [],
      crop_area: camera?.config?.crop_area || null
    };
  };

  const [dataForm, setDataForm] = React.useState<DataBasicForm>(
    convertToDataForm(data)
  );

  const [areaHash, setAreaHash] = React.useState<string>(
    objectHash(dataForm.area)
  );
  const [dataFormHash, setDataFormHash] = React.useState(
    objectHash({
      ...dataForm,
      area: areaHash,
      notification_schedules: objectHashNotificationSchedules(dataForm.notification_schedules)
    })
  );

  const [dataFormChangedHash, setDataFormChangedHash] =
    React.useState(dataFormHash);

  const lineIntersects = (
    a: number,
    b: number,
    c: number,
    d: number,
    p: number,
    q: number,
    r: number,
    s: number
  ) => {
    let det, gamma, lambda;
    det = (c - a) * (s - q) - (r - p) * (d - b);

    if (det === 0) {
      return false;
    } else {
      lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
      gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;

      return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
    }
  };

  const handleSaveData: React.FormEventHandler<HTMLFormElement> = async (
    event
  ) => {
    const form = event.currentTarget;
    event.preventDefault();

    const nonempty_notification_emails = dataForm.notification_emails.filter(Boolean)
    const correspond_notification_schedules =
      dataForm.notification_schedules.map((schedule, i) => [i, schedule]).filter((_, i) => dataForm.notification_emails[i]) as [number/**フォーム番号 */, NotificationScheduleFE][]

    if (!validateNotificationData(nonempty_notification_emails, correspond_notification_schedules))
      return false

    const correspond_notification_schedules_BE: NotificationScheduleBE[] =
      correspond_notification_schedules.map((schedulePair) => {
        return {
          ...schedulePair[1],
          rules: schedulePair[1].rules.sort(sortNotificationRule).map((rule) => {
            return {
              dayName: rule.dayName,
              startTime: rule.startTime,
              endTime: rule.endTime,
              rule_enable: rule.rule_enable
            }
          })
        }
      })

    if (
      form.checkValidity() === false ||
      !ValidateNotificationEmail(nonempty_notification_emails)
    ) {
      scrollTop();
      event.stopPropagation();
      return;
    }

    const interval = parseInt(dataForm.notificationInterval);

    const isDefined = (x: any) => x !== null && x !== undefined
    const lineCrossValidation = dataForm.line_crosses.every(input => {
      if (!input) return true
      const len = [input.p1.x, input.p1.y, input.p2.x, input.p2.y].filter(isDefined).length
      return len === 0 || len === 4
    })

    if (!lineCrossValidation) {
      handleErrorNotification("ラインクロス設定が不正です");
      return false;
    }

    const trimmedArea: AreaDetectionGetDataStore = areaDetection.current ? trimAreas(areaDetection.current.getDataStore()) : []

    const config = {
      ai_threshold_first: parseFloat(dataForm.ai_threshold_first),
      ai_threshold_second: parseFloat(dataForm.ai_threshold_second),
      notification_interval: interval >= 15 ? interval : 15,
      patrol_url: base64_encode(dataForm.patrolUrl),
      rtsp_url: dataForm.rtspUrl,
      alert: dataForm.alert,
      movie: dataForm.movie,
      notification_emails: nonempty_notification_emails,
      notification_schedules: correspond_notification_schedules_BE,
      schedule_enabled: dataForm.scheduleEnabled,
      schedule:
        dataForm.scheduleEnabled && !dataForm.isCloneConfig
          ? dataForm.schedule
          : undefined,
      is_clone_config: dataForm.isCloneConfig,
      area: trimmedArea,
      list_threshold_1: dataForm.list_threshold_1
        ? dataForm.list_threshold_1.split(";").map(ToTruthValue)
        : [],
      list_threshold_2: dataForm.list_threshold_2
        ? dataForm.list_threshold_2.split(";").map(ToTruthValue)
        : [],
      line_crosses: dataForm.line_crosses,
      crop_area: dataForm.crop_area
    };

    let g_drawValid = true;
    let areas = config.area;

    // for UT coverage
    if (areas.length === 0) {
      areas = require("./../../../jest/testData.json").dataCamera.data[0].config
        .area;
    }

    for (let key = 0; key < areas.length; key++) {
      const points = areas[key].points;
      const polygonLines = points;

      for (let i = 0; i < points.length - 1; i++) {
        const _currentLine = polygonLines[i];

        for (let j = 0; j < points.length - 1; j++) {
          const _checkLine = polygonLines[j];

          if (_currentLine.id === _checkLine.id) {
            continue;
          }

          if (
            lineIntersects(
              polygonLines[i].x,
              polygonLines[i].y,
              polygonLines[i + 1].x,
              polygonLines[i + 1].y,
              polygonLines[j].x,
              polygonLines[j].y,
              polygonLines[j + 1].x,
              polygonLines[j + 1].y
            )
          ) {
            g_drawValid = false;
          }
        }
      }

      for (let j = 0; j < points.length - 1; j++) {
        if (
          lineIntersects(
            polygonLines[points.length - 1].x,
            polygonLines[points.length - 1].y,
            polygonLines[0].x,
            polygonLines[0].y,
            polygonLines[j].x,
            polygonLines[j].y,
            polygonLines[j + 1].x,
            polygonLines[j + 1].y
          )
        ) {
          g_drawValid = false;
        }
      }
    }

    if (config.area.length === 0) {
      g_drawValid = true;
    }

    if (!g_drawValid) {
      handleErrorNotification("エリアの辺が交差しています");

      return false;
    }

    const isCropAreaValid = (): boolean => {
      const crop_area = dataForm.crop_area
      if (crop_area === null) {
        return true
      } else {
        return dataForm.line_crosses.every(lineCross => {
          if (lineCross === null) {
            return true
          } else {
            return [lineCross.p1, lineCross.p2].every(point => {
              const xValid = crop_area.p1.x <= point.x && point.x <= crop_area.p2.x
              const yValid = crop_area.p1.y <= point.y && point.y <= crop_area.p2.y
              return xValid && yValid
            })
          }
        })
      }
    }

    if (!isCropAreaValid()) {
      handleErrorNotification("クロップ領域内にラインクロスが収まっていません");
      return false
    }

    setSaving(true);
    const cameraUpdated = await updateCamera({
      id: dataForm.cameraID,
      body: {
        name: dataForm.cameraName,
        device_id: dataForm.deviceID,
        config: config,
      },
    });
    await loadAllCamera();

    if (cameraUpdated) setDataForm(convertToDataForm(cameraUpdated));

    setSaving(false);
    return false;
  };

  React.useEffect(() => {
    if (!isSaving) {
      setDataFormHash(dataFormChangedHash);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving])


  const {
    invoke: updateCamera,
    status: updateCameraStatus,
    error: updateCameraError,
  } = useAPIService(API.Cameras.updateCameraDetail);

  React.useEffect(() => {
    if (updateCameraStatus) {
      updateCameraStatus === ResponseStatus.SUCCESS
        ? handleSuccessNotification("カメラ設定が正常に更新されました")
        : handleErrorNotification(updateCameraError?.response?.data?.detail);
    }
  }, [updateCameraStatus, updateCameraError]);

  React.useEffect(() => {
    if (getDeviceStatus === ResponseStatus.FAIL) {
      handleErrorNotification(getDevicesError?.response?.data?.detail);
    }
  }, [getDeviceStatus, getDevicesError]);

  React.useEffect(() => {
    setDataFormChangedHash(objectHash({
      ...dataForm,
      area: areaHash,
      notification_schedules: objectHashNotificationSchedules(dataForm.notification_schedules)
    }));
  }, [dataForm, areaHash]);

  React.useEffect(() => {
    setChangeFlag(dataFormChangedHash !== dataFormHash);
  }, [dataFormChangedHash, dataFormHash, setChangeFlag]);

  const renderHeaderConfig = (text: string, width = "20%") => {
    return (
      <div
        style={{
          width: width,
          padding: "0.5rem",
          backgroundColor: "#dc3545",
          borderRadius: 10,
          color: "#fff",
          fontSize: "1.2rem",
        }}
      >
        <i className="bi bi-arrow-return-right" style={{ marginRight: 4 }} />
        <span id="subttl">{text}</span>
      </div>
    );
  };

  return (
    <Form
      style={{ display: "flex", flexDirection: "column" }}
      validated={false}
      onSubmit={handleSaveData}
      id="configCameraForm"
    >
      <PageHeadingWrap>
        <PageHeading>カメラ設定</PageHeading>
      </PageHeadingWrap>
      <ConfigWrapper firstElement={true}>
        {renderHeaderConfig("基本情報設定")}
        <BasicInfor dataForm={dataForm} setDataForm={setDataForm} />
      </ConfigWrapper>
      <ConfigWrapper>
        {renderHeaderConfig("詳細情報設定")}
        <DetailSetting
          firstCamera={firstCamera}
          dataForm={dataForm}
          setDataForm={setDataForm}
          cameraIndex={data?.camera_index || 1}
          camera={data}
        />
      </ConfigWrapper>
      <ConfigWrapper>
        {renderHeaderConfig("検知エリア・ラインクロス・クロップエリア設定", "480px")}
        <CropAreaProvider dataForm={dataForm} setDataForm={setDataForm}>
          <LineProvider dataForm={dataForm} setDataForm={setDataForm}>
            <AreaSetting
              dataForm={dataForm}
              setDataForm={setDataForm}
              areaDetection={areaDetection}
              setAreaHash={setAreaHash}
            /></LineProvider>
        </CropAreaProvider>
      </ConfigWrapper>
      <div
        style={{ padding: "1rem", display: "flex", justifyContent: "center" }}
      >
        <Button
          id={`buttonSaveSetting-${dataForm.cameraID}`}
          data-cy="_buttonSaveSetting"
          style={{ background: "#435ebe" }}
          type="submit"
          disabled={dataFormChangedHash === dataFormHash}
          className="btn btn-primary btn-save-camera"
        >
          {isSaving && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginRight: 4 }}
            />
          )}
          保存
        </Button>
        <Button
          id="buttonCancelSetting"
          data-cy="_buttonCancelSetting"
          variant="secondary"
          style={{ marginLeft: "2rem" }}
          onClick={() => {
            if (dataFormChangedHash === dataFormHash) {
              backToScreen();
            } else {
              setShowModal(true);
            }
          }}
        >
          戻る
        </Button>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>確認</Modal.Title>
          </Modal.Header>
          <Modal.Body>設定を保存せずに戻りますか。</Modal.Body>
          <Modal.Footer>
            <Button
              id="backModal"
              data-cy="_backModal"
              variant="primary"
              onClick={() => backToScreen()}
            >
              はい
            </Button>
            <Button
              id="closeModal"
              data-cy="_closeModal"
              variant="secondary"
              onClick={handleCloseModal}
            >
              いいえ
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Form>
  );
};
