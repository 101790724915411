import Axios from 'axios';
import React from "react";
import { ResponseStatus } from "../constant";
import API from "./index";
import { ChartDataAPIParams } from "../routes/DashBoard/crowd-detection/types";
import { CrowdDetectionType } from "./method/CrowdDetection";

type useAPIServiceType<T> = {
    data: T | undefined;
    isLoading: boolean;
    error: any
    invoke: (param: ChartDataAPIParams, abortSignal: AbortSignal) => Promise<T | undefined>;
    status?: string;
};
/** 人数カウントグラフでAbortSignal使う必要があったため、useAPIServiceを参考に応急処置的に作成 */
export default function useChartDataAPI<T>(
    functionService: CrowdDetectionType["getChartData"]
): useAPIServiceType<T> {
    const [data, setData] = React.useState<T | undefined>();
    const [error, setError] = React.useState<any>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [status, setStatus] = React.useState<ResponseStatus | undefined>();
    const invoke = React.useCallback(
        async (param: ChartDataAPIParams, abortSignal: AbortSignal) => {
            try {
                setIsLoading(true);
                const res = await functionService(param, abortSignal);
                setData(res.data);
                setStatus(ResponseStatus.SUCCESS);
                return res.data
            } catch (e) {
                let errorMessage;
                let level = 'ERROR';
                if (Axios.isAxiosError(e) && e.response) {
                    errorMessage = `${e.response.status}: ${e.message}`;
                    if (e.response.status >= 500) level = 'WARN';
                    else if (e.response.status >= 400) level = 'INFO';
                } else {
                    errorMessage = e instanceof Error ? e.message : 'API呼び出し時に予期せぬエラーが発生しました';
                }
                setError(e);
                setStatus(ResponseStatus.FAIL);
                API.Log.clientLog({
                    level,
                    message: `Client Error: error=[${errorMessage}]`,
                });
            } finally {
                setIsLoading(false);
                setStatus(undefined);
            }
        },
        [functionService]
    );
    return { invoke, data, error, isLoading, status };
}
