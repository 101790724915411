import { AxiosInstance, AxiosPromise } from "axios";
export type DevicesType = {
  getAllDevices(): AxiosPromise;
  getDeviceDetail(params: string): AxiosPromise;
  updateDevice(params: { id: string; name: string }): AxiosPromise;
  deleteDevice(params: string): AxiosPromise;
  createDevice(params: string): AxiosPromise;
};
export const DevicesMethod = (request: AxiosInstance): DevicesType => ({
  getAllDevices() {
    const linkAPI = `/devices/`;
    return request.get(linkAPI);
  },
  getDeviceDetail(params) {
    const linkAPI = `/devices/${params}`;
    return request.get(linkAPI);
  },
  updateDevice({ id, name }) {
    const linkAPI = `/devices/${id}`;
    return request.put(linkAPI, { name: name });
  },
  deleteDevice(params) {
    const linkAPI = `/devices/${params}`;
    return request.delete(linkAPI);
  },
  createDevice(params) {
    const linkAPI = `/devices/`;
    return request.post(linkAPI, { name: params });
  },
});
