import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListSubheader from "@mui/material/ListSubheader";
import { Camera, Device } from "../../../store/types/models";
import useAPIService from "../../../api/useAPIService";
import API from "../../../api";
import { handleErrorNotification } from "../../../utils";
import "./deviceSelectHelpText.css"


const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 600, //maxHeight以上項目があるとスクロールバーが追加される
            // width: 100   //クリック時の横幅：設定しないと自動調整される
        }
    }
};


const LIMIT_SELECT_DEVICE_NUM = 7;

const MENU_HEIGHT = "32px"

type DeviceItem = {
    onlyDevice: true,
    deviceName: string;
    deviceID: string;
}

type LineItem = {
    onlyDevice: false,
    deviceName: string;
    deviceID: string;
    cameraName: string;
    cameraID: string;
    lineNo: 1 | 2 | 3 | 4;
};

export type DeviceType = {
    deviceName: string;
    deviceID: string;
    cameras: CameraType[];
};
export type CameraType = {
    deviceID: string;
    cameraName: string;
    cameraID: string;
    lines: LineType[];
};

export type LineType = {
    no: 1 | 2 | 3 | 4;
    camera: CameraType;
};

export function numberAscendingCompare(a: number, b: number) {
    if (a < b) {
        return -1;
    } else if (a > b) {
        return 1;
    } else {
        return 0;
    }
}

export function stringAscendingCompare(a: string, b: string) {
    if (a < b) {
        return -1;
    } else if (a > b) {
        return 1;
    } else {
        return 0;
    }
}

type DeviceLineSelectProps = {
    deviceData: DeviceItem[]
    lineData: LineItem[];
    callback: (params: { devices: DeviceType[], lines: LineType[] }) => void
};
export const DeviceLineSelect = ({ deviceData, lineData, callback }: DeviceLineSelectProps) => {

    const [selectedDeviceIDs, setSelectedDeviceIDs] = React.useState<string[]>([]);
    const [selectedLineIDs, setSelectedLineIDs] = React.useState<string[]>([]);

    const allDevices: DeviceType[] = [];

    // lineDataをallDevicesに変換する処理
    lineData.forEach((data) => {
        const foundDevice = allDevices.find(
            (device) => device.deviceID === data.deviceID
        );
        if (foundDevice) {
            const foundCamera = foundDevice.cameras.find(
                (camera) => camera.cameraID === data.cameraID
            );
            if (foundCamera) {
                foundCamera.lines.push({ camera: foundCamera, no: data.lineNo });
            } else {
                const newCamera: CameraType = {
                    deviceID: foundDevice.deviceID,
                    cameraName: data.cameraName,
                    cameraID: data.cameraID,
                    lines: []
                }
                newCamera.lines.push({ camera: newCamera, no: data.lineNo })
                foundDevice.cameras.push(newCamera);
            }
        } else {
            const newDevice: DeviceType = {
                deviceName: data.deviceName,
                deviceID: data.deviceID,
                cameras: []
            };
            const newCamera: CameraType = {
                deviceID: newDevice.deviceID,
                cameraName: data.cameraName,
                cameraID: data.cameraID,
                lines: []
            }
            newCamera.lines.push({ camera: newCamera, no: data.lineNo })
            newDevice.cameras.push(newCamera);
            allDevices.push(newDevice);
        }
    });

    // カメラが設定されていないデバイスもallDevicesに登録
    deviceData.forEach((data) => {
        const foundDevice = allDevices.find(
            (device) => device.deviceID === data.deviceID
        );
        if (!foundDevice) {
            const newDevice: DeviceType = {
                deviceName: data.deviceName,
                deviceID: data.deviceID,
                cameras: []
            };
            allDevices.push(newDevice);
        }
    })

    allDevices.sort((a, b) => stringAscendingCompare(
        a.deviceName,
        b.deviceName
    ))

    allDevices.forEach(device => {
        device.cameras.sort((a, b) => stringAscendingCompare(a.cameraName, b.cameraName))
    })

    const lineUnicode = (line: LineType): string => {
        return line.camera.deviceID + line.camera.cameraID + line.no;
    };

    const deviceIDtoDevice = (deviceID: string): DeviceType => {
        return allDevices.find((x) => x.deviceID === deviceID)!
    };

    const lineIDtoLine = (lineID: string): LineType => {
        const cameras = allDevices.flatMap((x) => x.cameras);
        const lines = cameras.flatMap((c) => c.lines);
        return lines.find((c) => lineUnicode(c) === lineID)!
    };


    const onChangeDevice = (event: SelectChangeEvent<unknown>) => {
        const newDeviceIDs = event.target.value as string[];

        if (newDeviceIDs.length > LIMIT_SELECT_DEVICE_NUM) {
            handleErrorNotification(`選択できるデバイスは、最大${LIMIT_SELECT_DEVICE_NUM}台までとなっています。`)
            return
        }

        const unselectedDeviceIDs = selectedDeviceIDs.filter(
            (device) => !newDeviceIDs.find((x) => x === device)
        );

        const unselectedLines = unselectedDeviceIDs.flatMap((deviceID) =>
            deviceIDtoDevice(deviceID).cameras.flatMap((camera) =>
                camera.lines
            )
        );

        if (unselectedLines.length > 0) {
            const newLineIDs = selectedLineIDs.filter(
                (lineID) => !unselectedLines.find((x) => lineUnicode(x) === lineID)
            );
            setSelectedLineIDs(newLineIDs);
        }
        newDeviceIDs.sort((a, b) => stringAscendingCompare(deviceIDtoDevice(a).deviceName, deviceIDtoDevice(b).deviceName))
        setSelectedDeviceIDs(newDeviceIDs);
    };

    const onChangeLine = (event: SelectChangeEvent<unknown>) => {
        const newLineIDs = event.target.value as string[];
        setSelectedLineIDs(newLineIDs);
    };

    const lineDisplayString = (lineID: string): string => {
        const line = lineIDtoLine(lineID);
        const camera = line.camera;
        return `${camera.cameraName} : ライン${line.no}`;
    };

    return (
        <div style={{ display: "flex" }}>
            <div>
                <FormControl sx={{ m: 1, width: 280 }} size="small">
                    <InputLabel
                        id="device-multiple-checkbox-label"
                        shrink={true}
                    >
                        {""}
                    </InputLabel>
                    <Select
                        labelId="device-multiple-checkbox-label"
                        id="device-multiple-checkbox"
                        data-testid="device-Select"
                        multiple
                        displayEmpty={true}
                        value={selectedDeviceIDs}
                        onChange={onChangeDevice}
                        onClose={() => {
                            callback({
                                devices: selectedDeviceIDs.map(d => deviceIDtoDevice(d)),
                                lines: selectedLineIDs.map(l => lineIDtoLine(l))
                            })
                        }}
                        input={<OutlinedInput label="" />}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return "デバイス未選択";
                            } else if (selected.length === 1) {
                                return deviceIDtoDevice(selected[0]).deviceName;
                            } else {
                                return "デバイス複数選択中";
                            }
                        }}
                        MenuProps={MenuProps}
                    >
                        {allDevices.map((device, i) => {
                            const deviceName = device.deviceName;
                            const deviceID = device.deviceID;
                            return (
                                <MenuItem key={deviceID + i} value={device.deviceID} style={{ height: MENU_HEIGHT }}>
                                    <Checkbox
                                        checked={
                                            !!selectedDeviceIDs.find((x) => x === device.deviceID)
                                        }
                                    />
                                    <ListItemText primary={deviceName} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl sx={{ m: 1, width: 280 }} size="small">
                    <InputLabel
                        id="camera-multiple-checkbox-label"
                        shrink={true}
                    >
                        {""}
                    </InputLabel>
                    <Select
                        disabled={selectedDeviceIDs.length === 0}
                        labelId="camera-multiple-checkbox-label"
                        id="camera-multiple-checkbox"
                        data-testid="camera-Select"
                        multiple
                        displayEmpty={true}
                        value={selectedLineIDs}
                        onChange={onChangeLine}
                        onClose={() => {
                            callback({
                                devices: selectedDeviceIDs.map(d => deviceIDtoDevice(d)),
                                lines: selectedLineIDs.map(l => lineIDtoLine(l))
                            })
                        }}
                        input={<OutlinedInput label="" />}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return "カメラ未選択";
                            } else if (selected.length === 1) {
                                return lineDisplayString(selected[0]);
                            } else {
                                return "カメラ複数選択中";
                            }
                        }}
                        MenuProps={MenuProps}
                    >
                        {/* Selectコンポーネント内でReact.Fragmentが使えないので、フラットな配列を作成 */}
                        {selectedDeviceIDs
                            .flatMap((deviceID) => {
                                const cameras = deviceIDtoDevice(deviceID).cameras;
                                return [
                                    cameras.length > 0 && <ListSubheader key={deviceID} style={{ lineHeight: MENU_HEIGHT }}>
                                        {deviceIDtoDevice(deviceID).deviceName}
                                    </ListSubheader>
                                    ,
                                    cameras.flatMap((camera, i) => {
                                        const lineIDs = camera.lines.map((line) =>
                                            lineUnicode(line)
                                        );
                                        return lineIDs.sort().map((lineID) => {
                                            return (
                                                <MenuItem key={lineID} value={lineID} style={{ height: MENU_HEIGHT }}>
                                                    <Checkbox
                                                        checked={
                                                            !!selectedLineIDs.find((x) => x === lineID)
                                                        }
                                                    />
                                                    <ListItemText primary={lineDisplayString(lineID)} />
                                                </MenuItem>
                                            );
                                        });
                                    })
                                ].flatMap(x => x);
                            })}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}

type DeviceLineSelectContainerProps = {
    callback: (params: { devices: DeviceType[], lines: LineType[] }) => void
}

export const DeviceLineSelectContainer = ({ callback }: DeviceLineSelectContainerProps) => {
    const [deviceItems, setDeviceItems] = React.useState<DeviceItem[]>([])
    const [lineItems, setLineItems] = React.useState<LineItem[]>([])
    const formatValueString = (val: string | undefined) => {
        return val || "";
    };

    const {
        invoke: getAllDevices,
        data: dataListDevices,
    } = useAPIService<{
        data: Device[];
        pagination: { skip: number; limit: number; total: number };
    }>(API.Devices.getAllDevices);

    React.useEffect(() => {
        getAllDevices();
    }, [getAllDevices]);


    React.useEffect(() => {
        if (dataListDevices) {
            const data: DeviceItem[] = dataListDevices.data.map(d => ({
                onlyDevice: true,
                deviceID: d.id,
                deviceName: d.name || "No name"
            }))
            setDeviceItems(data)
        }
    }, [dataListDevices])

    const {
        invoke: getAllCamera,
        data: dataListCamera,
    } = useAPIService<{
        data: Camera[];
        pagination: { skip: number; limit: number; total: number };
    }>(API.Cameras.getAllCameras);

    React.useEffect(() => {
        getAllCamera();
    }, [getAllCamera]);

    React.useEffect(() => {
        if (dataListCamera) {
            //各カメラのライン１～４を表示する場合の実装
            const newData = dataListCamera.data.flatMap(camera => {
                const xs = [1, 2, 3, 4] as const
                return xs.map(lineNo => {
                    return {
                        onlyDevice: false as const,
                        deviceName: formatValueString(camera?.device?.name),
                        deviceID: formatValueString(camera?.device?.id),
                        cameraName: formatValueString(camera?.name),
                        cameraID: formatValueString(camera?.id),
                        lineNo: lineNo
                    }
                })
            })
            /**現在設定中のラインのみ表示する場合の実装*/
            // const newData = dataListCamera.data.flatMap(camera => {
            //     const lines = camera.config?.line_crosses?.map((lineCross, i) => lineCross === null ? null : i + 1)
            //     if (lines) {
            //         const filteredLines = lines.filter(x => x !== null) as number[]
            //         return filteredLines.map(line => {
            //             return {
            //                 deviceName: formatValueString(camera?.device?.name),
            //                 deviceID: formatValueString(camera?.device?.id),
            //                 cameraName: formatValueString(camera?.name),
            //                 cameraID: formatValueString(camera?.id),
            //                 lineNo: line
            //             }
            //         })
            //     } else {
            //         return []
            //     }
            // })
            setLineItems(newData)
        }
    }, [dataListCamera]);

    return <DeviceLineSelect deviceData={deviceItems} lineData={lineItems} callback={callback} />;
};
