export function ValidateEmail(inputText: string): boolean {
  const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return regex.test(inputText);
}

function isNumeric(value: string) {
  return /^[-]?\d*(\.\d+)?$/.test(value);
}

export function ValidateNotificationEmail(
  inputNotificationEmail: string[]
): boolean {
  return (
    !inputNotificationEmail || 
    inputNotificationEmail.every((item) => ValidateEmail(item))
  );
}

function isBoolean(str: string) {
  return str === "true" || str === "false";
}

export function ToTruthValue(str: string) {
  if (isBoolean(str)) return str === "true";
  if (isNumeric(str)) return Number.parseFloat(str);
  return str;
}
