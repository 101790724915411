import React from "react";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import styled from "styled-components";
import API from "../../api";
import useAPIService from "../../api/useAPIService";
import { LoadingComponent } from "../../components/LoadingLayout";
import { PermissionLayout } from "../../components/PermissionLayout";
import { ResponseStatus } from "../../constant";
import { AuthContext } from "../../contexts/AuthContext";
import { User, UserRole } from "../../store/types/models";
import {
  handleErrorNotification,
  handleSuccessNotification,
} from "../../utils";
import Logo from "../../components/Logo";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
const Heading = styled.div`
  padding-top: 1rem;
  margin: 0 2rem;
  display: flex;
  align-items: center;
`;

const PageHeadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const PageHeading = styled.h3`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  padding: 2rem;
  flex: 1;
  max-width: 1700px;
  min-width: 500px;
`;

enum ModalType {
  CREATE,
  DELETE,
}

export const UserManagement: React.FC = () => {
  const { user } = React.useContext(AuthContext);
  const [selectedItem, setSelectedItem] = React.useState<User | undefined>();
  const [showModal, setShowModal] = React.useState(false);
  const [typeModal, setTypeModal] = React.useState<ModalType>(ModalType.CREATE);

  const {
    invoke: getAllUser,
    data: dataAllUser,
    isLoading,
    error: errorGetUser,
    status: getAllUserStatus,
  } = useAPIService<{
    data: User[];
    pagination: { skip: number; limit: number; total: number };
  }>(API.UserManagement.getUserManagement);

  const {
    invoke: deleteUser,
    isLoading: deleteLoading,
    status: deleteStatus,
    error: errorDelete,
  } = useAPIService(API.UserManagement.deleteUserManagement);

  const {
    invoke: createUser,
    isLoading: createLoading,
    status: createStatus,
    error: errorCreate,
  } = useAPIService(API.UserManagement.createUserManagement);

  React.useEffect(() => {
    if (deleteStatus) {
      deleteStatus === ResponseStatus.SUCCESS
        ? handleSuccessNotification("ユーザーアカウントが正常に削除されました")
        : handleErrorNotification(errorDelete?.response?.data?.detail);
    }
  }, [deleteStatus, errorDelete]);

  React.useEffect(() => {
    if (getAllUserStatus === ResponseStatus.FAIL) {
      handleErrorNotification(errorGetUser?.response?.data?.detail);
    }
  }, [getAllUserStatus, errorGetUser]);

  React.useEffect(() => {
    if (createStatus) {
      createStatus === ResponseStatus.SUCCESS
        ? handleSuccessNotification("ユーザーアカウントが正常に作成されました")
        : handleErrorNotification(errorCreate?.response?.data?.detail);
    }
  }, [createStatus, errorCreate]);

  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    getAllUser();
  }, [getAllUser]);

  const handleCloseModal = () => {
    setUserName("");
    setPassword("");
    setSelectedItem(undefined);
    setShowModal(false);
  };

  const handleSaveChange = async () => {
    const dataSubmit = {
      username: userName,
      password: password,
    };
    if (typeModal === ModalType.CREATE) {
      await createUser(dataSubmit);
    } else {
      await deleteUser(selectedItem?.id);
    }
    getAllUser();
    handleCloseModal();
  };

  if (user && user.role === UserRole.OPERATOR) {
    return <PermissionLayout />;
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleSaveChange();
  };

  const renderTooltip = (
    props: any,
    message_1: any,
    message_2: any,
    message_3: any
  ) => (
    <Tooltip id="tooltip-password" {...props}>
      <ul>
        <li>{message_1}</li>
        <li>{message_2}</li>
        <li>{message_3}</li>
      </ul>
    </Tooltip>
  );


  return (
    <Wrapper>
      <Heading>
        <Logo />
      </Heading>
      <ContentWrapper>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div
              className="list-setting"
              style={{
                padding: "1rem",
                backgroundColor: "#fff",
                borderRadius: 10,
              }}
            >
              <PageHeadingWrap>
                <PageHeading>ユーザ設定</PageHeading>
                <Button
                  id="createUser"
                  style={{ background: "#435ebf" }}
                  onClick={() => {
                    setShowModal(true);
                    setTypeModal(ModalType.CREATE);
                  }}
                >
                  新規作成
                </Button>
              </PageHeadingWrap>
              <Table style={{ width: "100%" }}>
                <thead className="table-primary">
                  <tr>
                    <th>No</th>
                    <th>ユーザー名</th>
                    <th style={{ textAlign: "center" }}>削除</th>
                  </tr>
                </thead>
                <tbody>
                  {dataAllUser?.data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.username}</td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            id="deleteUser"
                            variant="danger"
                            onClick={() => {
                              setSelectedItem(item);
                              setShowModal(true);
                              setTypeModal(ModalType.DELETE);
                            }}
                          >
                            <i
                              className="bi bi-trash"
                              style={{ marginRight: 4 }}
                            />
                            削除
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        )}

        {showModal && (
          <Modal show={showModal}>
            <Form onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>
                  {typeModal === ModalType.CREATE
                    ? "新規ユーザー登録"
                    : "ユーザー削除"}
                </Modal.Title>
              </Modal.Header>
              {typeModal === ModalType.CREATE && (
                <Modal.Body>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1" style={{ width: "30%" }}>
                      ユーザー名
                    </InputGroup.Text>
                    <FormControl
                      type="email"
                      id="username"
                      data-cy="username"
                      placeholder="ユーザー名"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </InputGroup>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) =>
                      renderTooltip(
                        props,
                        "パスワードは8文字以上で入力してください。",
                        "パスワードは20文字以下で入力してください。",
                        "パスワードは半角英数字2種類以上を含めて入力してください。"
                      )
                    }
                  >
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="basic-addon1"
                        style={{ width: "30%" }}
                      >
                        パスワード
                      </InputGroup.Text>
                      <FormControl
                        type="password"
                        id="password"
                        data-cy="password"
                        placeholder="パスワード"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </OverlayTrigger>
                </Modal.Body>
              )}
              <Modal.Footer>
                <Button
                  type="submit"
                  id="saveChangeModal"
                  disabled={
                    (typeModal === ModalType.CREATE && !userName) ||
                    deleteLoading ||
                    createLoading
                  }
                  variant={
                    typeModal === ModalType.DELETE ? "danger" : "primary"
                  }
                >
                  {typeModal === ModalType.DELETE ? (
                    <>
                      <i style={{ marginRight: 4 }} className="bi bi-trash" />
                      削除する
                    </>
                  ) : (
                    "保存"
                  )}
                </Button>
                <Button
                  id="closeModal"
                  variant="secondary"
                  onClick={() => handleCloseModal()}
                >
                  戻る
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
