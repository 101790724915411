import { AxiosInstance, AxiosPromise } from "axios";
import { UserCreate } from "../../store/types/models";
export type UserManagementType = {
  getUserManagement(): AxiosPromise;
  deleteUserManagement(id: string): AxiosPromise;
  createUserManagement(params: UserCreate): AxiosPromise;
};
export const UserManagementMethod = (
  request: AxiosInstance
): UserManagementType => ({
  getUserManagement() {
    return request.get(`/users/`);
  },
  deleteUserManagement(id) {
    return request.delete(`/users/${id}`);
  },
  createUserManagement(params) {
    return request.post(`/users/`, params);
  },
});
