import React, { useEffect, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { RangeInfo } from './types';

type DropdownOfYearProps = {
    rangeInfo: RangeInfo
    setRangeInfo: React.Dispatch<React.SetStateAction<RangeInfo>>;
}

export const DropdownOfYear: React.FC<DropdownOfYearProps> = ({ setRangeInfo, rangeInfo }) => {
    const currentYear = new Date().getFullYear();

    const minimumYear = 2022
    const items = Array.from({ length: currentYear - minimumYear + 1 }, (_, i) => currentYear - i).map((item) => item.toString()).reverse()


    const [disabled, setDisabled] = useState(true)

    const [labelYear, setLabelYear] = useState<string | null>(null);

    const onSelector = (year: string | null) => {

        if (!year) {
            return
        }

        setLabelYear(year);
        setRangeInfo((prev) => {
            return {
                ...prev,
                year: parseInt(year)
            };
        });
    };

    useEffect(() => {
        if (rangeInfo.year && labelYear !== rangeInfo.year.toString()) {
            const newLabel = rangeInfo.year.toString()
            setLabelYear(newLabel)
        }

        if (rangeInfo.rangeType && ["年", "月", "日", "時"].includes(rangeInfo.rangeType)) {
            setDisabled(false)
        } else if (!disabled) {
            setDisabled(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeInfo])

    return (
        <Dropdown className="mx-2" as={ButtonGroup} onSelect={onSelector}>
            <Dropdown.Toggle variant="secondary" id="DropdownYear" disabled={disabled}>
                {labelYear ? labelYear + "年" : "年"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {items.map((item) => (
                    <Dropdown.Item key={item} eventKey={item}>{item}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
