import React, { useEffect, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { RangeInfo, RangeType } from './types';

type DropdownOfRangeProps = {
    setRangeInfo: React.Dispatch<React.SetStateAction<RangeInfo>>;
    rangeInfo: RangeInfo
    isOnlyDayHour?: boolean
}

export const DropdownOfRange: React.FC<DropdownOfRangeProps> = ({ rangeInfo, setRangeInfo, isOnlyDayHour }) => {
    const items: RangeType[] = isOnlyDayHour ? ["日", "時"] : ["年", "月", "日", "時"];

    const [labelRange, setLabelRange] = useState<RangeType | null>(null);

    useEffect(() => {
        if (labelRange !== rangeInfo.rangeType) {
            setLabelRange(rangeInfo.rangeType)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeInfo])


    const onSelector = (eventKey: string | null) => {

        if (!eventKey) {
            return
        }

        setRangeInfo((prev) => {
            return {
                ...prev,
                rangeType: eventKey as RangeType
            };
        });
        setLabelRange(eventKey as RangeType);
    };

    return (
        <Dropdown className="mx-2" as={ButtonGroup} onSelect={onSelector}>
            <Dropdown.Toggle variant="secondary" id="DropdownRange">
                {labelRange || "レンジ選択"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {items.map((item) => {
                    return <Dropdown.Item key={item} eventKey={item}>{item}</Dropdown.Item>;
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};